export class $meta {

  constructor($window) {
    this.$window = $window;
    this.title = null;
    this.section = null;
    this.defaultTitle = 'Wordtracker';
  }

  setSection(section) {
    if (section) this.section = section;
  }

  setDescription(description) {
    if (description) {
      this.$window.document.querySelector('meta[name="description"]').setAttribute("content", description);
    }
  }

  setTitle(title) {
    if (title === "DYNAMIC") return;
    if (title === "PRE-SET") return;
    title = title || this.defaultTitle;
    this.title = title;
    this.$window.document.title = title;
  }
}

$meta.$inject = ['$window'];
