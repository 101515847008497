import { getField } from '../../shared/fields';
import './pageMetrics.scss';

class _Controller {
  constructor($resultsStore, $domainSearchSettings) {
    this.results = $resultsStore;
    this.$domainSearchSettings = $domainSearchSettings;
    this.getField = getField;
    this.metricNames = [
      "ExtBackLinks",
      "RefDomains",
      "CitationFlow",
      "TrustFlow",
      "OverallScore"
    ]
  }

}

_Controller.$inject = ['$resultsStore', '$domainSearchSettings'];

export const pageMetrics = {
  bindings: {},
  controller: _Controller,
  template: require('./pageMetrics.html')
}
