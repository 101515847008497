import { getField } from "../shared/fields";
import "./ranking.scss";
import moment from "moment/moment.js";

class RankingController {
  constructor($limits, UserService, $searchManager, $location, $rankingSearchSettings, $seoMetaLengths, $resultsStore, $listManagementNotifier, $saveToList, $activeList, $notifier, $tokenManager, $keywordsCollection, $sharedCookies, $meta, Modal, $seeds, $lists, $stateParams, $timeout, $listNavigation, $rootScope) {
    this.$limits = $limits;
    this.results = $resultsStore;
    this.$searchManager = $searchManager;
    this.$location = $location;
    this.$rankingSearchSettings = $rankingSearchSettings;
    this.$seoMetaLengths = $seoMetaLengths;
    this.user = UserService.user;
    this.isFreeUser = UserService.user.isFreeUser;
    this.settingsChanged = false;
    this.$listManagementNotifier = $listManagementNotifier;
    this.$saveToList = $saveToList;
    this.$activeList = $activeList;
    this.$notifier = $notifier;
    this.getField = getField;
    this.$keywordsCollection = $keywordsCollection;
    this.$tokenManager = $tokenManager;
    this.$sharedCookies = $sharedCookies;
    this.$meta = $meta;
    this.country = null;
    this.Modal = Modal;
    this.$seeds = $seeds;
    this.import = {};
    this.$lists = $lists;
    this.lists = $lists.lists;
    this.list = null;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.prevPage = -1;
    this.isNew = false;
    this.loading = true;
    this.moment = moment;
    this.listNav = $listNavigation;
    this.$rootScope = $rootScope;
  }

  $onInit() {
    this.$tokenManager.getToken();
    this.$lists.initialize().then(this.loadList.bind(this));
  }

  loadList() {
    const { id } = this.$stateParams;
    const list = _.findWhere(this.lists, {id: parseInt(id) });
    if (!list) return this.$location.path(`/ranking`);
    this.setupFromList(list);
    this.getKeywords();
  }

  setupFromList(list) {
    if (!list.ranking_last_updated) list.ranking_last_updated = moment();
    if (!list.ranking_next_update) list.ranking_next_update = moment().add(1, 'weeks');
    this.list = list;
    this.$rankingSearchSettings.searchParams.site = list.domain;
    this.$rankingSearchSettings.searchParams.list_id = list.id;
    this.country = list.countries[0];
    this.$rankingSearchSettings.searchParams.country = this.country;
    this.results.ranking = this.$keywordsCollection({name: 'ranking'});
    this.results.ranking.add([]);
    this.rankingKeywordsUsed = this.list.keywords_count;
    this.rankingKeywordsAvailable = Math.max(this.$limits.raw.ranking_keywords - this.rankingKeywordsUsed, 0);
  }

  getKeywords({page = 1} = {}) {

    const _params = {
      id: this.list.id,
      page
    };

    this.$lists.getKeywords(_params).then(response => {
      this.results.ranking.loading = false;
      if (response.keywords.length > 0) {
        if (this.prevPage < response.page) {
          this.country = response.keywords[0].country;
          this.$rankingSearchSettings.searchParams.country = this.country;
          // Get any keywords without rank populated
          // This might be keywords that have been sent here directly from /search or /domain
          // Put these into a separate array, and also flag them as 'appended' on the original array
          // So the keywords display will show them with spinners.
          const freshKeywords = [];
          const keywords = response.keywords.map(keyword => {
            if (!keyword.rank) {
              freshKeywords.push(keyword.keyword);
              const _keyword = {
                keyword: keyword.keyword,
                appended: true
              }
              if (keyword.id) _keyword.id = keyword.id;
              keyword = _keyword;
            }
            return keyword;
          });

          // Create a new search for the appended keywords.
          if (freshKeywords.length > 0) {
            this.$rankingSearchSettings.searchParams.keywords = freshKeywords;
            this.$searchManager.searchRanking();
          }

          // Add the keywords
          this.results.ranking.add(keywords);
          this.results.ranking.updateCountsFromResponse(response);

          // Fetch the next page
          this.prevPage = response.page;
          this.getKeywords({page: response.page + 1});
        }
      } else {
        // We're on the last page already.
        this.prevPage = response.page;
        this.results.ranking.add([]);
      }

      this.$timeout(() => {this.loading = false;}, 250);

    });
  }

  newImport() {
    this.import.bulkSeeds = null;
    this.import.bulkCSV = null;
    this.Modal.new({
      component: 'searchBulkModal',
      dismissable: true,
      bindings: {
        title: "Add keywords to track..."
      }
    }).then(keywords => {
      if (keywords.length > this.rankingKeywordsAvailable) {
        return this.$notifier.red(`Max of 1000 keywords per list. You can import a maximum of ${this.rankingKeywordsAvailable} more.`);
      }
      this.importKeywords(keywords, this.country, 'ranking');
    }).catch(console.error);
  };

  importKeywords(keywords, country, source) {

    // De-dup with keywords already in collection
    const existingKeywords = this.results.ranking.keywords.map(keyword => keyword.keyword);
    keywords = _.difference(keywords, existingKeywords);

    if (keywords.length > 0) {
      // Save to list
      this.$lists.importKeywords(
        this.list,
        keywords.map(keyword => ({keyword, country, source}))
      ).then(res => {
        this.rankingKeywordsAvailable -= keywords.length;
        this.rankingKeywordsUsed += keywords.length;
      });

      this.$rankingSearchSettings.searchParams.keywords = keywords;
      this.$rankingSearchSettings.searchParams.country = this.country;
      keywords.reverse().forEach(keyword => this.results.ranking.addAppended(keyword));
      this.$searchManager.searchRanking();

    }

  };

  deleteSelected() {
    let selectedKeywords = this.results.ranking.getActionable();

    if (selectedKeywords.length === 0) return false;

    if (confirm('Remove ' + selectedKeywords.length + ' keywords from this list?')) {
      this.$lists.deleteKeywords(this.list, selectedKeywords).then(() => {
        var newKeywords = _.difference(this.results.ranking.keywords, selectedKeywords);
        this.results.ranking.keywords = newKeywords;
        this.results.ranking.selectedKeywords = [];
        this.results.ranking.count = newKeywords.length;
        // this.$timeout(() => {
          this.rankingKeywordsAvailable += selectedKeywords.length;
          this.rankingKeywordsUsed -= selectedKeywords.length;
        // });
      });
    }
  }

}

RankingController.$inject = ['$limits', 'UserService', '$searchManager', '$location', '$rankingSearchSettings', '$seoMetaLengths', '$resultsStore', '$listManagementNotifier', '$saveToList', '$activeList', '$notifier', '$tokenManager', '$keywordsCollection', '$sharedCookies', '$meta', 'Modal', '$seeds', '$lists', '$stateParams', '$timeout', '$listNavigation', '$rootScope'];

export const ranking = {
  controller: RankingController,
  template: require('./ranking.html')
};