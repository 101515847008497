export const $errors = function(Modal, $notifier) {

  const errors = {
    conditions: [
      { errorName: 'gscTokenExpired', test: error => (error.message && error.message === "Access token not found or not valid. Visit auth_uri to reauthenticate") },
      { errorName: 'gscNoProperty', test: error => (error === "NoPropertyError") }
    ],
    callbacks: {
      gscTokenExpired: (error) => {
        Modal.new({
          component: 'gscModal',
          bindings: {
            actionUri: error.auth_uri,
            actionText: 'Authenticate...',
            heading: 'Re-authenticate with Google',
            description: `<span>Sorry, you need to re-authenticate Google Search Console (GSC) to access GSC results.<br><br><span class='secondary-text'>(If you no longer want to see GSC data in Wordtracker, you can <a target='_self' href='${error.integrations_uri}'>disconnect GSC here</a>.</span></span>`
          },
        }).then(res => {
          // Close modal
        })
      },
      gscNoProperty: (error) => {
        Modal.new({
          component: 'gscModal',
          bindings: {
            actionUri: __ZUUL_URL + '/account/integrations',
            actionText: 'Select property...',
            heading: 'Select a property',
            description: `<span>You need to choose which of your Google properties (aka 'sites') you'd like Google Webmaster Console (GSC) data for.<br><br><span class='secondary-text'>(If you no longer want to see GSC data in Wordtracker, you can <a target='_self' href='${__ZUUL_URL + '/account/integrations'}'>disconnect GSC here</a>.</span></span>`
          },
        }).then(res => {
          // Close modal
        })
      }
    }
  }

  errors.handleGeneric = error => {
    $notifier.red("Something went wrong.")
    console.error('Errors service received an error but didn\'t handle it. Error was: ', error)
  }

  errors.handleError = (error) => {
    let errorName = null
    const { conditions, callbacks } = errors
    for (let i = 0; i < conditions.length; i++) {
      const condition = conditions[i]
      if (condition.test(error)) {
        errorName = condition.errorName; break;
      }
    }
    return errorName ? callbacks[errorName](error) : errors.handleGeneric(error);
  }

  return errors
};

$errors.$inject = ['Modal', '$notifier'];