import "./keywordCountSummary.scss";

class _Controller {
  constructor($limits, UserService, $resultsStore, $upsells, $searchSettings) {
    this.$upsells = $upsells;
    this.searchSettings = $searchSettings;
    this.limits = $limits;
    this.$resultsStore = $resultsStore;
    this.isFreeUser = UserService.user.isFreeUser;
    this.user = UserService.user;
  }

}

_Controller.$inject = ['$limits', 'UserService', '$resultsStore', '$upsells', '$searchSettings'];

export const keywordCountSummary = {
  controller: _Controller,
  bindings: {
    results: '<',
    location: '@',
    explainer: '@',
    isFiltered: '<',
    fromDomain: '<'
  },
  template: require('./keywordCountSummary.html')
};
