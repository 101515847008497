const authToken = () => document.querySelector('[name=csrf-token]').content;

const post = (url, body) => new Promise((resolve, reject) => {
  fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': authToken()
    },
    body: JSON.stringify(body)
  }).then(res => {
    if (res.ok) {
      return resolve(res.json());
    }
    throw new Error('Something went wrong', { cause: res });
  }).catch(error => {
    error.cause.json().then(res => {
      reject({data: res});
    })
  })
})

const parseErrors = response => {
  console.error(response);
  const { errors } = response.data;
  return Object.entries(errors).reduce((_errors, [key, values]) => {
    key = (key === "billing_info") ? "card_number" : key;
    values = (key === "billing_info") ? ["are invalid"] : values;
    _errors[key] = Array.isArray(values) ? values.join('and') : values;
    return _errors;
  }, {});
};

export const createSetupIntent = (token) => {
  return new Promise((resolve, reject) => {
    post('/api/current/intent', {token})
      .then(resolve)
      .catch(res => reject(parseErrors(res)));
  });
};

export const createUserWithSubscription = (payload) => {
  return new Promise((resolve, reject) => {
    post('/api/current/create_with_subscription', payload)
      .then(resolve)
      .catch(res => reject(parseErrors(res)));
  });
};

export const subscribe = (payload) => {
  return new Promise((resolve, reject) => {
    post('/api/current/subscribe', payload)
      .then(resolve)
      .catch(res => reject(parseErrors(res)));
  });
};

export const activate = (payload) => {
  return new Promise((resolve, reject) => {
    post('/api/current/activate_subscription', payload)
    .then(resolve)
    .catch(res => reject(parseErrors(res)));
  });
}