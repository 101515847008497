import React, { Component } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip'


class TableHeader extends Component {

  onClickHeader(field) {

    if (field.type === 'json') return false;

    let { sortField, sortDescending, onSetSorting } = this.props;
    let fieldKey = field.key;
    if (sortField === fieldKey) {
      sortDescending = !sortDescending;
    } else {
      sortDescending = true;
      sortField  = fieldKey;
    }
    onSetSorting({sortDescending, sortField});
  }

  render() {

    const { field, sortDescending, sortField, hasHistorical } = this.props;

    return (
      <th
        onClick={() => this.onClickHeader(field)}
        className={classNames({
          'table-stat': true,
          [field.key]:  true,
          'descending': sortDescending && sortField === field.key,
          'ascending':  !sortDescending && sortField === field.key,
          hasSparkline: hasHistorical
        })}>
        <span className='label'>
          { field.heading }
          <div data-tooltip-id={field.key} data-tooltip-html={field.description} className='info-icon'>
            <span className='metric-tip'></span>
          </div>
          <Tooltip
            className="react-tooltip-header-callout"
            id={field.key}
          ></Tooltip>
        </span>
      </th>
    )
  }
}

export default TableHeader;
