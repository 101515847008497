export const gscModal = {
  controller: class {
    resolve(value) {
      this.close({value});
    }
  },
  bindings: {
    close: '&',
    title: '<heading',
    actionUri: '<',
    actionText: '<',
    description: '<',
  },
  template: `
    <div class="modal-header">
      <span ng-bind-html='$ctrl.title'></span>
    </div>
    <div class="modal-body" id="modal-body" ng-bind-html='$ctrl.description'></div>
    <div class="modal-footer">
      <a class='button go-standard authenticate-button' ng-href='{{$ctrl.actionUri}}'>{{$ctrl.actionText}}</a>
      <button type="button" class='cancel-bordered' ng-click="$ctrl.resolve(false)">Close</button>
    </div>
  `,
}
