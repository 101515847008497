export const monthName = function() {

  var months = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December"
  };

  return function(dateString) {
    if (dateString === "") return "Monthly average";

    var match = dateString.match(/^([0-9]{4})-([0-9]{2})/);
    return months[match[2]] + " " + match[1];
  };
};
