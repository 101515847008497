import React from 'react'
import './PercentBar.scss';
// import { Tooltip } from 'react-tooltip'

export default function PercentBar({onClick, data, colorType = 'low-is-irrelevant'}) {

  // Expects data as a percentage decimal (eg, a float between 0 and 1)

  const renderBarWithData = () => {

    const barWidth = data == 0 ? 0 : Math.max(data, 8);

    return (
      <div
        className='percent-bar'
        onClick={onClick}
        title={data}
        data-tooltip-content={`${data}%`}
        data-tooltip-id='tooltip-percent-bar'
      >
        <div
          className={`percent-bar__bar ${colorType} percent-${parseFloat(data/10).toFixed(0)}`}
          style={{width: barWidth + '%'}}
        >{data}</div>
        {data}
      </div>
    )
  }

  const renderBarNoData = () => {
    return <div
      className={`percent-bar percent-no-data`}
      onClick={onClick}
    >-</div>
  }

  if (data == -1) data = null;

  if (data) {
    data = (data * 100).toFixed(0);
    if (data > 100) data = 100;
  }

  return (
    <>
      { (!!data || data == 0) && renderBarWithData()}
      { (!data && data != 0) && renderBarNoData()}
    </>
  )


}