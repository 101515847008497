import React from 'react'
import { getPlanByPriceId } from '../../shared/plans';

export default function UpsellContactSupport() {

  return (
    <div className='signup-form-area done fade-in' style={{display: "block", padding: "49px 70px 38px"}}>
      <h2 style={{fontSize: "26px"}}>
        <i className='fa-regular fa-lightbulb' />&nbsp; Need more than { getPlanByPriceId("lannister_gold_monthly").tier.limits.ranking_lists } ranking lists?
      </h2>
      <p>Get in touch with us at <a target='_blank' href='mailto:support@wordtracker.com'>support@wordtracker.com</a><br/>to talk about a custom plan.</p>
    </div>
  )
}
