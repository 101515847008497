import "./statics/api/codemirror-wt.scss";

class _Controller {
  constructor($location, $window, $timeout, $notifier, $sharedCookies) {
    this.$location = $location;
    this.$sharedCookies = $sharedCookies;
    this.$window = $window;
    this.$notifier = $notifier;
    this.$timeout = $timeout;
    this.zuul_url = __ZUUL_URL;
  }

  $onInit() {

    const params = this.$location.search();
    if (params.coupon) {
      this.$sharedCookies.set('coupon', params.coupon);
    }

    $.get("//www.wordtracker.com/social-widget", function(response) {
      var $blocks = $($.parseHTML(response)).children();
      $('.seo-block').append($blocks[0]);
      $('.twitter-block').append($blocks[1]);
    });

    this.showChatIfRequested();

  }

  showChatIfRequested() {
    let _params = this.$location.search();
    if (_params.livechat) {
      this.$timeout(() => {
        if (drift.api) {
          drift.api.openChat();
        } else {
          this.$timeout(this.showChatIfRequested.bind(this), 250);
        }
      });
    }
  }

  goToSignUp(){
    this.$window.location.href = this.zuul_url + '/sign-up?plan=lannister';
  }

}

_Controller.$inject = ['$location', '$window', '$timeout', '$notifier', '$sharedCookies'];

export const sales = {
  controller: _Controller,
  template: require('./sales.html')
};