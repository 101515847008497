import "./keywords.scss";

export const keywords = function($textFilters, $activeColumns, $searchManager, $upsells) {
  return {
    restrict: 'E',
    scope: {
      collection: '<',
      source: '<',
      serpsResults: '<',
      perPage: '@',
      sortField: '@',
      method: '<',
      forceColspan: '<',
      type: '@',
      onClickResult: '&onClickResultFn',
      isFreeUser: '<',
      elementId: '@',
      showFilters: '<'
    },
    template: require('./keywords.html'),
    link: function (scope) {

      scope.textFilters = $textFilters;
      scope.fields = $activeColumns.active[scope.type || 'search'];

      scope.searchSerp = (result) => {
        if (!scope.serpsResults || scope.serpsResults.country != scope.collection.country || (scope.serpsResults.results && !scope.serpsResults.results.hasOwnProperty(result.keyword))) {
          $searchManager.serpAppend(result.keyword, scope.collection.country);
        }
      }
    }
  };
};

keywords.$inject = ['$textFilters', '$activeColumns', '$searchManager', '$upsells'];