import "./listManagement.scss";

class _Controller {
  constructor($lists, $listManagementNotifier, UserService, $window, $activeList, $upsells) {
    this.isFreeUser = UserService.user.isFreeUser;
    this.$listManagementNotifier = $listManagementNotifier;
    this.$window = $window;
    this.$upsells = $upsells;
    this.zuul_url = __ZUUL_URL;

    // Lists service stuff
    this.$lists = $lists;
    this.lists = $lists.lists;
    this.projects = $lists.projects;
    this.$activeList = $activeList;
    this.activeList = $activeList.list;
  }

  $onInit() {
    this.$lists.initialize().then(() => {
      this.$activeList.initialize().then(() => {
        if (this.activeList.id) this.$lists.loadListKeywords(this.activeList);
      });
    });
  };

  // Sort out creating the activeList if it doesn't exist.

  setActiveList(list) {
    this.$activeList.setActiveList(list);
  }

  newList() {
    this.$activeList.newList();
  }

  saveEdit(value) {
    if (this.$lists.activeList.title != value) {
      this.$listManagementNotifier.pending('Saving...');
      this.$activeList.updateActiveList({title: value}).then(() => {
        this.$listManagementNotifier.success('Saved');
      });
    }
  }

  goToSignUp = () => this.$window.location.href = `${this.zuul_url}/sign-up?plan=lannister`; 
}

_Controller.$inject = ['$lists', '$listManagementNotifier', 'UserService', '$window', '$activeList', '$upsells'];

export const listManagement = {
  bindings: {},
  controller: _Controller,
  template: require('./listManagement.html')
};
