import React, { Fragment } from 'react';
import MetricFilter from './MetricFilter';
import QuestionFilter from './QuestionFilter.js';
import GSCFilter from './GSCFilter.js';

export default props => (
  Object.entries(props.filters).map(([type, {availableMin, availableMax}]) => (
    <Fragment key={type}>
      { type === 'question' && (
        <QuestionFilter
          onDelete={props.onDeleteFilter}
        ></QuestionFilter>
      )}
      { type === 'gsc' && (
        <GSCFilter
          onDelete={props.onDeleteFilter}
        ></GSCFilter>
      )}
      { !['question', 'gsc'].includes(type) && (
        <MetricFilter
          type={type}
          onDelete={props.onDeleteFilter}
          availableMin={availableMin}
          availableMax={availableMax}
          onChange={values => props.onChangeFilter(type, values)}
        ></MetricFilter>
      )}
    </Fragment>
  ))
)