// Load global hooks here. Things like redirects, auth checks, loading bars etc.

// import {AppConfig} from "./appConfig.service";

import { $meta } from "./meta.service";
import { $resourceRoutes } from "./resource/resourceRoutes.service";
import { $resourceManager } from "./resource/resourceManager.service";
import { $limits } from "./limits.service";
import { UserService } from "./user.service";
import { $notifier } from "./notifier.service";
import { $seoMetaLengths } from "./seoMetaLengths.service";
import { $listManagementNotifier } from "./listManagementNotifier.service";
import { $f } from "./f.service";
import { $searchDebug } from "./debug/searchDebug.service";
import { searchDebug } from "./debug/searchDebug.component";
import { $cableConnection } from "./cableConnection.service";
import { $searchSettings } from "./search/searchSettings.service";
import { $curiosity } from "./search/curiosity.service";
import { $handleKeyPress } from "./handleKeyPress.service";
import { $exampleSeeds } from "./exampleSeeds.service";
import { $tips } from "./tips.service";
import { $lists } from "./lists.service";
import { $activeList } from "./activeList.service";
import { $errors } from "./errors/errors.service";
import { $activeColumns } from "./activeColumns.service";
import { $mocks } from "./mocks.service";
import { $storage } from "./storage.service";
import { Seeds as $seeds } from "./seeds.factory";
import { OpportunityCalc as $opportunityCalc } from "./opportunityCalc.factory";
import { $resultsStore } from "./search/resultsStore.service";
import { $tokenManager } from "./search/tokenManager.service";
import { $searchRequest } from "./search/searchRequest.service";
import { $googleCountries } from "./googleCountries.service";
import { $textFilters } from "./textFilters.service";
import { $tokenCatcher } from "./search/tokenCatcher.service";
import { $searchManager } from "./search/searchManager.service";
import { $domainSearchSettings } from "./search/domainSearchSettings.service";
import { $rankingSearchSettings } from "./search/rankingSearchSettings.service";
import { $scrapeProtection } from "./scrapeProtection.service";
import { $requestManager } from "./search/requestManager.service";
import { $sharedCookies } from "./sharedCookies.service";
import { KeywordsCollection } from "./keywordsCollection.factory";
import { SearchMain as $searchMain } from "./search/curiosities/main.factory";
import { SearchAbstract as $searchAbstract } from "./search/curiosities/abstract.factory";
import { SearchCSV as $searchCSV } from "./search/curiosities/csv.factory";
import { SearchGSC as $searchGSC } from "./search/curiosities/gsc.factory";
import { SearchStandard as $searchStandard } from "./search/curiosities/standard.factory";
import { SearchRanking as $searchRanking } from "./search/curiosities/ranking.factory";
import { KeywordsForDomain as $keywordsForDomain } from "./search/curiosities/keywordsForDomain.factory";
import { SearchAppend as $searchAppend } from "./search/curiosities/append.factory";
import { SerpAppend as $serpAppend } from "./search/curiosities/serpAppend.factory";
import { SearchSerp as $searchSerp } from "./search/curiosities/serp.factory";
import { Competitors as $competitors } from "./search/curiosities/competitors.factory";
import { PageMetrics as $pageMetrics } from "./search/curiosities/pageMetrics.factory";
import { SiteReport as $siteReport } from "./search/curiosities/siteReport.factory";
import { Form } from "./form.factory";
import { OrderBy } from "./orderBy.factory";

import { navWithSubmenu } from "./nav_with_submenu/navWithSubmenu.component";

import { MetricFilters } from "./metricFilters.factory";
import { IncludingFilter, ExcludingFilter } from "./textFilter.factory";
import { $countryNames } from "./countryNames.service";
import { $stripe } from "./stripe.service";

import { pageChangeHook } from "./pageChange.hook";
export const GLOBAL_MODULE = angular.module('global', []);

GLOBAL_MODULE.factory('$searchMain', $searchMain);
GLOBAL_MODULE.factory('$searchAbstract', $searchAbstract);
GLOBAL_MODULE.factory('$searchCSV', $searchCSV);
GLOBAL_MODULE.factory('$searchGSC', $searchGSC);
GLOBAL_MODULE.factory('$searchStandard', $searchStandard);
GLOBAL_MODULE.factory('$searchAppend', $searchAppend);
GLOBAL_MODULE.factory('$searchRanking', $searchRanking);
GLOBAL_MODULE.factory('$serpAppend', $serpAppend);
GLOBAL_MODULE.factory('$searchSerp', $searchSerp);
GLOBAL_MODULE.factory('$keywordsForDomain', $keywordsForDomain);
GLOBAL_MODULE.factory('$pageMetrics', $pageMetrics);
GLOBAL_MODULE.factory('$competitors', $competitors);
GLOBAL_MODULE.factory('$siteReport', $siteReport);
GLOBAL_MODULE.factory('$keywordsCollection', KeywordsCollection);
GLOBAL_MODULE.factory('$metricFilters', MetricFilters);
GLOBAL_MODULE.factory('$seeds', $seeds);
GLOBAL_MODULE.factory('Form', Form);
GLOBAL_MODULE.factory('OrderBy', OrderBy);
GLOBAL_MODULE.factory('$opportunityCalc', $opportunityCalc);
GLOBAL_MODULE.factory('$includingFilter', IncludingFilter);
GLOBAL_MODULE.factory('$excludingFilter', ExcludingFilter);
GLOBAL_MODULE.service('$textFilters', $textFilters);
GLOBAL_MODULE.service('$meta', $meta);
GLOBAL_MODULE.service('$mocks', $mocks);
GLOBAL_MODULE.service('$searchManager', $searchManager);
GLOBAL_MODULE.service('$f', $f);
GLOBAL_MODULE.service('$sharedCookies', $sharedCookies);
GLOBAL_MODULE.service('$tokenManager', $tokenManager);
GLOBAL_MODULE.service('$searchRequest', $searchRequest);
GLOBAL_MODULE.service('$cableConnection', $cableConnection);
GLOBAL_MODULE.service('$requestManager', $requestManager);
GLOBAL_MODULE.service('$tips', $tips);
GLOBAL_MODULE.service('$searchSettings', $searchSettings);
GLOBAL_MODULE.service('$curiosity', $curiosity);
GLOBAL_MODULE.service('$listManagementNotifier', $listManagementNotifier);
GLOBAL_MODULE.service('$handleKeyPress', $handleKeyPress);
GLOBAL_MODULE.service('$errors', $errors);
GLOBAL_MODULE.service('$tokenCatcher', $tokenCatcher);
GLOBAL_MODULE.service('$domainSearchSettings', $domainSearchSettings);
GLOBAL_MODULE.service('$rankingSearchSettings', $rankingSearchSettings);
GLOBAL_MODULE.service('$exampleSeeds', $exampleSeeds);
GLOBAL_MODULE.service('$resultsStore', $resultsStore);
GLOBAL_MODULE.service('$seoMetaLengths', $seoMetaLengths);
GLOBAL_MODULE.service('$scrapeProtection', $scrapeProtection);
GLOBAL_MODULE.service('$resourceRoutes', $resourceRoutes);
GLOBAL_MODULE.service('$resourceManager', $resourceManager);
GLOBAL_MODULE.service('$googleCountries', $googleCountries);
GLOBAL_MODULE.service('$lists', $lists);
GLOBAL_MODULE.service('$activeList', $activeList);
GLOBAL_MODULE.service('$activeColumns', $activeColumns);
GLOBAL_MODULE.service('$storage', $storage);
GLOBAL_MODULE.service('$countryNames', $countryNames);
GLOBAL_MODULE.service('UserService', UserService);
GLOBAL_MODULE.service('$notifier', $notifier);
GLOBAL_MODULE.service('$limits', $limits);
GLOBAL_MODULE.service('$stripe', $stripe);
GLOBAL_MODULE.service('$searchDebug', $searchDebug);
GLOBAL_MODULE.component('searchDebug', searchDebug);
GLOBAL_MODULE.component('navWithSubmenu', navWithSubmenu);
GLOBAL_MODULE.run(pageChangeHook);

