import { sendTriggerUpsellEvent } from '../shared/events';

class _Controller {

  constructor($upsells, $limits, UserService, Modal, $rootScope, $lists, $location, $saveToList, $listNavigation) {
    this.$upsells = $upsells;
    this.$lists = $lists;
    this.lists = $lists.lists;
    this.projects = $lists.projects;
    this.tree = $lists.tree;
    this.$limits = $limits;
    this.canUpgrade = UserService.user.canUpgrade;
    this.isFreeUser = UserService.user.isFreeUser;
    this.Modal = Modal;
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.$saveToList = $saveToList;
    this.fileNav = $listNavigation;
    this.loading = true;
    this.listType = 'ranking';
  }

  $onInit() {
    this.$lists.initialize().then(() => {
      this.establishListsLimit();
      this.loading = false;
    })
  }

  establishListsLimit() {
    const incrementOnDomain = (sum, list) => sum + (list.domain ? 1 : 0);
    this.rankingListsUsed = this.lists.reduce(incrementOnDomain, 0);
    this.rankingListsAvailable = Math.max(this.$limits.raw.ranking_lists - this.rankingListsUsed, 0);
  }

  newImport() {
    this.Modal.new({
      component: 'searchBulkModal',
      dismissable: true,
      bindings: {
        title: "Add keywords to track..."
      }
    }).then(this.onSubmitAddKeywordsModal.bind(this));
  };

  async destroySelected() {
    const result = await this.fileNav.destroySelected();
    if (result) {
      this.rankingListsUsed -= 1;
      this.rankingListsAvailable += 1;
    }
  }

  getMoreLists() {
    return this.$upsells.new('ranking', {limit: 'ranking_lists', browsing: true, requested: this.rankingListsUsed + this.rankingListsAvailable + 1});
  }

  onSubmitAddKeywordsModal(keywords) {

    if (!keywords || keywords.length < 1) return false;

    if (this.rankingListsAvailable < 1) {
      return this.$upsells.new('ranking', {limit: 'ranking_lists', requested: this.rankingListsUsed + 1});
    };

    const getKeywordsFn = async list => {
      return keywords.map(keyword => ({keyword, country: list.countries[0], source: 'ranking'})).slice(0, 1000);
    };

    this.loading = true;

    // Little hack to display the 'processing' count in the modal without having to use a collection
    this.$saveToList.loadedCount = keywords.length;

    this.Modal.new({
      component: 'createListModal',
      dismissable: true,
      bindings: {
        isRankingList: true,
        countrySource: 'ranking',
        getKeywordsFn
      }
    }).then(list => {
      this.$location.path(`/ranking/${list.id}`);
    }).catch(error => {
      console.error(error);
      this.loading = false;
    });

  }

}

_Controller.$inject = ['$upsells', '$limits', 'UserService', 'Modal', '$rootScope', '$lists', '$location', '$saveToList', '$listNavigation'];

export const rankingNew = {
  controller: _Controller,
  template: require('./rankingNew.html')
}