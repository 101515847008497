class Controller {
  constructor($resourceManager, $notifier, $timeout, Form) {

    this.$resourceManager = $resourceManager;
    this.$notifier = $notifier;
    this.$timeout = $timeout;
    this.zuul_url = __ZUUL_URL;
    this.stripe = Stripe(__STRIPE_PUBLIC_KEY);
    this.showSummary = false;

    this.paymentForm = new Form({
      email: ['required', 'validEmail'],
      name: ['required'],
    })

  }

  $onInit() {
    this.cardElement = this.stripe.elements().create('card');
    this.cardElement.mount('#stripe-card-element');
  }

  somethingWentWrong(error) {
    console.error(error)
    this.$notifier.red('Something went wrong');
  }

  onReportGenerationError() {
    this.isSubmittingPayment = false;
    this.resolve({error: 'Report generation failed.'})
  }

  onPaymentError(err) {
    if (err === "Report generation failed") return this.onReportGenerationError();
    const stripeErrorMessages = {
      "card_declined": "Card was declined",
      "incomplete_zip": "Your postal code is invalid",
      "incomplete_number": "Your card number is incomplete",
    }
    if (Object.keys(stripeErrorMessages).includes(err.code)) {
      this.paymentForm.addError("card", stripeErrorMessages[err.code])
    } else {
      this.somethingWentWrong(err);
    }
    this.isSubmittingPayment = false;
  }

  hidePaymentForm() {
    this.resolve(false);
  }

  checkForStripeError(response) {
    if (response.error) throw response.error; 
    return new Promise(resolve => resolve(response));
  }

  fulfillOrder(response) {
    const { id } = response.paymentIntent;
    const { source, limit, email } = this.options
    // By this point we've charged the user.
    // If anything goes wrong, we don't want them submitting payment again
    // So rescue errors with a custom message
    return this.$resourceManager.request('report_orders', 'save', null, { source, limit, email, intent_id: id }).catch(() => {
      throw "Report generation failed";
    })
  }

  saveEmailToIntent() {
    const { source, limit, email, country } = this.options
    return this.$resourceManager.request('report_orders', 'update', { id: this.intent.id }, { source, limit, email, country })
  }

  charge() {
    return this.stripe.handleCardPayment(
      this.intent.client_secret, this.cardElement, { payment_method_data: { billing_details: { name: this.options.name } } }
    );
  }

  complete() {
    this.processing = false;
    this.isSubmittingPayment = false;
    this.isPaymentComplete = true;
    this.$timeout(() => {
      this.showSummary = true;
    }, 1500)
  }

  reset() {
    window.location.href = window.location.href;
  }

  resolve(value) {
    this.close({value})
  }

  onSubmitPayment () {
    const { email, name } = this.options;
    if (!this.paymentForm.validate({ email, name })) return false;
    this.isSubmittingPayment = true;
    this.saveEmailToIntent()
      .then(this.charge.bind(this))
      .then(this.checkForStripeError.bind(this))
      .then(this.fulfillOrder.bind(this))
      .then(this.complete.bind(this))
      .catch(this.onPaymentError.bind(this))
  };

}

Controller.$inject = ['$resourceManager', '$notifier', '$timeout', 'Form']

export const reportPaymentModal = {
  controller: Controller,
  bindings: {
    close: '&',
    intent: '<',
    options: '<'
  },
  template: `
    <div class="payment-form-contents fade" ng-show='!$ctrl.showSummary'>

      <button class='dismiss-standard' ng-click='$ctrl.hidePaymentForm()'>Close</button>

      <h3 class='payment-form-title'>Complete report order</h3>

      <text-field model='$ctrl.options.name' error='$ctrl.paymentForm.errors.name'>
        Name on card
      </text-field>

      <text-field model='$ctrl.options.email' error='$ctrl.paymentForm.errors.email'>
        Email address
      </text-field>

      <stripe-card-field error='$ctrl.paymentForm.errors.card'>
        Card details
      </stripe-card-field>

      <div class='form-row submit'>
        <async-button icon='padlock' on-click='$ctrl.onSubmitPayment()' loading='$ctrl.isSubmittingPayment' complete='$ctrl.isPaymentComplete'>
          Submit Payment
        </async-button>
      </div>

      <p class='explainer'>
        Your report will be generated and sent via email within minutes of ordering.
      </p>

    </div>

    <div class='report-summary fade' ng-show='$ctrl.showSummary'>
      <div class='big-tick'></div>
      <h3>Your report is being generated.</h3>
      <p>Your payment was successful and we're generating your report.<br>It'll be emailed to you when it's ready.</p>
      <p>If you haven't received your report within 30 minutes, just email <a href='mailto:support@wordtracker.com' target='_blank'>support@wordtracker.com</a> and we'll get straight back to you.</p>
      <button class='standard finish-button' ng-click='$ctrl.reset()'>Finish</button>
    </div>
  `
}