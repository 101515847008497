export class $listManagementNotifier {

  constructor($timeout, $sce) {
    this.$timeout = $timeout;
    this.$sce = $sce;
  }

  create(type, text, persist) {
    if (this.timeout) this.$timeout.cancel(this.timeout);
    this.type = type;
    this.text = this.$sce.trustAsHtml(String(text));
    this.active = true;
    this.timeout = this.$timeout(() => {
      if (!persist) this.dismiss();
    }, 2000);
  }

  pending(text) {
    this.create('pending', text, true);
  }

  success(text, persist) {
    this.create('success', text, persist);
  }

  error(text, persist) {
    this.create('error', text, persist);
  }

  dismiss() {
    this.active = false;
    this.type = null;
    this.text = null;
  }

}

$listManagementNotifier.$inject = ['$timeout', '$sce'];
