export const gradedColorClass = function() {
  return function(value, target) {
    if (!value || value === -1) return 'graded-null';
    var classes = ['graded-1', 'graded-2', 'graded-3', 'graded-4', 'graded-5', 'graded-6', 'graded-7', 'graded-8', 'graded-9'];
    // var bracket = 0.4;
    // var quarterBracket = bracket / 4; // 0.1
    // var threeQuarterBracket = bracket / 4 * 3; // 0.3
    // var tickSize = bracket / classes.length; //0.044;
    // var diff, index;
    
    // diff = (value - target); // Get the difference // 0.5
    // diff = Math.min(Math.max(diff, -threeQuarterBracket), quarterBracket); // Clamp diff by bracket
    
    
    // diff = diff + threeQuarterBracket; // Offset clampdiff so the min is zero
    // index = Math.round(diff / tickSize); // Get index of diff along a graded scale from min to max bracket of bracket. 
    var tickSize = 0.1111111111;
    value = Math.max(0, Math.min(value, 1));
    let index = Math.round(value / tickSize); // Get index of diff along a graded scale from min to max bracket of bracket. 

    if (!index) index = 1;
    return classes[index - 1];
  };
};