import { extend } from "underscore";

export const KeywordsForDomain = function($searchAbstract) {
  function _KeywordsForDomain (domainSearchSettings, orderBy) {
    var payload = function () {
      var _payload = {};
      extend(_payload, domainSearchSettings.searchParams);
      _payload.site = domainSearchSettings.searchParams.site;
      _payload.method = 'keywords_for_domain';
      _payload.sort_by = orderBy;
      return _payload;
    };

    let type = 'keywords_for_domain';
    let storeKey = `keywords_for_domain_by_${orderBy}`;
    let source = 'wordtracker';

    var search = $searchAbstract(type, source, storeKey, payload);

    return search;
  }

  return _KeywordsForDomain;
};

KeywordsForDomain.$inject = ['$searchAbstract'];