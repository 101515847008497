export const callLimits = {
  template: `
<table class="pricing call-limits">
  <thead>
    <tr>
      <th class='title-col'>Call type</th>
      <th>Seeds</th>
      <th>Results</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class='title-col'>Search</td>
      <td>1</td>
      <td>2,000</td>
    </tr>
    <tr>
      <td class='title-col'>Fetch</td>
      <td>500</td>
      <td>500</td>
    </tr>
  </tbody>
</table>
`
};