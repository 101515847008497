class Controller {

  constructor($saveToList, $lists, $listNavigation) {
    this.s = $saveToList;
    this.$lists = $lists;
    this.fileNav = $listNavigation;
    this.lists = $lists.lists;
    this.projects = $lists.projects;
    $lists.initialize();
  }

  resolve(value) {
    this.close({value});
  }

  createList() {
    this.resolve({
      onClose: () => {
        this.s.newSave(this.fileNav.selector.selected)
      }
    })
  }

}

export const appendToListModal = {
  controller: Controller,
  bindings: {
    close: '&',
    listType: '<'
  },
  template: `
    <div class="modal-header modal-save-to-list">
      Save keywords to list
      <button type='button' class='dismiss-standard' ng-click='$ctrl.resolve(false)'>&times;</button>
    </div>
    <div class="modal-body" id="modal-body">
      <list-tree type='$ctrl.listType'></list-tree>
    </div>
    <div class="modal-footer modal-save-to-list">
      <span class='modal-processing-note' ng-if='!$ctrl.s.newSaveModal && $ctrl.s.saving && $ctrl.s.loadingKeywords'><span class='spinner'></span> Loading keywords to save... ({{ $ctrl.s.loadedCount }} / {{ $ctrl.s.expectedCount }})</span>
      <span class='modal-processing-note' ng-if='!$ctrl.s.newSaveModal && $ctrl.s.saving && !$ctrl.s.loadingKeywords'><span class='spinner'></span> Processing {{$ctrl.s.loadedCount}} keywords...</span>
      <button class='create-standard' ng-click='$ctrl.createList()' ng-disabled='$ctrl.s.saving'>Create new list...</button>
      <button class='save-standard' ng-click='$ctrl.s.appendToList($ctrl.fileNav.selector.selected, $ctrl.resolve.bind($ctrl))' ng-disabled='!$ctrl.fileNav.selector.selected || $ctrl.fileNav.selector.type === "project" || $ctrl.s.saving'>{{ $ctrl.s.saving ? 'Saving...' : 'Add to selected list' }}</button>
    </div>
  `,
}

Controller.$inject = ['$saveToList', '$lists', '$listNavigation'];




