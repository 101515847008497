import React from 'react'
import classNames from 'classnames';

export default function SignupFormProgressIndicator({onClickNode, activeStage, progress, userState}) {
  return (
    <div className='signup-form-progress-indicator'>

      <div onClick={() => onClickNode(1)} className={`signup-form-progress-stage details ${classNames({active: activeStage === 1, complete: progress > 1})}`}>
        <div className='signup-form-progress-marker'></div>
        <h5 className='signup-form-progress-stage-name'>Details</h5>
      </div>

      <div onClick={() => onClickNode(2)} className={`signup-form-progress-stage billing ${classNames({active: activeStage === 2, complete: progress > 2, available: progress > activeStage})}`}>
        <div className='signup-form-progress-marker'></div>
        <h5 className='signup-form-progress-stage-name'>Billing</h5>
      </div>

      <div onClick={() => onClickNode(3)} className={`signup-form-progress-stage done ${classNames({complete: progress > 2})}`}>
        <div className='signup-form-progress-marker'></div>
        <h5 className='signup-form-progress-stage-name'>Done!</h5>
      </div>

  </div>
  )
}
