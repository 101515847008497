import "./fileNode.scss";

class _Controller {
  constructor($listNavigation) {
    this.fileNav = $listNavigation;
    this.dragDisabled = false;
  }

  dragStart(movingNode) {
    this.movingNode = movingNode;
  }

}

_Controller.$inject = ['$listNavigation'];

export const fileNode = {
  template: require('./fileNode.html'),
  controller: _Controller,
  bindings: {
    node: '<',
    type: '<'
  }
}

