import { omit } from "underscore";
import { v4 as uuidv4 } from 'uuid'
import { $resourceRoutes } from "../resource/resourceRoutes.service";

export const $requestManager = function ($resultsStore, cfpLoadingBar, $timeout, $resourceManager) {
  var requestManager = {
    searches: {
      // STRUCTURE //
      // main: {
      //   searchId: 'ABCDEF',
      //   requestIds: {
      //     '12345' => {source: SOURCE, supplier: SUPPLIER, status: 'accepted'},
      //     '67890' => {source: SOURCE, supplier: SUPPLIER, status: ''},
      //   }
      // }
      // datamuse: {
      //  ...
      // }
    }
  };

  requestManager.addRequest = function (searchId, requestId, storeKey, source, supplier) {
    const entry = requestManager.searches[storeKey]
    if (entry.searchId === searchId) {
      requestManager.searches[storeKey].requestIds[requestId] = { source, supplier, status: 'processing' }
      return true;
    }
    return false;
  };

  requestManager.addResponse = function (requestId, storeKey) {
    const entry = requestManager.searches[storeKey].requestIds[requestId]
    if (entry) {
      entry.status = 'accepted'
      requestManager.searches[storeKey].requestIds[requestId] = entry;
      return true;
    }
    return false;
  };

  requestManager.otherRequestIds = function(requestId) {
    const { storeKey } = requestManager.getRequest(requestId);
    const otherRequests = omit(requestManager.searches[storeKey].requestIds, requestId)
    return Object.keys(otherRequests);
  }

  requestManager.updateLoadingBar = function(requestId) {
    const { storeKey } = requestManager.getRequest(requestId);
    if (storeKey === 'main' || storeKey === 'ranking') {
      if (requestManager.otherRequestIds(requestId).length > 0) {
        cfpLoadingBar.set(0.6);
      } else {
        cfpLoadingBar.set(1);
        $timeout(() => cfpLoadingBar.complete(), 50);
      }
    }
  }

  requestManager.deleteRequest = function (requestId) {
    requestManager.updateLoadingBar(requestId);
    return Object.entries(requestManager.searches).reduce((result, [storeKey, value]) => {
      if (value.requestIds.hasOwnProperty(requestId)) delete value.requestIds[requestId];
      result[storeKey] = {
        ...value.searchId,
        requestIds: value.requestIds
      }
      return result;
    }, {})
  };

  requestManager.setup = function (storeKey, storeObject, shouldClear) {
    const existing = requestManager.searches[storeKey];
    if (existing && !shouldClear) return existing.searchId;
    $resultsStore[storeKey] = storeObject;
    const searchId = uuidv4();
    requestManager.searches[storeKey] = { searchId, requestIds: {} }
    return searchId;
  };

  requestManager.getRequest = requestId => {
    return Object.entries(requestManager.searches).reduce((result, [storeKey, value]) => {
      if (value.requestIds.hasOwnProperty(requestId)) result = { storeKey, ...value.requestIds[requestId] };
      return result;
    }, null)
  }
  
  requestManager.fail = function (requestId) {
    const { storeKey, supplier } = requestManager.getRequest(requestId)
    if ($resultsStore[storeKey] && supplier != 'gsc') {
      $resultsStore[storeKey].fail();
    }
  };
  
  requestManager.addData = function (requestId, data) {
    const { storeKey } = requestManager.getRequest(requestId)
    if ($resultsStore[storeKey]) {
      $resultsStore[storeKey].addFromCable(data);
    //   let p;
    //   if (storeKey === 'main' && parseInt(data.start) === 1) {
    //     performance.mark('mainResultsReturned');
    //     performance.measure('main', 'searchStart', 'mainResultsReturned');
    //     p = performance.getEntriesByName("main", "measure");
    //   }
    //   if (storeKey === 'datamuse') {
    //     performance.mark('lateralResultsReturned');
    //     performance.measure('lateral', 'searchStart', 'lateralResultsReturned');
    //     p = performance.getEntriesByName("lateral", "measure");
    //   }
    //   for (var i=0; i < p.length; i++) {
    //     // console.log (`${p[i].name}: ${parseFloat(p[i].duration/1000).toFixed(1)}s`) 
    //     // window.dataLayer = window.dataLayer || [];
    //     const params = {
    //       'search_type': p[i].name,
    //       'time': p[i].duration,
    //     }
    //     if (p[i].name === 'mainResultsReturned') {
    //       params.source = $resultsStore[storeKey].activeSeed.source;
    //     }
    //     $resourceManager.request('analytic_events', 'save', null, {analytic_event: params} )
    //   }
    }
  };

  return requestManager;
};

$requestManager.$inject = ['$resultsStore', 'cfpLoadingBar', '$timeout', '$resourceManager'];
