import "./rightSidebar.scss";


class _Controller {
  constructor($textFilters, $window, $timeout, $upsells) {
    this.textFilters = $textFilters;
    this.$upsells = $upsells;
    this.includingText = null;
    this.excludingText = null;
    this.$window = $window;
    this.$timeout = $timeout;
    $($window).on('scroll', this.setSidebarHeight);
  }

  $onInit() {
    this.$timeout(() => {
      this.setSidebarHeight();
    });

    $(this.$window).on('resize', () => this.setSidebarHeight);

    $(window).on('scroll', () => this.setSidebarHeight);
  }

  setSidebarHeight() {
    let $elem = angular.element('.right-sidebar-contents');
    if ($elem.length > 0) {
      let offsetTop = $elem.offset().top;
      let scrollTop = $(window).scrollTop();
      let relativeTop = offsetTop - scrollTop;
      let windowHeight = $(window).innerHeight();

      $elem.css('height', windowHeight - relativeTop + 'px');
    }
  }

  addIncludingFilter() {
    this.textFilters.create("includes", this.filterText);
    this.filterText = null;
  }

  addExcludingFilter() {
    this.textFilters.create("excludes", this.filterText);
    this.filterText = null;
  }

}

_Controller.$inject = ['$textFilters', '$window', '$timeout', '$upsells'];

export const rightSidebar = {
  bindings: {
    results: '<',
    searchSettings: '<',
    onClickLateralResultFn: '&'
  },
  transclude: true,
  controller: _Controller,
  template: require('./rightSidebar.html')
};
