import "./searchDebug.scss";

import { findWhere } from 'underscore';
import { diffString } from 'json-diff';

var Convert = require('ansi-to-html');
var convert = new Convert();

class _Controller {
  constructor($searchDebug, $sce) {
    this.$s = $searchDebug;
    this.$sce = $sce;

    this.view = "manager";
    this.viewDiff = true;
  }

  stringify(obj) {
    return JSON.stringify(obj, null, 2)
  }

  format(event, key) {
    if (!event) return null;
    const obj = event[key];
    const prevObj = (findWhere(this.$s.events, {uuid: event.prevUuid}) || {})[key];

    const diff = diffString(prevObj, obj);

    return (diff === "") ? "No changes" : this.$sce.trustAsHtml(convert.toHtml(diff));

  }
  
}

_Controller.$inject = ['$searchDebug', '$sce'];

export const searchDebug = {
  controller: _Controller,
  template: require('./searchDebug.html')
};
