import React, { useEffect } from 'react'

export default function StripeCardField({stripe, card, onCard}) {

  const elements = stripe.elements();

  const style = {
    base: {
      color: '#2b2b2b',
      fontFamily: '"Locator Regular", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#c3c3c1'
      },
      padding: '12px 8px'
    },
    invalid: {
      color: '#bb0022',
      iconColor: '#bb0022'
    }
  };

  useEffect(() => {
    const cardElement = card || elements.create('card', {style: style});
    cardElement.mount(document.getElementById('stripe-form-card-element'));
    onCard(cardElement);
  }, []);

  return <div id='stripe-form-card-element' className='card-element'></div>

}