import timeLimitedPromise from '../../global/utilities/timeLimitedPromise';

const RANKING_MAX_KEYWORDS = 1000;

export const $saveToList = function($lists, $resultsStore, $notifier, $q, $timeout, Modal, $location) {

  let service = {
    collection: null,
    selectedId: null,
    loadedCount: 0,
    expectedCount: 0,
    loadingKeywords: false,
    debug: false,
  };

  service.newSave = (projectId) => {
    service.selectedId = projectId;
    Modal.new({
      component: 'createListModal',
      dismissable: true,
    })
  };

  service.newAppend = (collection) => {
    service.collection = collection || $resultsStore.main;
    service.targetList = { id: null, title: null };
    Modal.new({
      component: 'appendToListModal',
      dismissable: true,
      bindings: {
        listType: 'keywords'
      }
    }).then(({onClose}) => {
      if (onClose) onClose();
    })
  };

  service.newTrack = (collection, domain = null, collectionCountry = 'US') => {
    service.collection = collection;
    service.targetList = { id: null, title: null, domain };
    const getKeywordsFn = async list => {
      const keywords = await service.loadKeywordsToSave(RANKING_MAX_KEYWORDS);
      return keywords.slice(0, RANKING_MAX_KEYWORDS).map(keyword => ({keyword: keyword.keyword, country: list.countries[0], source: 'ranking'}));
    };
    Modal.new({
      component: 'createListModal',
      dismissable: true,
      bindings: {
        isRankingList: true,
        domain: domain ? `${domain}` : null,
        country: `${collectionCountry}`,
        countrySource: 'ranking',
        getKeywordsFn
      }
    }).then(list => {
      $location.path('/ranking/' + list.id).search({});
    });
  };

  service.setTargetList = (listId) => {
    service.targetList = angular.copy($lists.getListById(listId));
  };

  service.waitForKeywords = (getKeywordsFn) => {
    return new Promise(function recursor(resolve) {
      const keywords = getKeywordsFn();
      service.loadedCount = keywords.length;
      if (service.loadedCount >= service.expectedCount || service.collection.finished) {
        service.loadingKeywords = false;
        resolve(keywords);
      } else {
        $timeout(recursor.bind(null, resolve), 2000); // Check again every two seconds.
      }
    });
  }

  const getExpectedCount = () => {
    const available          = service.collection.count;
    const manuallyDeselected = (service.collection.keywords.length - service.collection.selectedCount);

    return (service.collection.selectAll) ? (available - manuallyDeselected) : available;
  };

  service.loadKeywordsToSave = (maxKeywords) => {

    return $q(resolve => {

      // Get keywords that are physically 'ticked' or, if filters active, 'ticked' and matching filters.
      var selected = service.collection.getSelected();

      // If some keywords are physically ticked, and select all is not ticked, we can ignore the pages that haven't loaded yet.
      if (selected.length > 0 && !service.collection.selectAll) {
        service.loadedCount = selected.length;
        return resolve(selected);
      }

      // If we're here then we know the user has either clicked 'select all' or they've clicked
      // 'save' with nothing ticked. Either way this means they want to save the entire collection.

      // First, set loading, because the user will be waiting while we load keywords...
      service.loadingKeywords = true;

      // Set up the select keywords function
      const selectKeywordsFn = service.collection.getActionable.bind(service.collection);

      // Calculate the number of keywords we want to save (and therefore wait for).
      service.expectedCount = maxKeywords ? Math.min(maxKeywords, getExpectedCount()) : getExpectedCount();

      // Wait until the number of keywords loaded matches the expected count, then resolve.
      service.waitForKeywords(selectKeywordsFn).then(resolve);
    });
  };

  service.setSavingFinished = (closeModalFn) => {
    $notifier.green('Saved');
    closeModalFn();
    service.savingNewList = false;
    service.importingKeywords = false;
    service.saving = false;
  }

  service.createListWithKeywords = (listParams, getKeywordsFn, resolveModalFn) => {
    const project_id = service.selectedId;
    service.savingNewList = true;
    $lists.createList({...listParams, project_id}).then(list => {
      service.savingNewList = false;
      service.importingKeywords = true;
      list = {...list, ...listParams} // Some params like countries are not saved to the db, so merge them from request params
      if (!getKeywordsFn) getKeywordsFn = async (_list) => await service.loadKeywordsToSave(); // The default function which loads keywords from the collection. Wrapped in a funciton to toss out list, as loadKeywordsToSave asks for maxKeywords
      getKeywordsFn(list).then(keywords => {
        return importKeywords(list, keywords, resolveModalFn);
      });
    });
  };

  service.appendToList = (targetListId, resolveModalFn) => {
    service.setTargetList(targetListId);
    service.saving = true;
    service.loadKeywordsToSave().then(keywords => {
      return importKeywords(service.targetList, keywords, resolveModalFn);
    })
  };

  const importKeywords = (list, keywords, resolveModalFn) => {
    const importPromise = $lists.importKeywords(list, keywords);
    timeLimitedPromise(importPromise, 28000)
      .then(res => {
        // Update list metadata in memory, so hard reload is not required
        const sources = _.union(list.sources, keywords.map(k => k.source));
        const keywords_count = (list.keywords_count || 0) + keywords.length;
        const newList = {...list, sources, keywords_count};
        $lists.replaceList(list, newList);
        service.setSavingFinished(() => resolveModalFn(list));
      })
      .catch(error => {
        console.error(error);
        service.setSavingFinished(() => resolveModalFn(false))
      });
  }

  return service;
};

$saveToList.$inject = ['$lists', '$resultsStore', '$notifier', '$q', '$timeout', 'Modal', '$location'];