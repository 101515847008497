class _Controller {

  constructor() {}

  popSelected() {
    var forRemoval = this.collection.getActionable().map((result) => result.keyword);
    this.collection.remove(forRemoval);
  }

}

_Controller.$inject = ['$upsells', '$limits', '$saveToList'];

export const removeButton = {
  bindings: { collection: '<' },
  controller: _Controller,
  template: `<button class='delete-from-list' ng-click='$ctrl.popSelected()' ng-disabled='!$ctrl.collection.keywords || $ctrl.collection.keywords && ($ctrl.collection.getActionable().length < 1)'>Remove...</button>`
}


