import { lists } from "./lists.component";
import { listsIndex } from "./listsIndex.component";
import { projectShow } from "./projectShow.component";
import { listShow } from "./listShow.component";
import { fileNode } from "./file_node/fileNode.component";
import { $listNavigation } from "./listNavigation.service";

export const LISTS_MODULE = angular.module('listsModule', []);

const listsState = {
  parent: 'app',
  name: 'lists',
  component: 'lists',
};

const listsIndexState = {
  parent: 'lists',
  name: 'listsIndex',
  title: 'Lists | Wordtracker',
  section: 'lists',
  url: '/lists',
  component: 'listsIndex',
  reload: true,
};

const listShowState = {
  parent: 'lists',
  name: 'listShow',
  title: 'Lists | Wordtracker',
  section: 'lists',
  url: '/lists/:id',
  component: 'listShow',
  reload: true,
};

const projectState = {
  parent: 'lists',
  name: 'projectShow',
  title: 'Lists | Wordtracker',
  section: 'lists',
  url: '/lists/folder/:id',
  component: 'projectShow',
  reload: true,
};

LISTS_MODULE.service('$listNavigation', $listNavigation);
LISTS_MODULE.component('lists', lists);
LISTS_MODULE.component('listsIndex', listsIndex);
LISTS_MODULE.component('listShow', listShow);
LISTS_MODULE.component('projectShow', projectShow);
LISTS_MODULE.component('fileNode', fileNode);

LISTS_MODULE.config(['$stateRegistryProvider', function($stateRegistry) {
  $stateRegistry.register(listsState);
  $stateRegistry.register(listsIndexState);
  $stateRegistry.register(listShowState);
  $stateRegistry.register(projectState);
}]);
