// TODO: Work out what this is for and make it do only that

export const $listNavigation = function($lists, $notifier, $location, OrderBy, Modal) {

  let service  = {
    selector: {
      selected: null,
      type: null
    },
    newFolderParentId: null,
    folderName: null,
    showRename: false,
    renameType: null,
    expandedIds: [],
    orderBy: new OrderBy({column: 'title', direction: 'ASC', folderComparator: 'keywords_count'})
  };

  service.newRename = () => {
    service.renameType = service.selector.type;
    service.renameObject = _.findWhere($lists[service.renameType + 's'], {id: parseInt(service.selector.selected)});
    const _renameType = service.renameType.replace(/project/i, 'folder');
    Modal.new({
      component: 'stringInputModal',
      dismissable: true,
      bindings: {
        heading: "Rename " + _renameType,
        description: "Enter a new name for this " + _renameType,
        placeholder: "My new " + _renameType + " name",
        label: _renameType + " name",
        initialValue: service.renameObject.title,
      },
    }).then(res => {
      if (res) service.rename(res)
    })
  };

  service.rename = (title) => {
    let promiseMethod = $lists['update' + _.capitalize(service.renameType)];
    service.renameObject.title = title;
    promiseMethod(service.renameObject, {title: title}).then(() => {
      $notifier.green('Saved');
    });
  };

  service.newFolder = () => {
    const { selected, type } = service.selector;
    service.newFolderParentId = (!!selected && type === "project") ? selected : null;
    Modal.new({
      component: 'stringInputModal',
      dismissable: true,
      bindings: {
        heading: "Create a new folder",
        description: "Enter a name for your new folder",
        placeholder: "My new folder",
        label: "Folder name"
      },
    }).then(res => {
      if (res) service.createFolder(res)
    })
  };

  service.select = (id, type) => {
    let _selector = {
      selected: id,
      type: type
    };
    angular.copy(_selector, service.selector);
  };

  service.hideModal = () => {
    service.showNewFolder = false;
    service.showRename = false;
  };

  service.expand = folder => {
    if (!service.expandedIds.includes(folder.id)) service.expandedIds.push(folder.id);
  };

  service.collapse = folder => {
    if (service.expandedIds.includes(folder.id)) {
      const idx = service.expandedIds.indexOf(folder.id);
      service.expandedIds.splice(idx, 1);
    }
  }

  service.toggleExpanded = folder => {
    const method = (service.expandedIds.includes(folder.id)) ? 'collapse' : 'expand';
    service[method](folder);
  }

  service.createFolder = title => {
    const project_id = service.newFolderParentId;
    $lists.createProject({ title, project_id }).then(project => {
      $notifier.green(project.title + ' folder created.');
      service.hideModal();
    });
  };

  service.goToMyLists = () => {
    $location.path('/lists');
  };

  service.goToMyRankingLists = () => {
    $location.path('/ranking');
  };

  service.goToProject = project => {
    $location.path('/lists/folder/' + project.id );
  };

  service.goToList = list => {
    const slug = !!list.domain ? 'ranking' : 'lists';
    $location.path(`/${slug}/` + list.id );
  };

  service.moveNode = (movingNode, targetNode, isList) => {
    // Prevent moving parent into child
    // and prevent moving into itself
    // and prevent moving into current folder
    if (targetNode && movingNode.project_id && movingNode.project_id === targetNode.id) { return false };
    if (!movingNode.project_id && !targetNode) { return false };
    if (targetNode && !isList) {
      if (targetNode.parentIds.includes(movingNode.id)) return false;
      if (targetNode.id == movingNode.id) return false;
    }
    
    // Move it.
    const project_id = (!!targetNode) ? targetNode.id : null;
    const method = (isList) ? 'updateList' : 'updateProject';
    if (targetNode) service.expand(targetNode);
    $lists[method](movingNode, { project_id }).then(() => {
      // const movedLocation = (!!targetNode) ? targetNode.title: 'My lists';
      // $notifier.green(movingNode.title + ' moved to ' + movedLocation);
    });
  };

  service.destroyProject = async (projectId) => {
    let project = _.findWhere($lists.projects, {id: projectId});
    if (confirm('Do you want to permanently delete this folder including any subfolders and lists stored inside?')) {
      service.selector.selected = null;
      $lists.deleteProject(project).then(() => $notifier.green('Deleted'));
      return project;
    }
    return false;
  };

  service.destroyList = async (listId) => {
    let list = _.findWhere($lists.lists, {id: listId});
    if (confirm('Do you want to permanently delete this list?')) {
      service.selector.selected = null;
      $lists.deleteList(list).then(() => $notifier.green('Deleted'));
      return list;
    }
    return false;
  };

  service.destroySelected = async () => {
    let objectId = parseInt(service.selector.selected);
    return await service['destroy' + _.capitalize(service.selector.type)](objectId);
  };

  return service;

};

$listNavigation.$inject = ['$lists', '$notifier', '$location', 'OrderBy', 'Modal'];