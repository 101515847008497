export const textInputModal = {
  controller: class {

    $onInit() {
      this.title = this.title || 'Input'
    }

    resolve(value) {
      this.close({value});
    }

  },
  bindings: {
    close: '&',
    title: '<heading',
    label: '<',
    placeholder: '<',
    description: '<',
  },
  template: `
    <div class="modal-header modal-input">
      <span ng-bind-html='$ctrl.title'></span>
      <button type='button' class='dismiss-standard' ng-click='$ctrl.resolve(false)'>&times;</button>
    </div>
    <div class="modal-body" id="modal-body">
      <span ng-bind-html='$ctrl.description' class='description' ng-if='$ctrl.description'></span>
      <div class='form-row'>
        <label class='label'>
          <span ng-bind-html='$ctrl.label' />
          <textarea ng-model='$ctrl.inputValue' placeholder='{{$ctrl.placeholder}}' autofocus noresize />
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class='save-standard' ng-click="$ctrl.resolve($ctrl.inputValue)" ng-disabled='!$ctrl.inputValue'>Submit</button>
      <button type="button" class='cancel-bordered' ng-click="$ctrl.resolve(false)">Cancel</button>
    </div>
  `,
}
