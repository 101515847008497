import { extend } from "underscore";

export const Competitors = function($searchAbstract) {
  function _Competitors (domainSearchSettings) {
    var payload = function () {
      var _payload = {};
      extend(_payload, domainSearchSettings.searchParams);
      _payload.site = domainSearchSettings.searchParams.site;
      _payload.method = 'competitors';
      return _payload;
    };

    var search = $searchAbstract('competitors', 'wordtracker', 'competitors', payload);

    search.store = function () {
      return {
        results: null,
        loading: true,
        failed: false,
        fail: function () {
          this.results = [];
          this.loading = false;
          this.failed = true;
        },
        addFromCable: function (response) {
          this.loading = false;
          this.results = response.competitors.filter(competitor => {
            return (parseInt(competitor.num_shared_keywords) > 0);
          });
          if (['fail', 'notfound'].includes(response.status)) {
            this.fail();
          }
        }
      };
    };

    return search;
  }

  return _Competitors;
};

Competitors.$inject = ['$searchAbstract'];
