import React from 'react'
import SerpPreview from './SerpPreview/SerpPreview'

export default function ExamineKeywordModal({result, serpsResults}) {
  return (
    <div id='examine-modal'>
      {result && <h3 className='examine-modal-title'>SERP preview for <span className='serp-keyword'>{result.keyword}</span></h3>}
      { result && serpsResults && <SerpPreview
        keyword={result.keyword}
        serpsResults={serpsResults}
      ></SerpPreview> }
    </div>
  )
}
