class _Controller {

  constructor($lists, $listNavigation) {
    this.fileNav = $listNavigation;
    this.$lists = $lists;
    this.lists = $lists.lists;
    this.projects = $lists.projects;
    this.tree = $lists.tree;
    this.listType = 'keywords';
    $lists.initialize();
  }
}

_Controller.$inject = ['$lists', '$listNavigation'];

export const listsIndex = {
  controller: _Controller,
  template: require('./listsIndex.html')
};
