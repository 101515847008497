class Controller {
  constructor($window) {
    this.$window = $window;
  }

  goToApiDocs(){
    this.$window.location.href = "https://wordtracker.github.io/keyword-api/";
  }

  goToApiSignUp(){
    this.$window.location.href = "https://wordtracker.3scale.net/";
  }

}

Controller.$inject= ['$window'];

export const apiButtons = {
  controller: Controller,
  template: `<div class='api-buttons dual-buttons'>
    <button class='go-standard' ng-click='$ctrl.goToApiSignUp()'>Get started</button>
    <button class='search-standard' ng-click='$ctrl.goToApiDocs()'>Documentation</button>
  </div>`
};