import React from 'react'

const Form = ({ id, name, children }) => {

  // We'll be submitting async plz
  const handleSubmit = e => e.preventDefault();

  return (
    <form id={id} name={name} onSubmit={handleSubmit}>
      {children}
    </form>
  );

}

export default Form;