import React, { useState, useEffect } from 'react';

const LoadingMessage = ({texts}) => {

  texts = texts || [
    'Getting keywords...',
    'Calculating metrics...',
    'Finalising results...',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1 < texts.length) ? prevIndex + 1 : prevIndex);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [texts]);

  return (
    <span>{texts[currentIndex]}</span>
  );
};

export default LoadingMessage;