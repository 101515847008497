import actionCable from 'actioncable'

export const $cableConnection = function($cookies, $timeout, $requestManager, $searchDebug, $resultsStore, $errors) {

  const onReceiveMessage = function (message) {
    $timeout(function () {
      const request = $requestManager.getRequest(message.request_id) || {};

      const { storeKey, source, supplier } = request;
      $searchDebug.log('okay', `${storeKey} onReceiveMessage received message`, {message});

      if (message.data) {
        if (storeKey) {
          $requestManager.addData(message.request_id, message.data);
          $searchDebug.log('okay', `${storeKey} onReceiveMessage added data`, {source, supplier});
          if (!message.data.has_more) {
            $requestManager.deleteRequest(message.request_id);
            $searchDebug.log('okay', `${storeKey} onReceiveMessage deleted request (request finished)`, {source, supplier});
          }
          if (Object.keys($requestManager.searches[storeKey].requestIds).length === 0) {
            $resultsStore[storeKey].loading = false;
            $searchDebug.log('okay', `${storeKey} requestManager set loading to false (all requests finished)`, {source, supplier});
          }
        } else {
          $searchDebug.log('warn', `onReceiveMessage received old data, ignored`);
        }
      }

      if (message.failed) {
        $requestManager.fail(message.request_id);
        $searchDebug.log('error', `onReceiveMessage called fail`, {source, supplier});
        $requestManager.deleteRequest(message.request_id);
        $searchDebug.log('okay', `${storeKey} onReceiveMessage deleted request (failed)`, {source, supplier});
        if (message.error) $errors.handleError(message.error);
      }

    });

  }

  const url = document.querySelectorAll("meta[name='action-cable-url']")[0].getAttribute('content')

  const api = {
    isConnected: false,
    cable: actionCable.createConsumer(url),
    sessionUid: $cookies.get('_wordtracker_webhook_uuid'),
    onConnectCallbacks: [],
    registerOnConnectCallback: onConnectCallback => {
      if (api.isConnected) return onConnectCallback();
      api.onConnectCallbacks.push(onConnectCallback);
    }
  }

  api.channel = api.cable.subscriptions.create('ResponseChannel', {
    connected: () => {
      api.isConnected = true;
      api.onConnectCallbacks.forEach(onConnectCallback => onConnectCallback());
    },
    disconnected: () => {
      api.isConnected = false;
    },
    received: onReceiveMessage
  })

  return api;

}

$cableConnection.$inject = ['$cookies', '$timeout', '$requestManager', '$searchDebug', '$resultsStore', '$errors']