import { keywords } from "./keywords/keywords.directive";
import { dropdown } from "./dropdown/dropdown.directive";
import { dropdownOption } from "./dropdown/dropdownOption.directive";
import { $upsells } from "./upsells/upsells.service.js";
import { listTree } from "./list_tree/listTree.component";
import { metricColumnSelector } from "./metric_column_selector/metricColumnSelector.component";
import { metricColumnSelectorAndPresets } from "./metric_column_selector_and_presets/metricColumnSelectorAndPresets.component";
import { advancedSearchOptions } from "./advanced_search_options/advancedSearchOptions.component";

import { freeUpsellBanner } from "./freeUpsellBanner.component.js";
import { upgradeNowBanner } from "./upgradeNowBanner.component.js";
import { expiredBanner } from "./expiredBanner.component.js";
import { saveToListButton } from "./saveToListButton.component.js";
import { exportButton } from "./exportButton.component.js";
import { removeButton } from "./removeButton.component.js";

import { $saveToList } from "./save_to_list/saveToList.service";

import { reactResults } from "./keywords/reactResults.directive";
import { renameSelect } from "./rename_select/renameSelect.directive";

import { countryDropdownOption } from "./dropdown/countryDropdownOption.directive";
import { onEnterKey } from "./onEnterKey.directive";
import { callout } from "./callout/callout.directive";
import { fileread } from "./fileread.directive";
import { listBlock } from "./listBlock.directive";
import { dial } from "./dial/dial.directive";
import { simpleSpinner } from "./simple_spinner/simpleSpinner.component";
import { splitButton } from "./split_button/splitButton.directive";

import { DROPDOWNS_MODULE } from "./dropdowns/dropdowns.module";

export const FEATURES_MODULE = angular.module('features', [
  DROPDOWNS_MODULE.name,
]);

FEATURES_MODULE.component('freeUpsellBanner', freeUpsellBanner);
FEATURES_MODULE.component('upgradeNowBanner', upgradeNowBanner);
FEATURES_MODULE.component('expiredBanner', expiredBanner);
FEATURES_MODULE.component('advancedSearchOptions', advancedSearchOptions);
FEATURES_MODULE.component('listTree', listTree);
FEATURES_MODULE.component('saveToListButton', saveToListButton);
FEATURES_MODULE.component('exportButton', exportButton);
FEATURES_MODULE.component('removeButton', removeButton);
FEATURES_MODULE.component('simpleSpinner', simpleSpinner);
FEATURES_MODULE.component('metricColumnSelector', metricColumnSelector);
FEATURES_MODULE.component('metricColumnSelectorAndPresets', metricColumnSelectorAndPresets);

FEATURES_MODULE.service('$upsells', $upsells);
FEATURES_MODULE.service('$saveToList', $saveToList);

FEATURES_MODULE.directive('reactResults', reactResults);
FEATURES_MODULE.directive('keywords', keywords);
FEATURES_MODULE.directive('dropdown', dropdown);
FEATURES_MODULE.directive('dropdownOption', dropdownOption);
FEATURES_MODULE.directive('countryDropdownOption', countryDropdownOption);
FEATURES_MODULE.directive('dial', dial);
FEATURES_MODULE.directive('onEnterKey', onEnterKey);
FEATURES_MODULE.directive('renameSelect', renameSelect);
FEATURES_MODULE.directive('callout', callout);
FEATURES_MODULE.directive('fileread', fileread);
FEATURES_MODULE.directive('listBlock', listBlock);
FEATURES_MODULE.directive('splitButton', splitButton);
