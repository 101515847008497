import "./reportGenerator.scss";

class Controller {
  constructor($countryNames, $resourceManager, $notifier, $timeout, $limits, numberFilter, Modal) {

    this.$resourceManager = $resourceManager;
    this.numberFilter = numberFilter;
    this.$notifier = $notifier;
    this.$timeout = $timeout;
    this.$limits = $limits;
    this.countries = [];
    this.processing = false;
    this.intent = {};
    this.showSupportMessage = false;
    this.Modal = Modal;

    // const countries = ["global", ...$limits.countries.clickstream.available];
    const countries = ["global", "US", "GB", "CA", "AU", "NZ"];

    this.countries = countries.reduce((array, countryCode) => {
      array.push({ code: countryCode, name: $countryNames[countryCode] })
      return array;
    }, [])

    this.sources = ["google", "amazon", "youtube", "ebay"]

    this.pricing = {
      "google": {
        "top_1000": 10,
        "top_10000": 50,
        "top_100000": 100
      },
      "amazon": {
        "top_1000": 25,
        "top_10000": 200,
        "top_100000": 1500
      },
      "youtube": {
        "top_1000": 25,
        "top_10000": 200,
        "top_100000": 1500
      },
      "ebay": {
        "top_1000": 25,
        "top_10000": 200,
        "top_100000": 1500
      }
    }

  }

  $onInit() {
    this.resetOptions();
  }

  resetOptions() {
    this.price = null;
    this.options = {
      source: null,
      country: null,
      limit: null,
      email: null,
      name: null,
    }
  }

  setSource(source) {
    this.options.source = source;
    this.calculatePricing();
  }
  
  setCountry(country) {
    this.options.country = country.code;
  }
  
  setLimit(number) {
    this.options.limit = number;
    this.calculatePricing();
  }

  calculatePricing() {
    const { source, limit } = this.options;
    try { this.price = this.pricing[source][`top_${limit}`] } catch {}
  }

  newOrder() {
    this.processing = true;
    const { source, limit, country } = this.options;
    const promise = (this.intent.id) ?
      this.$resourceManager.request('report_orders', 'update', {id: this.intent.id}, { source, limit, country }) :
      this.$resourceManager.request('report_orders', 'new', { source, limit, country });
    promise
      .then(this.setupPayment.bind(this))
      .catch(this.onNewOrderError.bind(this))
  }

  onNewOrderError(err) {
    this.processing = false;
    this.$notifier.red({
      422: "Please check options"
    }[err.status] || "Something went wrong");
  }

  somethingWentWrong(error) {
    console.error(error)
    this.$notifier.red('Something went wrong');
  }

  contactSupport() {
    this.showSupportMessage = true;
    this.processing = false;
  }

  onPaymentError(err) {
    if (err === "Report generation failed") return this.contactSupport();
    const stripeErrorMessages = {
      "card_declined": "Card was declined",
      "incomplete_zip": "Your postal code is invalid",
      "incomplete_number": "Your card number is incomplete",
    }
    if (Object.keys(stripeErrorMessages).includes(err.code)) {
      this.paymentForm.addError("card", stripeErrorMessages[err.code])
    } else {
      this.somethingWentWrong(err);
    }
    this.isSubmittingPayment = false;
  }

  setupPayment(response) {
    const { id, client_secret } = response.intent;
    this.intent = { id, client_secret }; 
    this.showPaymentForm();
  };

  showPaymentForm() {
    this.Modal.new({
      component: 'reportPaymentModal',
      bindings: {
        intent: this.intent,
        options: this.options
      }
    }).then(res => {
      this.processing = false;
      if (res.error) {
        if (error === 'Report generation failed.') this.contactSupport();
      }
    })
  }

  reset() {
    window.location.href = window.location.href;
  }

}

Controller.$inject = ['$countryNames', '$resourceManager', '$notifier', '$timeout', '$limits', 'numberFilter', 'Modal'];

export const reportGenerator = {
  controller: Controller,
  template: require('./reportGenerator.html')
};