import './autoplayVideo.scss';

class _Controller {
  constructor(UserService, $location) {
    this.isFreeUser = UserService.user.isFreeUser;
    this.$location = $location;
    this.autoplay = false;
  }

  $onInit() {
    let params = this.$location.search();
    if (params.autoplay && params.autoplay === "true") {
      this.autoplay = true;
    }
    if (this.isFreeUser) {
      this.showVideo = true;
    }
  }

  closeVideo() {
    this.showVideo = false;
    this.autoplay = false;
    let params = this.$location.search();
    if (params) {
      const { autoplay, ...rest} = params;
      this.$location.search({...rest});
    }
  }

}

_Controller.$inject = ['UserService', '$location'];

export const autoplayVideo = {
  template: `<script src="https://fast.wistia.com/embed/medias/9r7b75kjf6.jsonp" async></script>
             <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
             <div ng-if='$ctrl.showVideo' class='lannisterWistaVideoContainer'>
              <span class="wistia_embed wistia_async_9r7b75kjf6 popover=true popoverAnimateThumbnail=true popoverShowOnLoad={{$ctrl.autoplay}}" style="width: 300px; height: 169px;">&nbsp;</span>
              <button ng-click='$ctrl.closeVideo()' class='dismiss-standard'>&times;</button>
            </div>`,
  controller: _Controller
}
