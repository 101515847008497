import validTlds from "./validTlds";

export default (url) => {
  const urlPattern = /^(?:(ftp|http[s]?):\/\/)?([a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,})(:\d{2,5})?(\/\S*)?$/i;

  const match = url.match(urlPattern);

  if (match) {
    const domain = match[2].toUpperCase();
    const tld = domain.split('.').pop();
    return validTlds.includes(tld);
  }


  return false;
}