import { intersection, union } from 'underscore';

export class $limits {

  constructor($googleCountries) {
    this.$googleCountries = $googleCountries;
  }

  initialize(rawLimits, user) {
    user = user || {};
    this.raw = rawLimits;
    this.countries = this.raw.countries;
    const { disabled_features  } = this.raw;
    this.upgradeFeatures = disabled_features;
  }

  can(featureName) {
    if (this.upgradeFeatures) {
      return this.upgradeFeatures.indexOf(featureName) === -1;
    } else {
      return true;
    }
  }

  cant(featureName) {
    return !this.can(featureName);
  }
}

$limits.$inject = ['$googleCountries'];
