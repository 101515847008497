class _Controller {

  constructor($upsells, $limits, $saveToList) {
    this.$saveToList = $saveToList;
    this.$upsells = $upsells;
    this.$limits = $limits;
  }

  newSave(collection) {
    this.$saveToList.newAppend(collection);
  }

}

_Controller.$inject = ['$upsells', '$limits', '$saveToList'];

export const saveToListButton = {
  bindings: { collection: '<' },
  controller: _Controller,
  template: `
    <button class='save-to-list' ng-if='$ctrl.$limits.cant("lists")' ng-click='$ctrl.$upsells.new("lists")' ng-disabled='!$ctrl.collection.keywords || $ctrl.collection.keywords && ($ctrl.collection.getActionable().length < 1)'>Save</button>
    <button class='save-to-list' ng-if='$ctrl.$limits.can("lists")' ng-click='$ctrl.newSave()' ng-disabled='!$ctrl.collection.keywords || $ctrl.collection.keywords && ($ctrl.collection.getActionable().length < 1)'>Save</button>
  `
}
