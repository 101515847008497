export const $activeList = function($lists, $q, $cookies, UserService) {

  const COOKIE_KEY = '__wordtracker_activeListId';

  let service = {
    list: {},
    loading: true
  };

  service.initialize = () => {
    return $q(resolve => {
      if (!$lists.loaded) $lists.initialize().then(service.initialize);
      else {
        const listId = $cookies.get(COOKIE_KEY);
        let list = (listId && !UserService.user.isFreeUser) ? $lists.lists.filter(l => l.id == listId)[0] : null;
        if (!list) list = service.getUntitledList();
        service.loading = false;
        resolve(angular.copy(list, service.list));
      }
    });
  };

  service.newList = () => {
    const list = {title: 'Untitled list', keywords: []};
    $cookies.put(COOKIE_KEY, list.id);
    angular.copy(list, service.list);
  }

  service.getUntitledList = () => {
    return _.last(_.sortBy(_.where($lists.lists, {title: 'Untitled list'}), 'created_at')) || {title: 'Untitled list', keywords: []};
  };

  service.ensureActiveListExists = () => {
    return $q(resolve => {
      if (service.list.id) resolve();
      else {
        $lists.createList({title: service.list.title}).then(list => {
          list.keywords = service.list.keywords;
          angular.copy(list, service.list);
          $cookies.put(COOKIE_KEY, list.id);
          resolve();
        });
      }
    });
  };

  service.saveKeywordsToList = (keywords) => {
    return service.ensureActiveListExists().then(() => {
      return $lists.saveKeywordsToList(keywords, service.list);
    });
  };

  service.removeKeyword = keyword => {
    return $lists.removeKeywordsFromList([keyword], service.list);
  }

  service.updateActiveList = (payload) => {
    return service.ensureActiveListExists().then(() => {
      return $lists.updateList(service.list, payload);
    });
  };

  service.setActiveList = (list) => {
    service.loading = true;
    $lists.loadListKeywords(list).then(() => {
      $cookies.put(COOKIE_KEY, list.id);
      service.loading = false;
      angular.copy(list, service.list)
    });
  };

  return service;
};

$activeList.$inject = ['$lists', '$q', '$cookies', 'UserService'];
