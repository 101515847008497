export const confirmModal = {
  controller: class {

    $onInit() {
      this.title = this.title || 'Confirm'
    }

    resolve(value) {
      this.close({value});
    }

  },
  bindings: {
    close: '&',
    title: '<heading',
    description: '<',
  },
  template: `
    <div class="modal-header">
      <span ng-bind-html='$ctrl.title'></span>
      <button type='button' class='dismiss-standard' ng-click='$ctrl.resolve(false)'>&times;</button>
    </div>
    <div class="modal-body" id="modal-body" ng-bind-html='$ctrl.description'></div>
    <div class="modal-footer">
      <button type="button" class='save-standard' ng-click="$ctrl.resolve(true)">Confirm</button>
      <button type="button" class='cancel-bordered' ng-click="$ctrl.resolve(false)">Go back</button>
    </div>
  `,
}
