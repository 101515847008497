import React, { Component } from 'react'
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'
import { SliderRail, Handle, Track } from './RangeComponents'
import { getField } from '../../../shared/fields';
import calculateStepAndRoundedMinMax from './filterStep';
import './MetricFilter.scss'
import { debounce } from 'lodash';

const headingForType = type => getField(type)?.heading || type;

const sliderStyle = {
  position: 'relative',
  width: '100%',
}

const calculateStep = (type, availableMin, availableMax) => {
  let step = 1;
  if (getField(type).type === 'percentage_decimal') step = 0.01;
  if (getField(type).type === 'currency') step = 0.01;
  if (getField(type).type === 'count') step = calculateStepAndRoundedMinMax(availableMin, availableMax).step;
  return step;
}

class MetricFilter extends Component {
  
  constructor(props) {
    super(props)
    const { availableMin, availableMax, type } = this.props;
    console.log(availableMin, availableMax);
    this.state = {
      domain: [availableMin, availableMax],
      values: [availableMin, availableMax],
      update: [availableMin, availableMax],
      step: calculateStep(type, availableMin, availableMax)
    }
  }

  onUpdate = update => {
    this.setState({ update })
    this.props.onChange({values: update})
  }

  debouncedOnUpdate = debounce(this.onUpdate, 100);

  onChange = values => {
    // this.setState({ values })
  }

  render() {
    const { domain, values, update, step } = this.state;
    const { type, onDelete } = this.props;
    const field = getField(type);

    let min = update[0];
    let max = update[1];

    if (field.type === 'percentage_decimal') {
      min = parseInt(min * 100);
      max = parseInt(max * 100);
    }

    return (
      <div className='filter'>
        <span className='filter-type'>{headingForType(type)}</span>
        <span className='value value-min'>
          { step === 0.01 && field.type != 'percentage_decimal' ? min.toFixed(2) : min } 
        </span>
        <span className='value value-max'>
          { step === 0.01 && field.type != 'percentage_decimal' ? max.toFixed(2) : max } 
        </span>
        <div className='slider-outer'>
          <Slider
            mode={1}
            step={step}
            domain={domain}
            rootStyle={sliderStyle}
            onUpdate={this.debouncedOnUpdate}
            onChange={this.onChange}
            values={values}
          >
            <Rail>
              {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map(handle => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={domain}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
                </div>
              )}
            </Tracks>
          </Slider>
        </div>
        <button className='dismiss-standard' onClick={() => onDelete(type)}>Remove</button>
      </div>
    )
  }
}

export default MetricFilter