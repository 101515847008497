import React from 'react'
import './PercentLozenge.scss';

export default function PercentLozenge({onClick, data, colorType = 'low-is-irrelevant'}) {

  // Expects data as a percentage decimal (eg, a float between 0 and 1)

  if (data >= 0) {
    data = (data * 100).toFixed(0);
    if (data > 100) data = 100;
    return (
       <div
        className={`percent-lozenge ${colorType} percent-${(data/10).toFixed(0)}`}
        onClick={onClick}
      >{data}</div>
    )
  }

  return <div
    className={`percent-lozenge percent-no-data`}
    onClick={onClick}
  >&ndash;</div>

}