class _Controller {

  constructor($upsells, $limits) {
    this.$upsells = $upsells;
    this.$limits = $limits;
  }

}

_Controller.$inject = ['$upsells', '$limits'];

export const exportButton = {
  bindings: { collection: '<' },
  controller: _Controller,
  template: `
    <button type="button" class='export-to-csv' ng-if='$ctrl.$limits.cant("export")' ng-click="$ctrl.$upsells.new('export')" ng-disabled='!$ctrl.collection.keywords || $ctrl.collection.keywords && ($ctrl.collection.results.getActionable().length < 1)'>Export</button>
    <split-button ng-if='$ctrl.$limits.can("export")' on-click-toggle='$ctrl.collection.updateClipboard' disabled='!$ctrl.collection.keywords || $ctrl.collection.keywords && ($ctrl.collection.getActionable().length < 1)' class='split-button-export'>
      <default-option><button type="button" class='export-to-csv' ng-csv="$ctrl.collection.csv.generate()" lazy-load='true' csv-header='$ctrl.collection.csv.headers' filename="{{ $ctrl.collection.csv.name }}" ng-disabled='!$ctrl.collection.keywords || $ctrl.collection.keywords && ($ctrl.collection.getActionable().length < 1)'>Export</button></default-option>
      <button class='copy-to-clipboard' ngclipboard data-clipboard-text="{{ $ctrl.collection.clipboard }}" ng-disabled='!$ctrl.collection.keywords || $ctrl.collection.keywords && ($ctrl.collection.getActionable().length < 1)'>
        Copy to clipboard
      </button>
    </split-button>
  `
}