import React from 'react'
import './NumberChange.scss';

export default function NumberChange({data}) {

  let klassName = 'number-change';

  if (data > 0) klassName += ' positive';
  if (data < 0) klassName += ' negative';

  const renderData = data => {
    if (data === null) return <>&mdash;</>;
    data = parseInt(data.toString().replace(/\-/g, ''))
    return <span>{data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
  }

  return (<span className={klassName}>
    {data > 0 && <i className="fa-solid fa-caret-up"></i> }
    {data < 0 && <i className="fa-solid fa-caret-down"></i> }
    {renderData(data)}
  </span>)
}
