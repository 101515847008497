import { v4 as uuidv4 } from 'uuid';

export const $searchDebug = function($requestManager, $resultsStore) {

  const searchDebug = {
    events: [],
    activeEvent: null,
    enabled: (typeof __IS_PRODUCTION === 'undefined' || (typeof __IS_PRODUCTION !== 'undefined' && !__IS_PRODUCTION) || (typeof __IS_STAGING != 'undefined' && !!_IS_STAGING))
  };

  searchDebug.log = (type, message, additional) => {

    if (!searchDebug.enabled) return false;

    const uuid = uuidv4();

    const prevEvent = searchDebug.events[0] || {
      requestManagerState: {},
      resultsStoreState: {},
      messageState: {},
    }

    let requestManagerState = JSON.parse(JSON.stringify($requestManager))
    let resultsStoreState = JSON.parse(JSON.stringify($resultsStore))
    let messageState = "n/a";
    if (additional && additional.message) messageState = JSON.parse(JSON.stringify(additional.message));

    if (resultsStoreState.main && resultsStoreState.main.keywords && resultsStoreState.main.keywords.length > 0) resultsStoreState.main.keywords = [`...${resultsStoreState.main.keywords.length} keywords (omitted)...`];
    if (resultsStoreState.datamuse && resultsStoreState.datamuse.keywords && resultsStoreState.datamuse.keywords.length > 0) resultsStoreState.datamuse.keywords = [`...${resultsStoreState.datamuse.keywords.length} keywords (omitted)...`];

    searchDebug.events.unshift({
      uuid,
      type,
      message,
      additional,
      requestManagerState,
      resultsStoreState,
      messageState,
      prevUuid: prevEvent.uuid
    });

  }

  searchDebug.select = event => {
    searchDebug.activeEvent = event;
  }

  return searchDebug;

}

$searchDebug.$inject = ['$requestManager', '$resultsStore'];