import angular from "angular";
import { filter, contains, compact, keys, collect, sortBy } from "underscore";
import { v4 as uuidv4 } from 'uuid';
import { buildRows } from "../shared/export";

export const KeywordsCollection = function($limits, $scrapeProtection, $timeout, cfpLoadingBar) {
  function _KeywordsCollection ({name}) {
    var collection = {
      name: name,
      keywords: null,
      count: null,
      selectAll: false,
      failed: false,
      uuid: uuidv4(),
      scrapeProtection: false,
      maxVolume: 0,
      selectedCount: 0,
      selectedKeywords: [],
      loading: true, // by default this should be true, as we create a empty instance when we search.
      finished: false,
      reactCount: 0,
      isFiltered: false,
      sortField: null,
      sortDescending: null,

      fail: function () {
        cfpLoadingBar.set(1);
        $timeout(() => cfpLoadingBar.complete(), 50);
        this.loading = false;
        this.failed = true;
      },

      addFromCable: function (response) {
        this.loading = false;
        this.failed = false;
        if (response.protection) {
          response.results = $scrapeProtection.decodeAll(response.results);
        }
        this.add(response.results);
        this.possible = response.possible;
        this.updateCountsFromResponse(response);
      },

      updateCountsFromResponse: function(response) {
        if (response.has_more) {
          let count;
          if (!!this.count) {
            count = this.count + response.count;
          } else {
            count = response.count;
          }
          this.count = count;
        } else {
          this.finished = true;
          this.count = this.keywords.length;
        }
      },

      addAppended: function(seed) {
        let keyword = {keyword: seed, appended: true};
        this.keywords = _.union([keyword], this.keywords);
      },

      add: function(keywords) {
        this.keywords = this.keywords || [];
        keywords = keywords || []
        
        angular.forEach(this.getAppended(), keyword => {
          let appendedKeywordResult = _.findWhere(keywords, {keyword: keyword.keyword});
          if (appendedKeywordResult) {
            appendedKeywordResult.appended = true;
            Object.assign(keyword, appendedKeywordResult);
            keywords.splice(keywords.indexOf(appendedKeywordResult),1);
          }
        });

        keywords.forEach(newKeyword => {
          const existingKeyword = _.findWhere(this.keywords, {keyword: newKeyword.keyword});
          if (existingKeyword) {
            const keys = _.union(Object.keys(existingKeyword, Object.keys(newKeyword)));
              const mergedKeyword = keys.reduce((obj, key) => {
                const oldValue = existingKeyword[key];
                const newValue = newKeyword[key];
                const oldValueIsSet = oldValue && oldValue != -1;
                const newValueIsSet = newValue && newValue != -1;
                const value = (newValueIsSet && !oldValueIsSet) ? newValue : oldValue;
                obj[key] = value;
                return obj;
              }, {});
              Object.assign(existingKeyword, mergedKeyword);
          } else {
            this.keywords.push(newKeyword);
          }
        })

      },

      remove: function(toRemoveArray) {
        const _keywords = this.keywords.map(k => k.keyword).sort();
        const removeIdxs = toRemoveArray.sort().map(toRemove => _keywords.indexOf(toRemove))
        let newKeywords = sortBy(Object.assign([], this.keywords), k => k.keyword);
        for (let i = removeIdxs.length - 1; i >= 0; i--) {
          newKeywords.splice(removeIdxs[i], 1);
        }
        angular.copy(newKeywords, this.keywords);
        this.count = Math.max(0, this.count - toRemoveArray.length);
        collection.selectedKeywords = [];
      },

      getAppended: function() {
        return this.keywords.filter(function(keyword) {
          return !!keyword.appended;
        });
      },

      onChangeSelected: (selected) => {
        collection.selectedKeywords = selected
      },

      onUpdateSorting: (sortField, sortDescending) => {
        collection.sortField = sortField;
        collection.sortDescending = sortDescending;
      },

      getActionable: () => {
        return (collection.selectedKeywords.length > 0) ? collection.buildSelectedKeywords() : collection.keywords;
      },

      getSelected: () => {
        return (collection.selectedKeywords.length > 0) ? collection.buildSelectedKeywords() : [];
      },

      buildSelectedKeywords: () => {
        return collection.keywords.filter(keyword => {
          return collection.selectedKeywords.includes(keyword.keyword)
        })
      },

      updateAngularCountAndMeta: ({ count, filtered }) => {
        $timeout(() => {
          collection.reactCount = count;
          collection.isFiltered = filtered;
        })
      },

      clipboard: null,

      buildExportData: () => {

        // Get 'selected' data (or all keywords if nothing selected);
        let data = collection.getActionable();

        // Sort data based on currently selected column, and apply column headings
        data = sortBy(data, collection.sortField);

        data = buildRows(collection.name, data);

        if (collection.sortDescending) {
          data = data.reverse();
        }

        return data;
      },

      updateClipboard: function() {
        var results = collection.buildExportData();

        var headers = collection.csv.headers = keys(results[0]).join(",");
        results = results.map(function(result) {
          var arr = [];
          angular.forEach(result, function(value) {
            arr.push(value);
          });
          return arr.join(",");
        });
        results.unshift(headers);
        collection.clipboard = results.join("\n");
      },
      
      csv: {
        headers: null,
        name: null,
        generate: function (skipHeaders) {

          if ($limits.can('export')) {

            const data = collection.buildExportData();

            const csvName = {
              ranking: 'ranking'
            }[collection.name] || 'keywords';

            collection.csv.name = `wordtracker__${csvName}_${new Date().toISOString().slice(0, 16).replace(/[-T]/g, '_').toLowerCase()}.csv`;

            if (!skipHeaders) {
              collection.csv.headers = keys(data[0]);
            }

            return data;

          }

        }

      }
    };

    return collection;
  }

  return _KeywordsCollection;
};

KeywordsCollection.$inject = ['$limits', '$scrapeProtection', '$timeout', 'cfpLoadingBar'];