export const IncludingFilter = function () {
  function _IncludingFilter(value) {
    return { value: value, type: 'includes' };
  }
  return _IncludingFilter;
};

export const ExcludingFilter = function() {
  function _ExcludingFilter(value) {
    return { value: value, type: 'excludes' };
  }
  return _ExcludingFilter;
};
