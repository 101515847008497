import "./amazonResults.scss";

class _Controller {
  constructor($limits) {
    this.limits = $limits;
  }
}

_Controller.$inject = ['$limits'];

export const amazonResults = {
  bindings: {
    results: '<',
    activeSeed: '<'
  },
  controller: _Controller,
  template: require('./amazonResults.html')
};
