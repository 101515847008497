class _Controller {
  constructor($resultsStore, $searchManager) {
    this.$searchManager = $searchManager;
    this.$resultsStore = $resultsStore;
    this.chartData = null;
  }

  $onChanges(changes) {
    if (!this.$resultsStore.serps || (this.$resultsStore.serps.results && !this.$resultsStore.serps.results.hasOwnProperty(this.keyword))) {
      this.$searchManager.serpAppend(this.keyword);
    }
    if (changes.data && changes.data.currentValue) {
      let data = angular.copy(changes.data.currentValue);
      this.redraw(data);
    }
  }

  redraw(data) {
    if (data.reduce((sum, x) => sum + x.volume, 0) === 0) {
      this.chartData = false
    } else {
      this.chartData = data;
    }
  }

  resolve(value) {
    this.close({value})
  }
}

_Controller.$inject = ['$resultsStore', '$searchManager'];

export const sparklineModal = {
  controller: _Controller,
  bindings: {
    close: '&',
    data: '<',
    keyword: '<'
  },
  template: `
    <div class='modal-header'>
      Keyword overview for "{{ $ctrl.keyword }}"
      <button type='button' class='dismiss-standard' ng-click='$ctrl.resolve(false)'>&times;</button>
    </div>

    <div class='modal-body'>
      <div class='chart-area' ng-if='$ctrl.chartData'>
        <div chart data='$ctrl.chartData'></div>
      </div>

      <serps-results close-fn='$ctrl.resolve.bind($ctrl)()' keyword='$ctrl.keyword'></serps-results>
    </div>
  `
}