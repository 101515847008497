import React, { useEffect, useState } from 'react'
import classNames from 'classnames';

const VALID_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TextField = (props) => {

  let {
    label,
    name,
    type,
    id,
    klassname,
    placeholder,
    error,
    onChange,
    onKeyPress,
    onErrorStateChange,
    validations = [],
    initialValue = ''
  } = props;

  useEffect(() => {
    SetError(error);
  }, [error])

  if (error == 'is already taken') {
    error = <>already exists. <a href='/login'>Log in instead</a></>;
  }

  const [ Error, SetError ] = useState(error);

  const handleBlur = e => {
    const { value } = e.target;
    let error = null;
    validations.forEach(validation => {
      switch (validation.name) {
        case 'required':
          if (!value || value === '') {
            error = validation.message;
          }
          break;
        case 'validEmail':
          if (!VALID_EMAIL_REGEX.test(String(value).toLowerCase())) {
            error = validation.message;
          }
          break;
        case 'minLength':
          if (!value || value.length < validation.minLength) {
            error = validation.message;
          }
          break;
        default:
          break;
      }
    });
    SetError(error);
    if (onErrorStateChange) {
      onErrorStateChange(e, error);
    }
  }

  return (
    <label htmlFor={id} className={classNames({'has_error': Error, [klassname]: true})}>
      {label}&nbsp;
      { Error && <span className='error'>{Error}</span> }
      <input
        id={id}
        type={type}
        name={name}
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={initialValue}
      />
    </label>
  )
}

export default TextField;