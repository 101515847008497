pageChangeHook.$inject = ['$transitions', '$meta', '$lists'];

export function pageChangeHook($transitions, $meta, $lists) {

  $transitions.onSuccess({}, (transition) => {
    // Gets title and section from state and updates meta service on successful state change.

    const to = transition.to();
    $meta.setTitle(to.title);
    $meta.setDescription(to.description);
    $meta.setSection(to.section);

    if (to.parent === 'lists') {
      $lists.initialize(true);
    }

  });

}
