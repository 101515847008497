import { ranking } from "./ranking.component.js";
import { rankingNew } from "./rankingNew.component.js";
import { trackButton } from "./trackButton.component.js";
import { rankingKeywords } from "./ranking_keywords/rankingKeywords.component";

export const RANKING_MODULE = angular.module('rankingModule', []);

const rankingNewState = {
  parent: 'app',
  name: 'rankingNew',
  title: 'Ranking | Wordtracker',
  section: 'ranking',
  url: '/ranking',
  component: 'rankingNew',
  reload: true,
  resolve: {
    cable: [function () {
      return true;
    }]
  }
};

const rankingShowState = {
  parent: 'app',
  name: 'rankingShow',
  title: 'Ranking | Wordtracker',
  section: 'ranking',
  url: '/ranking/:id',
  component: 'ranking',
  reload: true,
  resolve: {
    cable: ['$rankingSearchSettings', '$activeColumns', function ($rankingSearchSettings, $activeColumns) {
      $activeColumns.loadRanking();
      return true;
    }]
  }
};

RANKING_MODULE.config(['$stateRegistryProvider', function($stateRegistry) {
  // $stateRegistry.register(rankingState);
  $stateRegistry.register(rankingNewState);
  $stateRegistry.register(rankingShowState);
}]);


RANKING_MODULE.component('ranking', ranking);
RANKING_MODULE.component('rankingKeywords', rankingKeywords);
RANKING_MODULE.component('rankingNew', rankingNew);
RANKING_MODULE.component('trackButton', trackButton);