const scale = (number, _scale) => {
  for (let i = 0; i <= 11; i++) {
    let lower = _scale[i];
    let upper = _scale[i + 1] || 0;
    if (number >= lower && number < upper) return i;
  }
  return 10;
};

const calculateGrade = {
  "Difficulty": number => Math.round(number / 10),
  "RefDomains": number => scale(number, [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]),
  "ExtBackLinks": number => scale(number, [0, 100, 1000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000, 10000000]),
  "TrustFlow": number => scale(number, [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
  "CitationFlow": number => scale(number, [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
};

export const toGraded = function () {
  return function(number, stat) {
    number = parseInt(number);
    if (calculateGrade.hasOwnProperty(stat)) {
      return calculateGrade[stat](number);
    } else {
      return "nil";
    }
  };
};