const VALID_ENGINES = ["google", "amazon", "youtube", "ebay"];

import "./sales.scss";

import {home} from "./home/home.component";
import {seo} from "./seo/seo.component";
import {sales} from "./sales.component";
import {affiliatePage} from "./statics/affiliatePage.component";

// Top keywords
import {nicheExplorer} from "./statics/explorer_and_reports/niche_explorer/nicheExplorer.component.js";
import {reportGenerator} from "./statics/explorer_and_reports/report_generator/reportGenerator.component.js";
import {top100Show} from "./statics/explorer_and_reports/top_100/top100Show.component";
import {nicheExplorerShow} from "./statics/explorer_and_reports/niche_explorer/nicheExplorerShow.component";
import {topKeywords} from "./statics/explorer_and_reports/top_100/topKeywords.component";
import {keywordIdeas} from "./statics/explorer_and_reports/top_100/keywordIdeas.component";
import {reportCombinerGraphic} from "./statics/report_combiner_graphic/reportCombinerGraphic.component";
import {appWindow} from "./statics/app_window/appWindow.component";

// API stuff
import {apiPage} from "./statics/api/apiPage.component";
import {apiButtons} from "./statics/api/apiButtons.component";
import {planPricing} from "./statics/api/planPricing.component";
import {planLimits} from "./statics/api/planLimits.component";
import {callLimits} from "./statics/api/callLimits.component";

const salesState = {
  name: 'sales',
  component: 'sales',
};

const homeState = {
  name: 'home',
  title: 'Free Keyword Research Tool from Wordtracker',
  component: 'home',
  url: '/',
  parent: 'sales'
};

const seoState = {
  name: 'seo',
  title: 'Rank better with SEO Agency Services from Wordtracker',
  description: `Wordtracker have been providing SEO Services since 1998 and have helped thousands of customers rank better. Find out how we can help you do the same today.`,
  component: 'seo',
  url: '/seo-services',
  parent: 'sales'
};

const affiliateState = {
  name: 'affiliate',
  title: 'Wordtracker Affiliate Program',
  description: `Make money with the Wordtracker Affiliate program with our great comission rates`,
  url: '/affiliate',
  parent: 'sales',
  component: 'affiliatePage'
};

const nicheExplorerState = {
  name: 'explorer',
  title: 'Wordtracker Niche Explorer',
  description: `View the top 100 keywords for Google, Amazon, eBay and YouTube`,
  url: '/niche-explorer',
  parent: 'sales',
  component: 'nicheExplorer',
  resolve: {
    categories: ['$resourceManager', '$limits', '$q', ($resourceManager, $limits, $q) => {
      return $q.all([
        $resourceManager.request('generated_pages', 'query').then(res => res),
        $resourceManager.request('current', 'limits').then(res => res)
      ]).then(([categories, limits]) => {
        $limits.initialize(limits);
        return _.sortBy(categories.map(category => ({value: category.toLowerCase(), name: category})), 'name');
      });      
    }]
  }
};

const nicheExplorerShowState = {
  name: 'nicheExplorerShow',
  title: 'DYNAMIC',
  url: '/niche-explorer/:engine/:country/:keyword',
  parent: 'sales',
  component: 'nicheExplorerShow',
  resolve: {
    valid: ['$stateParams', '$state', '$resourceManager', function ($stateParams, $state, $resourceManager) {
      var country = $stateParams.country;
      var source = $stateParams.engine;
      var keyword = $stateParams.keyword;
      return $resourceManager.request('generated_pages', 'get', {id: keyword, source, country})
        .then(res => { if (!res.category) throw 'Not found'; })
        .catch(() => $state.go("explorer"))
    }]
  }
};

const reportGeneratorState = {
  name: 'reportGenerator',
  title: 'Bespoke keyword reports from Wordtracker',
  description: `Order a bespoke keyword report to discover the top keywords on Google, Amazon, YouTube and eBay.`,
  url: '/reports',
  parent: 'sales',
  component: 'reportGenerator',
  resolve: {
    limits: ['$resourceManager', '$limits', ($resourceManager, $limits) => {
      return $resourceManager.request('current', 'limits').then(limits => $limits.initialize(limits));
    }]
  }
};

const top100EngineState = {
  name: 'engine',
  title: 'DYNAMIC',
  url: '/reports/top-100-keywords/:engine',
  parent: 'sales',
  component: 'top100Show',
  resolve: {
    valid: ['$stateParams', '$state', function ($stateParams, $state) {
      const engine = $stateParams.engine;
      if (!VALID_ENGINES.includes(engine)) return $state.go("engine", {engine: "google"});
    }]
  }
};

const top100EngineCountryState = {
  name: 'engineCountry',
  title: 'DYNAMIC',
  url: '/reports/top-100-keywords/:engine/:country',
  parent: 'sales',
  component: 'top100Show',
  resolve: {
    valid: ['$countryNames', '$stateParams', '$state', function ($countryNames, $stateParams, $state) {
      const countryCode = $stateParams.country;
      const engine = $stateParams.engine;
      if (!VALID_ENGINES.includes(engine)) return $state.go("engine", {engine: "google"});
      if (!($countryNames.hasOwnProperty(countryCode.toUpperCase()) || countryCode.toUpperCase() == "GLOBAL")) return $state.go("engineCountry", {engine: engine, country: "us"});
    }]
  }
};

const apiState = {
  name: 'api',
  title: 'Keyword API from Wordtracker the Keyword Experts',
  description: `The best FREE alternative to the Keyword Planner. Use Wordtracker to reveal 1000s of profitable longtail keywords with up to 10,000 results per search`,
  url: '/api',
  parent: 'sales',
  component: 'apiPage'
};


export const SALES_MODULE = angular.module('sales', []);

SALES_MODULE.component('sales', sales);
SALES_MODULE.component('home', home);
SALES_MODULE.component('seo', seo);
SALES_MODULE.component('affiliatePage', affiliatePage);
SALES_MODULE.component('nicheExplorer', nicheExplorer);
SALES_MODULE.component('nicheExplorerShow', nicheExplorerShow);
SALES_MODULE.component('reportGenerator', reportGenerator);
SALES_MODULE.component('top100Show', top100Show);
SALES_MODULE.component('apiPage', apiPage);
SALES_MODULE.component('apiButtons', apiButtons);
SALES_MODULE.component('planPricing', planPricing);
SALES_MODULE.component('planLimits', planLimits);
SALES_MODULE.component('callLimits', callLimits);
SALES_MODULE.component('topKeywords', topKeywords);
SALES_MODULE.component('keywordIdeas', keywordIdeas);
SALES_MODULE.component('reportCombinerGraphic', reportCombinerGraphic);
SALES_MODULE.component('appWindow', appWindow);

SALES_MODULE.config(['$uiRouterProvider', function($uiRouter) {
  const $stateRegistry = $uiRouter.stateRegistry;
  $stateRegistry.register(salesState);
  $stateRegistry.register(homeState);
  $stateRegistry.register(seoState);
  $stateRegistry.register(affiliateState);
  $stateRegistry.register(nicheExplorerState);
  $stateRegistry.register(nicheExplorerShowState);
  // $stateRegistry.register(reportGeneratorState);
  $stateRegistry.register(top100EngineState);
  $stateRegistry.register(top100EngineCountryState);
  $stateRegistry.register(apiState);
}]);