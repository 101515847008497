import angular from "angular";
import "./modals.scss";

import { enableBodyScroll, disableBodyScroll } from "../../utilities/bodyScrollLock";

const uniqueModals = ['signUpModal']; // Only one of these modals can exist at once

const spinalCase = str => {
  var skewer = function(_m, c1, c2) { return [c1, c2].join('-').toLowerCase(); };
  return str.replace(/([a-z0-9])([A-Z])/g, skewer);
}

const Modal = function($document, $rootScope, $compile, $q, $timeout) {

  let id = 0;

  function _Modal(options) {

    this.close = (value) => {
      api.destroy(this);
      this.modalWindowElScope.show = false;
      $timeout(() => {
        enableBodyScroll(document.getElementById(`modal-${this.id}`));
        this.modalWindowElScope.$destroy();
        this.modalWindowEl.remove();
        if (value === false) return this.promise.reject(`Modal called 'close' function with a value of false`);
        this.promise.resolve(value);
      }, 150);
    }

    this.onMouseDown = (e) => {
      this.mousePosition.x = e.screenX;
      this.mousePosition.y = e.screenY;
    }

    this.onMouseDown = (e) => {
      this.mousePosition.x = e.screenX;
      this.mousePosition.y = e.screenY;
    }
  
    this.dismiss = (e) => {
      if (this.mousePosition.x === e.screenX && this.mousePosition.y === e.screenY && this.dismissable && e.target.className === "modal") {
        this.close(false);
      }
    }

    this.id = options.id;
    this.type = options.component;
    if (uniqueModals.includes(this.type)) {
      this.unique = true;
    }
    this.promise = $q.defer();
    this.modalWindowElScope = $rootScope.$new();
    this.modalWindowElScope.show = false;
    this.modalWindowElScope.close = this.close.bind(this)
    this.modalWindowElScope.dismiss = this.dismiss.bind(this)
    this.modalWindowElScope.onMouseDown = this.onMouseDown.bind(this)

    this.mousePosition = {
      x: 0,
      y: 0
    };

    const { bindings, component, dismissable } = options

    this.dismissable = dismissable

    let bindingAttributes = '';

    if (bindings) {
      this.modalWindowElScope.bindings = bindings; 
      bindingAttributes += Object.keys(bindings || {}).map(key => {
        return spinalCase(key) + '="bindings.' + key + '"';
      }).join(' ');
    }

    this.modalWindowEl = $compile(`
      <div class='modal-container ${spinalCase(component)}'>
        <div class='modal-backdrop fade-in' ng-if='show' style='z-index: calc(2000 + ${this.id});'></div>
        <div class='modal' id='modal-${this.id}' ng-mousedown='onMouseDown($event)' style='z-index: calc(2001 + ${this.id});' ng-click='dismiss($event)'>
          <div class='modal-content fade-down' ng-if='show'>
            <${spinalCase(component)} resolve="resolve" dismiss="dismiss" close="close(value)" ${bindingAttributes}></${spinalCase(component)}>
          </div>
        </div>
      </div>
    `)(this.modalWindowElScope);

    angular.element($document[0].body).append(this.modalWindowEl)

    $timeout(() => {
      disableBodyScroll(document.getElementById(`modal-${this.id}`));
      this.modalWindowElScope.show = true
    }, 75);

    return this;

  }

  const api = {
    modals: [],
    new: (options) => {
      options.id = id++;
      const modalsOfSameType = api.modals.filter(_modal => _modal.type === options.component);
      if (modalsOfSameType[0] && modalsOfSameType[0].unique) return false;
      let _modal = new _Modal(options);
      api.modals.push(_modal);
      return _modal.promise.promise;
    },
    destroy: (modal) => {
      api.modals = api.modals.filter(_modal => _modal.id != modal.id)
    },
    closeAll: () => {
      api.modals.forEach(modal => modal.close(false))
    }
  };

  return api;

}

Modal.$inject = ['$document', '$rootScope', '$compile', '$q', '$timeout'];

const modalWindow = function() {
  return {
    template:'<div class="modal-window" ng-transclude></div>',
    transclude: true,
    link: function(scope, element){
      element.on('click', function(evt) {
        if (evt.target === evt.currentTarget) {
          this.close()
        }
     })
    }
  }
}

export { modalWindow, Modal };