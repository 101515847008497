import { asyncButton } from "./async_button/asyncButton.component";
import { fullPage } from "./full_page/fullPage.component";
import { textField } from "./text_field/textField.component";
import { textareaField } from "./text_field/textareaField.component";
import { stripeCardField } from "./stripe_card_field/stripeCardField.component";
import { Modal, modalWindow } from "./modals/modal.factory.js";
import { confirmModal } from "./modals/modals/confirmModal.component";
import { infoModal } from "./modals/modals/infoModal.component";
import { upsellModal } from "./modals/modals/upsellModal.component";
import { searchBulkModal } from "./modals/modals/searchBulkModal.component";
import { gscModal } from "./modals/modals/gscModal.component";
import { stringInputModal } from "./modals/modals/stringInputModal.component";
import { textInputModal } from "./modals/modals/textInputModal.component";
import { createListModal } from "./modals/modals/createListModal.component";
import { appendToListModal } from "./modals/modals/appendToListModal.component";
import { sparklineModal } from "./modals/modals/sparklineModal.component";
import { reportPaymentModal } from "./modals/modals/reportPaymentModal.component";
import { suspendedAccountModal } from "./modals/modals/suspendedAccountModal.component";

export const ELEMENTS_MODULE = angular.module('elementsModule', []);

ELEMENTS_MODULE.component('asyncButton', asyncButton);
ELEMENTS_MODULE.component('stripeCardField', stripeCardField);
ELEMENTS_MODULE.component('confirmModal', confirmModal);
ELEMENTS_MODULE.component('infoModal', infoModal);
ELEMENTS_MODULE.component('searchBulkModal', searchBulkModal);
ELEMENTS_MODULE.component('upsellModal', upsellModal);
ELEMENTS_MODULE.component('gscModal', gscModal);
ELEMENTS_MODULE.component('stringInputModal', stringInputModal);
ELEMENTS_MODULE.component('textInputModal', textInputModal);
ELEMENTS_MODULE.component('createListModal', createListModal);
ELEMENTS_MODULE.component('appendToListModal', appendToListModal);
ELEMENTS_MODULE.component('suspendedAccountModal', suspendedAccountModal);
ELEMENTS_MODULE.component('sparklineModal', sparklineModal);
ELEMENTS_MODULE.component('reportPaymentModal', reportPaymentModal);
ELEMENTS_MODULE.component('textField', textField);
ELEMENTS_MODULE.component('textareaField', textareaField);
ELEMENTS_MODULE.component('fullPage', fullPage);
ELEMENTS_MODULE.directive('modalWindow', modalWindow);
ELEMENTS_MODULE.factory('Modal', Modal);