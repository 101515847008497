import "../search/central_area/centralArea.scss";

class _Controller {

  constructor($lists, $listNavigation, $stateParams, $location, $limits, $keywordsCollection, $notifier, $activeColumns, $resultsStore, listSourcesFilter) {
    this.listNav = $listNavigation;
    this.list = null;
    this.$location = $location;
    this.limits = $limits;
    this.source = 'mixed';
    this.$keywordsCollection = $keywordsCollection;
    const store = $keywordsCollection({name: 'currentList'});
    $resultsStore.currentList = store;
    this.$resultsStore = $resultsStore;
    this.$notifier = $notifier;
    this.$lists = $lists;
    this.lists = $lists.lists;
    this.projects = $lists.projects;
    this.$activeColumns = $activeColumns;
    this.prevPage = -1;

    $lists.initialize().then(() => {
      let list = _.findWhere(this.lists, {id: parseInt($stateParams.id)});
      this.list = (list) ? list : false;
      if (this.list) {
        this.$activeColumns.loadList();
        this.setParentProject();
        this.getKeywords({page: 1});
        this.$resultsStore.currentList.source = listSourcesFilter(this.list.sources);
      }
    });
  }

  setParentProject() {
    if (this.list.project_id) {
      this.parentProject = _.findWhere(this.projects, {id: this.list.project_id});
    }
  }

  goToParentProject() {
    this.$location.path('/lists/folder/' + this.parentProject.id);
  }

  getKeywords(params) {
    let _params = {
      id: this.list.id,
      page: params.page
    };

    this.$lists.getKeywords(_params).then((response) => {
      this.$resultsStore.currentList.loading = false;
      if (response.keywords.length > 0) {
        if (this.prevPage < response.page) {
          this.prevPage = response.page;
          this.$resultsStore.currentList.add(response.keywords);
          this.$resultsStore.currentList.updateCountsFromResponse(response);
          this.getKeywords({page: response.page + 1});
        }
      } else {
        this.prevPage = response.page;
        this.$resultsStore.currentList.add([]);
      }
    });
  }

  newExportUpsell() {
    this.$notifier.red('Export unavailable during free trial.');
  }

  deleteSelected() {
    let selectedKeywords = this.$resultsStore.currentList.getActionable();
    if (selectedKeywords.length === 0) return false;

    if (confirm('Remove ' + selectedKeywords.length + ' keywords from this list?')) {
      this.$lists.deleteKeywords(this.list, selectedKeywords).then(() => {
        var newKeywords = _.difference(this.$resultsStore.currentList.keywords, selectedKeywords);
        this.$resultsStore.currentList.keywords = newKeywords;
        this.$resultsStore.currentList.selectedKeywords = [];
        this.$resultsStore.currentList.count = newKeywords.length;
      });
    }
  }

}

_Controller.$inject = ['$lists', '$listNavigation', '$stateParams', '$location', '$limits', '$keywordsCollection', '$notifier', '$activeColumns', '$resultsStore', 'listSourcesFilter'];

export const listShow = {
  controller: _Controller,
  template: require('./listShow.html')
};
