import React, { useEffect, useState } from "react";
import SignUp from "./signup/SignUp";
import { subscribe, unsubscribe } from "../shared/events";

export default function Global() {

  const [User, SetUser] = useState(null)
  const [CountryCode, SetCountryCode] = useState(null)
  const stripe = Stripe(__STRIPE_PUBLIC_KEY);

  useEffect(() => {

    subscribe("userBuilt", (e) => {
      SetUser(e.detail.user);
    });

    fetch("https://ipapi.co/json/", {
      method: 'GET',
    }).then(response => response.json()).then(response => {
      SetCountryCode(response.country_code);
    });

    return () => {
      unsubscribe("userBuilt", () => SetUser(null));
    }

  }, []);

  return (
    <>
      {User && <SignUp user={User} stripe={stripe} countryCode={CountryCode} /> }
    </>
  )
}