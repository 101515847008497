import './competitors.scss';

import generateRandomGibberish from '../../shared/generateGibberish';

class _Controller {
  constructor($resultsStore, $domainSearchSettings, UserService, $upsells) {
    this.results = $resultsStore;
    this.$domainSearchSettings = $domainSearchSettings;
    this.$upsells = $upsells;
    this.paddedResults = [];
    this.isFreeUser = UserService.user.isFreeUser;
  }

  $onInit() {
    this.padResults();
  }

  padResults() {
    if (this.isFreeUser) {
      for (let i = 0; i < 15; i++) {
        const paddedPhrase = generateRandomGibberish(1, 12, 25);
        this.paddedResults.push({competitor: paddedPhrase, number: Math.floor(Math.random() * 8000) + 60});
      }
    }
  }
}

_Controller.$inject = ['$resultsStore', '$domainSearchSettings', 'UserService', '$upsells'];

export const competitors = {
  bindings: {
    onClickResultFn: '&'
  },
  controller: _Controller,
  template: require('./competitors.html')
}
