export const $seoMetaLengths = function() {

  const store = {
    title: [
      { min: 0,  max: 40,    title: 'Too short',  tip: 'Too short. Potentially missing keywords.'},
      { min: 41, max: 65,    title: 'Good',       tip: 'The title length is great.'},
      { min: 66, max: 70,    title: 'A bit long', tip: 'Title is a bit long. There\'s a chance Google will cut off the end...'},
      { min: 71, max: 99999, title: 'Too long',   tip: 'Too long. Keywords at the end are getting cut off.'},
    ],
    description: [
      { min: 0,   max: 60,    title: 'Too short', tip: 'Too short. Longer descriptions are more likely to be used in SERPs.'},
      { min: 61,  max: 160,   title: 'Good',      tip: 'Length is good. A unique description with a call to action works best.'},
      { min: 161, max: 99999, title: 'Too long',  tip: 'The description should be under 160 characters'},
    ]
  };

  let api = {
    getFor: function(type, string) {
      return _.find(store[type], function(entry) {
        return (string.length >= entry.min && string.length <= entry.max);
      });
    },
    getForTitle: function(string) {
      return this.getFor('title', string);
    },
    getForDescription: function(string) {
      return this.getFor('description', string);
    }
  };

  return api;
};

// $seoMetaLengths.$inject = [];
