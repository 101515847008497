export const SerpAppend = function ($searchAbstract, $requestManager, $searchSettings, $resultsStore) {
  function _SerpAppend (seed, country) {

    var payload = function () {
      var _payload = {};
      _.extend(_payload, $searchSettings.searchParams);
      _payload.seeds = seed;
      return _payload;
    };

    let search = $searchAbstract('serp', 'google', 'serps', payload, null, false);

    search.store = function () {
      let _seed = payload().seeds
      return {
        results: $resultsStore.serps && $resultsStore.serps.country === country ? $resultsStore.serps.results || {} : {},
        country,
        fail: function () {},
        addFromCable: function (response) {
          this.results[_seed] = response;
        }
      };
    };

    return search;
  }

  return _SerpAppend;
};

SerpAppend.$inject = ['$searchAbstract', '$requestManager', '$searchSettings', '$resultsStore'];
