import React, { useState } from 'react'
import { getField } from '../../../../shared/fields';
import classNames from 'classnames';
import LoadingMessage from '../LoadingMessage';
import SimpleSpinner from '../SimpleSpinner';
import { Tooltip } from 'react-tooltip';

const scale = (number, _scale) => {
  for (let i = 0; i <= 11; i++) {
    let lower = _scale[i];
    let upper = _scale[i + 1] || 0;
    if (number >= lower && number < upper) return i;
  }
  return 10;
};

const calculateGrade = {
  "Difficulty": number => Math.round(number / 10),
  "RefDomains": number => scale(number, [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]),
  "ExtBackLinks": number => scale(number, [0, 100, 1000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000, 10000000]),
  "TrustFlow": number => scale(number, [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
  "CitationFlow": number => scale(number, [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
};

const toGraded = (number, stat) => {
  number = parseInt(number);
  if (calculateGrade.hasOwnProperty(stat)) {
    return calculateGrade[stat](number);
  } else {
    return "nil";
  }
}

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const bigNumber = (text) => {
  var number = parseInt(text);
  if (number > 0) {
    var abs = Math.abs(number);
    if (abs >= Math.pow(10, 12)) {
      number = (number / Math.pow(10, 12)).toFixed(0) + "t";
    } else if (abs < Math.pow(10, 12) && abs >= Math.pow(10, 9)) {
      number = (number / Math.pow(10, 9)).toFixed(0) + "b";
    } else if (abs < Math.pow(10, 9) && abs >= Math.pow(10, 6)) {
      number = (number / Math.pow(10, 6)).toFixed(0) + "m";
    } else if (abs < Math.pow(10, 6) && abs >= Math.pow(10, 4)) {
      number = (number / Math.pow(10, 3)).toFixed(1) + "k";
    } else if (abs < Math.pow(10, 4) && abs >= Math.pow(10, 3)) {
      number = numberWithCommas(number);
    }
    return number;
  } else {
    return 0;
  }
};

export default function SerpPreview({serpsResults, keyword}) {
  
  const [SerExpanded, SetSerExpanded] = useState(-1);

  const loadingMessages = [
    'Fetching SERP preview...',
    'Calculating metrics...',
    'Finalizing results...'
  ]

  const expandSer = (index) => {
    SetSerExpanded((SerExpanded === index) ? null : index);
  }

  return (
    <div id='serp-results'>
      <div className='serp-list-holder'>
        { !!serpsResults[keyword] &&
          <ul id='serp-list'>
            <Tooltip className="react-tooltip-header-callout" id={getField("RefDomains").key} />
            <Tooltip className="react-tooltip-header-callout" id={getField("ExtBackLinks").key} />
            <Tooltip className="react-tooltip-header-callout" id={getField("CitationFlow").key} />
            <Tooltip className="react-tooltip-header-callout" id={getField("TrustFlow").key} />
            <Tooltip className="react-tooltip-header-callout" id={getField("Difficulty").key} />
            <li className='table-header'>
              <span className='label url-label'>URL</span>
              <span className='label refdomains-label'>
                RDs
                <div data-tooltip-id={getField("RefDomains").key} data-tooltip-html={getField("RefDomains").description} className='info-icon'>
                  <span className='metric-tip'></span>
                </div>
              </span>
              <span className='label extbacklinks-label'>
                BLs
                <div data-tooltip-id={getField("ExtBackLinks").key} data-tooltip-html={getField("ExtBackLinks").description} className='info-icon'>
                  <span className='metric-tip'></span>
                </div>
              </span>
              <span className='label citationflow-label'>
                CF
                <div data-tooltip-id={getField("CitationFlow").key} data-tooltip-html={getField("CitationFlow").description} className='info-icon'>
                  <span className='metric-tip'></span>
                </div>
              </span>
              <span className='label trustflow-label'>
                TF
                <div data-tooltip-id={getField("TrustFlow").key} data-tooltip-html={getField("TrustFlow").description} className='info-icon'>
                  <span className='metric-tip'></span>
                </div>
              </span>
              <span className='label difficulty-label'>
                Diff.
                <div data-tooltip-id={getField("Difficulty").key} data-tooltip-html={getField("Difficulty").description} className='info-icon'>
                  <span className='metric-tip'></span>
                </div>
              </span>
            </li>
            { serpsResults[keyword].slice(0,10).map((ser, idx) => (
              <li key={idx} className={classNames({expanded: SerExpanded === idx})}>
                <div className='ser-summary' onClick={() => expandSer(idx)}>
                  <div className='url'>
                    <span>{ser.Item.replace(/https?\:\/\//,'').replace(/\/$/,'')}</span>
                  </div>
                  <div className='refdomains stat'>{ bigNumber(ser["RefDomains"]) }</div>
                  <div className='extbacklinks stat'>{ bigNumber(ser["ExtBackLinks"]) }</div>
                  <div className='citationflow stat'>{ ser["CitationFlow"] }</div>
                  <div className='trustflow stat'>{ ser["TrustFlow"] }</div>
                  <div className={`difficulty stat graded-${ toGraded(ser["Difficulty"], "Difficulty") }`}>{ ser["Difficulty"] }</div>
                </div>
                <div className='ser-metadata'>
                  <p className='title'><a href={ser.Item} target='_blank'><strong>{ ser.title }</strong></a></p>
                  <p className='meta-url'>{ ser.Item }</p>
                  <p className='snippet'>{ ser.snippet }</p>
                </div>
              </li>
            ))}
          </ul>
        }
        { (!serpsResults || !serpsResults[keyword]) && (
          <table className='serp-list'>
            <tbody>
              <tr><td colSpan='6' className='dimmed'>
                <div>
                  <SimpleSpinner/>
                  <div className='loading-text' style={{marginBottom: '0px'}}><LoadingMessage texts={loadingMessages} /></div>
                </div>
              </td></tr>
            </tbody>
          </table>
        )}
        {/* <table ng-if='$ctrl.results.failed'className='serp-list'>
          <tbody>
            <tr><td colspan='6' className='dimmed'>
              <div className='loading-text'>Sorry there has been an error. Please try again.</div>
            </td></tr>
          </tbody>
        </table>
        <table ng-if='!$ctrl.results.serps.loading && serpsResults.length == 0 && !$ctrl.results.serps.failed' className='serp-list'>
          <tbody>
            <tr><td colspan='6' className='dimmed'>
              <div className='loading-text'>No search results for this term</div>
            </td></tr>
          </tbody>
        </table> */}
      </div>
    </div>

  )
}
