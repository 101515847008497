export const $textFilters = () => {

  var service = {
    active: [],
  };

  service.create = function(type, value) {
    var filter = {type, value};
    service.destroyWhereValue(value);
    service.active.push(filter);
  };
  
  service.destroyWhereValue = (value) => {
    const filter = _.findWhere(service.active, {value: value});
    if (filter) service.destroy(filter);
  };

  service.destroy = filter => {
    service.active.splice(service.active.indexOf(filter), 1);
  };

  return service;
};

// $textFilters.$inject = [];
