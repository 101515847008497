import { random } from 'underscore';

export const $tips = function() {

  let tips = {
    store: [
      "Enter any idea or keyword to begin..."
      // "Get a feel for an entire niche by entering more than one search term (comma-separated).",
      // "Want more product or video-based results? Check out the YouTube and Amazon suggestions.",
      // "Click a result from the 'Sugge' section to quickly build up a picture of a larger niche.",
      // // "Use the minus sign in searches to exclude terms from the suggestions, eg, 'eggs -bacon'"
    ],
    pick: () => {
      return _.sample(this.store);
    }
  };

  return tips;
};
