import "./renameSelect.scss";

_.isLink = function(element) {
  var $element = $(element);
  if (!$element.attr('href')) return false;
  return (!!$element.attr('href').match(/\/\#\//));
};

export const renameSelect = function ($document, $timeout, $rootScope) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      extraClass: '@',
      disabled: '=',
      label: '<',
      name: '@',
      onCompleteEdit: '&'
    },
    template: `
      <div class='rename-select' ng-class='{open: isOpen, editing: isEditing}'>
        <button ng-click='edit()' class='rename-button' ng-disabled='disabled'>Rename</button>
        <button ng-click='toggle()' ng-disabled='disabled' class='main-button'>{{ label }}</button>
        <input ng-show='isEditing' ng-blur='finishEdit()' id='rename-field' ng-model='label'></input>
        <ul ng-show='isOpen' class='fade-in'>
          <div class='scroller' ng-transclude></div>
        </ul>
      </div>`,

    link: function (scope, element) {

      scope.isOpen = false;
      scope.isEditing = false;

      scope.edit = () => {
        scope._cachedValue = angular.copy(scope.label);
        scope.close();
        scope.isEditing = true;
        $timeout(() => $('#rename-field').select());
      };

      scope.finishEdit = () => {
        scope.isEditing = false;
        if (!scope.label) {
          scope.label = scope._cachedValue;
        } else {
          scope.onCompleteEdit({value: scope.label});
        }
      };

      scope.toggle = function () {
        (scope.isOpen) ? scope.close() : scope.open();
      };

      scope.open = function () {
        scope.isOpen = true;
        $timeout(() => {
          $document.one("click", scope.close);
        }, 10);
      };

      scope.close = function () {
        $timeout(() => scope.isOpen = false);
      };

    }
  };
};

renameSelect.$inject = ['$document', '$timeout', '$rootScope'];
