export const SearchMain = function ($searchAbstract, $keywordsCollection) {
  function _SearchMain (searchSettings) {
    var dataSource = searchSettings.source;

    var payload = function () {
      var _payload = {};
      _.extend(_payload, searchSettings.searchParams);
      _payload.source = searchSettings.source;
      _payload.seeds = searchSettings.seeds.api;
      return _payload;
    };

    var search = $searchAbstract('search', dataSource, 'main', payload, searchSettings);

    search.store = function () {
      let collection = $keywordsCollection({name: 'main'});
      if (searchSettings) {
        collection.activeSeed = searchSettings.activeSeed;
        collection.source = searchSettings.source;
        collection.country = searchSettings.searchParams.country;
      }
      return collection;
    };

    return search;
  }
  return _SearchMain;
};

SearchMain.$inject = ['$searchAbstract', '$keywordsCollection'];
