export const SearchAppend = function ($searchAbstract) {
  function _SearchAppend (searchSettings, seed, resultsStoreKey) {

    var payload = function () {
      var _payload = {};
      _.extend(_payload, searchSettings.searchParams);
      _payload.seeds = seed;
      _payload.method = 'exact';
      _payload.plurals = false;
      return _payload;
    };

    let search = $searchAbstract('search', searchSettings.source, resultsStoreKey, payload, null, false);

    return search;
  }

  return _SearchAppend;
};

SearchAppend.$inject = ['$searchAbstract'];
