export const $scrapeProtection = function() {
  var parse = (function(d){return function(a){return a(a)}(function(a){return d(function(){return a(a).apply(null,arguments)})})})(function(d){return function(a,b){b=b||[];var c=a.split(""),e=c.shift(),f=c.shift().charCodeAt()-e.charCodeAt(), g=c.join(""); b[f]=e;return 0<c.length?d(g,b):b.join("");}});

  var protector = {
    decodeAll: function (array) {
      angular.forEach(array, function (k) {
        k.keyword = protector.decode(k.keyword);
      });
      return array;
    },
    decode: function (string) {
      $.base64.raw = false;
      const decoded = $.base64.decode($.trim(string));
      return parse(decoded);
    }
  };

  return protector;
};
