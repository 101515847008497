/**
 * This file imports the third party library dependencies, then creates the angular module "demo"
 * and exports it.
 */
// External dependencies
import * as angular from "angular";
import uiRouter from "@uirouter/angularjs";
import { StickyStatesPlugin } from '@uirouter/sticky-states';
import { DSRPlugin } from '@uirouter/dsr';
import * as $resource from 'angular-resource';
import * as ngCookies from 'angular-cookies';
import * as ngSanitize from 'angular-sanitize';
import * as angularMoment from "angular-moment";
import * as dirPagination from "angular-utils-pagination"

import 'codemirror/lib/codemirror.css';
import './uiCodemirror.module.js';
import 'angular-bindonce/bindonce.min.js';

require('./jqueryBase64');
require('angular-animate/angular-animate.min');
require('angular-spinkit');
// require('ng-country-select/dist/ng-country-select');
require('angular-rangeslider');
require('jquery-sparkline');
require('ng-csv');
require('clipboard');
require('ngclipboard');
require('ng-draggable/ngDraggable');
require('angular-multi-check');
require('angular-off-click');
require('angular-loading-bar');

import { MAIN_MODULE } from '../main/main.module';
import { SALES_MODULE } from '../sales/sales.module';
import { GLOBAL_MODULE } from './global.module';
import { FEATURES_MODULE } from '../features/features.module';
import { FILTERS_MODULE } from './filters/filters.module';
import { SEARCH_MODULE } from '../search/search.module';
import { LISTS_MODULE } from '../lists/lists.module';
import { DOMAIN_MODULE } from '../domain/domain.module';
import { RANKING_MODULE } from '../ranking/ranking.module';
import { ELEMENTS_MODULE } from "./elements/elements.module.js";

_.capitalize = function (string) {
  return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
};

export const ngmodule = angular.module("salesApp", [
  uiRouter,
  $resource,
  ngCookies,
  ngSanitize,
  angularMoment,
  dirPagination,
  'pasvaz.bindonce',
  'angular-spinkit',
  'ui-rangeSlider',
  'ngCsv',
  'ngclipboard',
  'angular-multi-check',
  'ngDraggable',
  'offClick',
  'cfp.loadingBar',
  // 'countrySelect',
  'ngAnimate',
  'ui.codemirror',
  MAIN_MODULE.name,
  SALES_MODULE.name,
  GLOBAL_MODULE.name,
  FEATURES_MODULE.name,
  SEARCH_MODULE.name,
  FILTERS_MODULE.name,
  LISTS_MODULE.name,
  DOMAIN_MODULE.name,
  RANKING_MODULE.name,
  ELEMENTS_MODULE.name

]);

ngmodule.config(['$uiRouterProvider', '$locationProvider', 'cfpLoadingBarProvider', '$provide', ($uiRouter, $locationProvider, cfpLoadingBarProvider, $provide) => {

  cfpLoadingBarProvider.parentSelector = '.has-loading-bar';

  $locationProvider.hashPrefix('');
  $uiRouter.plugin(StickyStatesPlugin);
  $uiRouter.plugin(DSRPlugin);

  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });

  window.$uiRouter = $uiRouter;

  $provide.decorator('$location', ["$delegate", function($delegate) {

    $delegate.addParams = object => {
      let params = $delegate.search();
      angular.forEach(object, (value, key) => {
        params[key] = value;
      });
      return $delegate.search(params);
    };

    $delegate.removeParams = keys => {
      keys = (Array.isArray(keys)) ? keys : [keys];
      let params = $delegate.search();
      keys.forEach(key => delete params[key]);
      return $delegate.search(params);
    };

    return $delegate;
  }]);

}])