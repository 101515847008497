export const PageMetrics = function($searchAbstract, $timeout) {
  function _PageMetrics (searchSettings) {
    var payload = function () {
      return searchSettings.searchParams;
    };

    var search = $searchAbstract('page_metrics', 'page_metrics', 'page_metrics', payload);

    search.store = function () {
      return {
        loading: true,
        failed: false,
        metrics: null,
        fail: function(response) {
          this.loading = false;
        },
        addFromCable: function (response) {
          this.loading = false;
          this.result = response;
        }
      };
    };

    return search;
  }

  return _PageMetrics;
};

PageMetrics.$inject = ['$searchAbstract', '$timeout'];
