import "./exportResultsButton.scss";

class _Controller {
  constructor($limits) {
    this.limits = $limits;
  }
}

_Controller.$inject = ['$limits'];

export const exportResultsButton = {
  bindings: {
    results: '<',
    newUpsellFn: '<'
  },
  controller: _Controller,
  template: require('./exportResultsButton.html')
};
