import * as Fingerprint2 from 'fingerprintjs2';

export const $f = function($q) {
  return {
    gen: function () {
      var options = {excludeLanguage: true,
        excludeUserAgent: true,
        excludeSessionStorage: true,
        excludeIndexedDB: true,
        excludeWebGL: true,
        excludeOpenDatabase: true,
        excludeDoNotTrack: true,
        excludePlugins: true,
        excludeIEPlugins: true,
        excludeAdBlock: true,
        excludeJsFonts: true,
        excludeFlashFonts: false};
      return $q(resolve => {
        new Fingerprint2(options).get(function(result){
          resolve(result)
        });
      })
    }
  };
};

$f.$inject = ['$q'];
