import React, { useEffect, useState } from 'react'
import classNames from 'classnames';

const Checkbox = (props) => {

  const {
    id,
    label,
    name,
    klassname,
    error,
    onChange,
    initialValue = ''
  } = props;

  useEffect(() => {
    SetError(error);
  }, [error])

  const [ Error, SetError ] = useState(error);

  return (
    <label htmlFor={id} className={classNames({'has_error': Error, [klassname]: true})}>
      <input
        type='checkbox'
        id={id}
        name={name}
        onChange={onChange}
        checked={initialValue}
      />
      {label}&nbsp;
      { Error && <span className='error'>{Error}</span> }
    </label>
  )
}

export default Checkbox;