import "./metricColumnSelectorAndPresets.scss"

class Controller {
  constructor(UserService, $activeColumns, Modal, $searchSettings, $upsells) {
    this.user = UserService.user;
    this.$activeColumns = $activeColumns;
    this.searchSettings = $searchSettings;
    this.Modal = Modal;
    this.activeCols = $activeColumns.active;
    this.$upsells = $upsells;
  }

  promptGSC() {
    if (this.user.isFreeUser) {
      this.$upsells.new('gsc_data')
    } else {
      this.Modal.new({
        component: 'gscModal',
        dismissable: true,
        bindings: {
          title: "Connect to Google",
          description: "You can get <strong>Google Search Console (GSC) data</strong> right in your Wordtracker search results.<br><br>Simply click below to hook your account to GSC!",
          action_uri: __ZUUL_URL + '/account/integrations',
          action_text: 'Connect'
        },
      })
    }
  }

}

Controller.$inject = ['UserService', '$activeColumns', 'Modal', '$searchSettings', '$upsells'];

export const metricColumnSelectorAndPresets = {
  controller: Controller,
  bindings: {
    location: '@'
  },
  template: require('./metricColumnSelectorAndPresets.html')
}