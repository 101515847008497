class Controller {
  constructor($upsells) {
    this.$upsells = $upsells;
    this.upsell = $upsells.active;
  }

  resolve(value) {
    this.close({value});
  }

  action() {
    // Needs a refactor, but:
    if (this.upsell.actionFn === 'confirmEndTrial') {
      // Close the modal after the async endTrial action has completed
      this.$upsells.actionFns.confirmEndTrial().then(res => {
        this.resolve(true)
      }).catch(res => {
        this.resolve(false)
      })
    } else {
      // Otherwise close it and then run the action function
      this.resolve(this.$upsells.actionFns[this.upsell.actionFn])
    }
  }

  actionSecondary() {
    this.resolve(this.$upsells.actionFns[this.upsell.actionFnSecondary])
  }

}

Controller.$inject = ['$upsells'];

export const upsellModal = {
  controller: Controller,
  bindings: {
    close: '&',
  },
  template: `
    <div class='padlock-motif'></div>
    <button type='button' class='dismiss-standard' ng-click='$ctrl.resolve(false)'>&times;</button>
    <h4 ng-bind-html='$ctrl.upsell.title'></h4>
    <div ng-bind-html='$ctrl.upsell.html'></div>
    <button class='upsell-action-button' ng-if='$ctrl.upsell.actionFn' ng-click='$ctrl.action()' ng-disabled='$ctrl.upsell.processing'>{{ ($ctrl.upsell.processing) ? 'Processing...' : $ctrl.upsell.actionText }}</button>
    <button class='upsell-secondary-action-button' ng-if='$ctrl.upsell.actionFnSecondary' ng-click='$ctrl.actionSecondary()'>{{$ctrl.upsell.actionTextSecondary}}</button>
  `,
}

