const errorMessages = {
  "required": "can't be blank",
  "validEmail": "needs double-checking",
}

const validators = {
  required: input => (!!input && input != ""),
  validEmail: input => {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(input).toLowerCase());
  }
}

export const Form = function() {
  function _Form (fields) {

    var form = {
      errors: {},
      fields
    };

    form.addError = (name, message) => {
      if (!form.errors.hasOwnProperty(name)) form.errors[name] = message;
    }

    form.validate = (data) => {
      form.errors = {}
      for (const [field, validations] of Object.entries(form.fields)) {
        validations.forEach(validation => {
          const input = data[field]; 
          if (!validators[validation](input)) {
            form.addError(field, errorMessages[validation])
          }
        })
      }
      return (Object.keys(form.errors).length === 0)
    }

    return form;
  }

  return _Form;

}

// Form.$inject = []