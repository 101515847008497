export const $rankingSearchSettings = function($cableConnection) {
  var settings = {
    searchParams: {
      token: null,
      site: null,
      keywords: [],
      country: null,
      session_uid: $cableConnection.sessionUid
    },
    changed: false,
    source: 'ranking',
    setChanged: function(val) {
      this.changed = val;
    }
  };

  return settings;
};

$rankingSearchSettings.$inject = ['$cableConnection'];
