import React, { Component} from 'react'
import FilterButtons from './FilterButtons';
import Filters from './Filters';
import { getFilteredKeywords } from './filterLogic';
import { getField } from '../../../shared/fields';
import calculateStepAndRoundedMinMax from './filterStep';

const seoViewKey = 'competitioniaatvolume'
const ppcViewKey = 'cmpcpcvolume'
const gscViewKey = 'ctrimpressionsposition'

class FiltersProvider extends Component {

  constructor(props) {
    super(props)
    this.addFilter = this.addFilter.bind(this)
    this.changeFilter = this.changeFilter.bind(this)
    this.deleteFilter = this.deleteFilter.bind(this)
    this.state = {
      fields: [],
      filtered: [],
      filters: {},
      textFilters: [],
    }
  }

  componentDidUpdate(prevProps) {
    this.autoAddGSCFilter();
    this.updateTextFiltersIfChanged();
    this.updateKeywordsIfChanged(prevProps);
  }

  updateKeywordsIfChanged(prevProps) {
    if (JSON.stringify(this.props.keywords) != JSON.stringify(prevProps.keywords)) this.updateFiltered();
  }

  updateTextFiltersIfChanged() {
    const { textFilters: _textFilters } = this.props;
    const { textFilters }               = this.state;
    if (_textFilters && !_.isEqual(textFilters, _textFilters)) {
      this.setState({textFilters: [..._textFilters]}, () => {
        this.updateFiltered();
      })
    }
  }

  autoAddGSCFilter() {
    let { fields: pFields } = this.props;
    let { fields: sFields } = this.state;
    pFields = pFields.map(f => f.key);
    if (pFields && !_.isEqual(sFields, pFields)) {
      this.setState({fields: [...pFields]}, () => {
        const { filters } = this.state;
        const viewKey = pFields.sort().join('');
        if (viewKey === gscViewKey && !sFields.includes('ctr')) {
          this.addFilter('gsc', true);
        }
        if ((viewKey === seoViewKey || viewKey === ppcViewKey) && filters?.gsc?.addedAutomatically) {
          this.deleteFilter('gsc');
        }
      })
    }
  }

  updateFiltered() {
    const { filters, textFilters } = this.state;
    const { keywords } = this.props;
    const filtered = getFilteredKeywords(keywords, filters, textFilters)
    this.setState({filtered});
    this.props.updateAngularCountAndMeta({
      count: filtered.length,
      filtered: (Object.keys(filters).length + Object.keys(textFilters).length) > 0
    });
  }

  addFilter(type, flagAutoAdd) {
    if (type === 'gsc' && this.props.onClickGsc) {
      return this.props.onClickGsc()
    }
    const { filters = {} } = this.state;
    const { keywords } = this.props;
    if (!filters.hasOwnProperty(type)) {
      if (['question', 'gsc'].includes(type)) {
        filters[type] = {addedAutomatically: flagAutoAdd}
      } else {
        let _vals = keywords.map(k => k[type]).filter(v => (v !== null))
        if (!['rank_change', 'clicks_change_domain'].includes(type)) {
          _vals = _vals.filter(v => (v >= 0));
        }
        if (_vals.length === 0) _vals = [0, 1];

        let availableMin = Math.min.apply(Math, _vals);
        let availableMax = Math.max.apply(Math, _vals);

        const metricType = getField(type).type;

        if (metricType === 'percentage') {
          availableMin = 0;
          availableMax = 100;
        }

        if (metricType === 'percentage_decimal') {
          availableMin = 0;
          availableMax = 1;
        }

        if (metricType === 'count') {
          ({ availableMin, availableMax } = calculateStepAndRoundedMinMax(availableMin, availableMax));
        }

        if (metricType === 'currency') {
          availableMin = 0.00;
          availableMax = Math.ceil(availableMax);
        }

        const min = availableMin;
        const max = availableMax;
        filters[type] = {
          min,
          max,
          availableMin,
          availableMax
        }
      }
    }
    this.setState({filters}, () => {
      this.updateFiltered();
    })
  }

  deleteFilter(type) {
    const { filters } = this.state;
    delete filters[type];
    this.setState({filters}, () => this.updateFiltered());
  }

  changeFilter(type, {values}) {
    const { filters } = this.state;
    const [min, max] = values;
    filters[type] = {
      ...filters[type], min, max
    }
    this.setState({filters});
    this.updateFiltered()
  }

  render () {

    const { keywords, fields, source, method, onClickGsc, showFilters = true } = this.props;
    const { filters } = this.state;

    return (
      fields && (
        <>
          { keywords && keywords.length > 0 && showFilters && (
            <>
              <FilterButtons
                onAddFilter={this.addFilter}
                filters={filters}
                source={source}
                method={method}
                fields={fields}
                gscConnected={!onClickGsc}
              ></FilterButtons>
              <div className='filters'>
                <Filters
                  filters={filters}
                  onChangeFilter={this.changeFilter}
                  onDeleteFilter={this.deleteFilter}
                ></Filters>
              </div>
            </>
          )}
          {this.props.children({
            hasFilters: Object.keys(filters).length > 0,
            ...this.state,
            ...this.props,
          })}
        </>
      )
    )
  }
}

export default FiltersProvider