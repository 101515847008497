let baseUrl = '/api/KLASS/:id';

export class $resourceManager {

  constructor($resourceRoutes, $resource, $q) {
    this.resources = {};
    this.$q = $q;
    // this.$resource = $resource;

    angular.forEach($resourceRoutes, function (value, key) {
      var url = baseUrl.replace('KLASS', key);
      this.resources[key] = $resource(url, null, value);
    }.bind(this));
  }

  request(klass, method, params, payload) {
    return this.$q(function (resolve, reject) {
      if (!this.resources[klass] || !this.resources[klass][method]) {
        throw 'Method missing from resource_routes.js -> ' + klass + ':' + method;
      }
      const innerPromise = this.resources[klass][method](params, payload).$promise;
      innerPromise.then(function () {
        resolve(innerPromise);
      }, function () {
        reject(innerPromise.$$state.value);
      });
    }.bind(this));
  }

}

$resourceManager.$inject = ['$resourceRoutes', '$resource', '$q'];
