import { sendTriggerUpsellEvent } from "../shared/events";
import { getField } from "../shared/fields";
import isValidDomain from "../shared/isValidDomain";
import "./domain.scss";

class DomainController {
  constructor($limits, UserService, $searchManager, $location, $rootScope, $upsells, $domainSearchSettings, $seoMetaLengths, $resultsStore, $listManagementNotifier, $saveToList, $activeList, $notifier, $tokenManager, $keywordsCollection, $sharedCookies, $meta) {

    this.$limits = $limits;
    this.results = $resultsStore;
    this.$searchManager = $searchManager;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.$upsells = $upsells;
    this.$domainSearchSettings = $domainSearchSettings;
    this.$seoMetaLengths = $seoMetaLengths;
    this.user = UserService.user;
    this.isFreeUser = UserService.user.isFreeUser;
    this.settingsChanged = false;
    this.$listManagementNotifier = $listManagementNotifier;
    this.$saveToList = $saveToList;
    this.$activeList = $activeList;
    this.$notifier = $notifier;
    this.getField = getField;
    this.$keywordsCollection = $keywordsCollection;
    this.$tokenManager = $tokenManager;
    this.$sharedCookies = $sharedCookies;
    this.$meta = $meta;
  }

  $onInit() {
    this.$searchManager.searchedDomain = !!this.results.site_report;
    this.setSearchAndQuery();
    this.$rootScope.$on('showUpsell', this.handleTooManySearches.bind(this));
    this.$tokenManager.getToken();
    this.$sharedCookies.set('seen_domain_bug', true);
    if (!window.document.title.match(/^(Traffic, Keywords and Stats for |By Domain).*/)) {
      this.$meta.setTitle('By Domain | Wordtracker');
      this.$meta.setDescription("Wordtracker is a keyword research tool that let's you see not just the keywords, but the traffic and SEO performance for any domain.");
    }
  }

  setSearchAndQuery() {
    let params = this.$location.search();
    let site = this.$domainSearchSettings.searchParams.site;
    if (params.hasOwnProperty('query')) {
      site = decodeURIComponent(params.query)
      if (params.hasOwnProperty('from_search')) this.$notifier.green('Looks like a URL! Switched to By Domain.');
      this.search(site);
    } else if (!params.query && site) {
      this.siteInput = site;
      this.$location.addParams({query: encodeURIComponent(site)});
    }
  }

  openModalIfExpired() {
    if (this.user.isExpiredUser) {
      sendTriggerUpsellEvent();
      return true;
    }
    return false;
  }

  search(site) {
    if (this.openModalIfExpired()) return false;
    try {
      site = site.replace(/(^\w+:|^)\/\//, '').split('/')[0];
      if (isValidDomain(site)) {
        this.$domainSearchSettings.searchParams.site = site;
        this.siteInput = site;
        this.$location.addParams({query: site});
        this.settingsChanged = false;
        this.$searchManager.searchDomain();
        this.$meta.setTitle(`Traffic, Keywords and Stats for ${site} powered by Wordtracker'`);
        this.$meta.setDescription(`Wordtracker is a keyword research tool that lets you see not just the keywords, but the traffic and SEO performance for any domain, including ${site}`);
      } else {
        throw 'Not valid URL'
      }
    } catch(error) {
      console.error(error);
      return this.$notifier.red('Unable to analyze this site. Please check the URL.');
    }
  }

  setSettingsChanged() {
    if (this.$searchManager.searchedDomain) this.settingsChanged = true;
  }

  setCountry(country) {
    const countryCode = (typeof country === "string") ? country : country.value || country.code;
    if (countryCode != this.$domainSearchSettings.searchParams.country) {
      this.$domainSearchSettings.searchParams.country = countryCode;
      this.setSettingsChanged();
    }
  };

  handleTooManySearches() {
    if (this.user.isFreeUser) {
      this.$upsells.new('unlimited_searches');
    } else if (this.user.isTrialUser) {
      this.$upsells.new('really_unlimited_searches');
    }
  }

  setCountry(countryCode) {
    this.$domainSearchSettings.searchParams.country = countryCode;
    this.setSettingsChanged();
  }

  onEnter() {
    if (this.$searchManager.searchedDomain && !this.settingsChanged) return false;
    const site = this.siteInput;
    this.search(site);
  }

  sendKeywordsToSearch() {
    if (!this.results.main) {
      this.results.main = this.$keywordsCollection({name: 'main'});
    }
    this.results.main.addFromCable({ results: this.results.keywords_for_domain_by_clicks.keywords });
    this.results.main.addFromCable({ results: this.results.keywords_for_domain_by_clicks_change.keywords });
    this.results.main.addFromCable({ results: this.results.site_report.topKeywords.keywords });
    this.$location.path("/search").search({from_domain: "true"});
  }

}

DomainController.$inject = ['$limits', 'UserService', '$searchManager', '$location', '$rootScope', '$upsells', '$domainSearchSettings', '$seoMetaLengths', '$resultsStore', '$listManagementNotifier', '$saveToList', '$activeList', '$notifier', '$tokenManager', '$keywordsCollection', '$sharedCookies', '$meta'];

export const domain = {
  controller: DomainController,
  template: require('./domain.html')
};
