import angular from 'angular';
import React from 'react';
import ReactDOM from 'react-dom';
import Results from './react/Results';

const renderReact = props => {
  const reactApp = document.getElementById(props.elementId);
  ReactDOM.render(<Results {...props}></Results>, reactApp)
}

export const reactResults = ($upsells, UserService, Modal) => {
  return {
    restrict: 'E',
    scope: {
      collection: '<',
      keywords: '<',
      serpsResults: '<',
      fields: '<',
      source: '<',
      method: '<',
      textFilters: '<',
      onClickResult: '<',
      onClickSparkline: '<',
      showFilters: '<',
      isFreeUser: '<',
      elementId: '<',
      perPage: '<',
      sortField: '<'
    },
    template: `<div id="{{elementId}}"></div>`,
    link: function (scope) {

      scope.elementId = angular.isDefined(scope.elementId) ? scope.elementId : 'reactapp';

      scope.onClickUnlock = () => $upsells.new("keywords")

      scope.setWatchFor = prop => {
        scope.$watch(prop, (newValue, oldValue) => {

          if (angular.isDefined(newValue)) {

            // Don't re-render if it's only selectedKeywords that has changed.
            if (newValue?.selectedKeywords?.length != oldValue?.selectedKeywords?.length) return false;

            const { collection, keywords, fields, source, method, perPage, sortField, uuid, serpsResults } = scope;
            
            const reactParams = {
              elementId: scope.elementId,
              keywords,
              collection,
              fields,
              source,
              serpsResults,
              method,
              perPage,
              sortField,
              showFilters: scope.showFilters,
              isFreeUser: scope.isFreeUser,
              [prop]: newValue,
              onClickResult: scope.onClickResult,
              onClickSparkline: scope.onClickSparkline,
              onClickUnlock: scope.onClickUnlock,
              onChangeSelected: collection.onChangeSelected,
              updateAngularCountAndMeta: collection.updateAngularCountAndMeta,
              onUpdateSorting: collection.onUpdateSorting,
            };

            if (UserService.user.isFreeUser) reactParams.onClickGsc = () => $upsells.new("gsc_data")
            if (!UserService.user.isFreeUser && !UserService.user.gsc_has_access_token) {
              reactParams.onClickGsc = () => {
                Modal.new({
                  component: 'gscModal',
                  dismissable: true,
                  bindings: {
                    heading: "Connect to Google",
                    description: "You can get <strong>Google Search Console (GSC) data</strong> right in your Wordtracker search results.<br><br>Simply click below to hook your account to GSC!",
                    action_uri: __ZUUL_URL + '/account/integrations',
                    action_text: 'Connect'
                  }
                })
              }
            }
            renderReact(reactParams);
          }
        }, true)
      }

      scope.setWatchesFor = props => props.map(scope.setWatchFor);

      scope.setWatchesFor(['collection', 'serpsResults', 'keywords', 'fields', 'source', 'textFilters']);
    }
  };
};

reactResults.$inject = ['$upsells', 'UserService', 'Modal'];
