export const $storage = function($resourceManager, $q, $mocks) {

  let storage = {};

  storage.mock = (klass, method, params, payload) => {
    return $q((resolve, reject) => {
      if ($mocks[klass].methods.hasOwnProperty(method)) {
        resolve($mocks[klass].methods[method](params, payload));
      } else {
        reject();
      }
    });
  };

  storage.shouldMock = (klass) => $mocks[klass] && $mocks[klass].shouldMock();

  storage.request = (klass, method, params, payload) => {

    return $q(function (resolve, reject) {

      let promise;

      if (storage.shouldMock(klass)) {
        promise = storage.mock(klass, method, params, payload);
      } else {
        promise = $resourceManager.request(klass, method, params, payload);
      }

      promise.then(() => {
        resolve(promise);
      }, (err) => {
        reject(err);
      });
    });
  };

  return storage;

};

$storage.$inject = ['$resourceManager', '$q', '$mocks'];
