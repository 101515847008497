import React from 'react';
import ReactDOM from 'react-dom';

// All Javascript
import "../global/ngmodule.js";

// CSS base styles
// (Mixins and vars from css/provided are already provided via webpack sass-resource-loader)
import "../global/css/_reset.scss";
import "../global/css/_typefaces.scss";
import "../global/css/_typography.scss";
import "../global/css/_buttons.scss";
import "../global/css/_dropdown.scss";
import "../global/css/_errors.scss";
import "../global/css/_form_rows.scss";
import "../global/css/_general.scss";
// import "../global/css/_modals.scss";
import "../global/css/_labels.scss";
import "../global/css/_text_fields.scss";
import "../global/css/animate.css";
import "../global/css/_animations.scss";
import "../global/css/_callouts.scss";
import "../global/css/_notices.scss";

// Vendor CSS
// import "chartist/dist/scss/chartist.scss";
import "angular-spinkit/build/angular-spinkit.min.css";
import "angular-rangeslider/angular.rangeSlider.css";
import "angular-percent-circle-directive/dist/percent-circle.scss";
import 'react-tooltip/dist/react-tooltip.css'

import Global from "../global_react/Global.js";

document.addEventListener("DOMContentLoaded", (e) => {
  ReactDOM.render(<Global/>, document.getElementById('global-react'))
});
