export const $resourceRoutes = function() {
  // Default routes created for key

  //   'get'   : { method: 'GET' }
  //   'save'  : { method: 'POST' }
  //   'query' : { method: 'GET', isArray: true}
  //   'remove': { method: 'DELETE' }
  //   'delete': { method: 'DELETE' }

  var routes = {
    'keywords': {
      'delete': {method: 'DELETE', isArray: true},
      'import': {method: 'POST', url: '/api/keywords/import'}
    },
    'lists': {
      'update': {method: 'PATCH', url: '/api/lists/:id'},
      'keywords': {method: 'GET', url: '/api/lists/:id/keywords'}
    },
    'projects': {
      'update': {method: 'PATCH', url: '/api/projects/:id'},
      'lists': {method: 'GET', url: '/api/projects/:id/lists', isArray: true}
    },
    'top_keywords': {
      'keywords': {method: 'GET', url: '/api/top'},
      'ideas': {method: 'GET', url: '/api/ideas'}
    },
    'generated_pages': {},
    'analytic_events': {},
    'report_orders': {
      'new': {method: 'GET', url: '/api/report_orders/new'},
      'update': {method: 'PUT', url: '/api/report_orders/:id'},
    },
    'request': {
      'save': {method: 'POST', uri: '/api/request/', cache: false}
    },
    'communications': {},
    'current': {
      'limits': {method: 'GET', url: '/api/current/limits'},
      'user': {method: 'GET', url: '/api/current/user'},
      // 'request_upgrade': {method: 'POST', url: '/api/current/request_upgrade'},
      'intent': {method: 'POST', url: '/api/current/intent'},
      'subscribe': {method: 'POST', url: '/api/current/subscribe'},
      'end_trial': {method: 'POST', url: '/api/current/end_trial'}
    },
    'countries': {},
    'tokens': {
      'generate': {method: 'POST', url: '/api/tokens/generate'},
      'remaining': {method: 'GET', url: '/api/tokens/remaining'},
      'reset': {method: 'GET', url: '/api/tokens/reset'}
    }
  };

  return routes;
};
