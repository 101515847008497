export const OrderBy = function () {

  function _OrderBy (params) {
    this.column = params.column;
    this.direction = params.direction || "ASC";
    this.folderComparator = params.folderComparator;
  }

  _OrderBy.prototype.setOrderBy = function(column) {
    if (column === this.column) {
      this.direction = (this.direction === "DESC") ? "ASC" : "DESC";
    } else {
      this.column = column;
      this.direction = "DESC";
    }
  };

  _OrderBy.prototype.orderFn = function() {
    const dir = (this.direction === "DESC") ? "-" : "";
    if (this.folderComparator && this.column != "created_at") {
      return [`!!${this.folderComparator}`, `${dir}${this.column}`];
    } else {
      return `${dir}${this.column}`;
    }
  }

  return _OrderBy;
}

