import React from 'react'
import PlanSalesSummary from './PlanSalesSummary';
import { getSalesText, getPlanByPriceId } from '../../shared/plans';
import logo from '../../images/wordtracker-logo-black.png';

export default function ActivateSubscription({onActivate, success, processing, priceId, user, userState}) {

  const salesText = getSalesText(priceId, userState);
  const plan = getPlanByPriceId(priceId);

  const choosePlansLink = user.isZuulUser ? `${__ZUUL_URL}/account/subscription/edit` : `${__ZUUL_URL}/sign-up`;

  const handleActivate = () => {
    onActivate(priceId)
      .then(res => {
        setTimeout(() => {window.location.href = window.location.href}, 2500);
      })
  }

  const renderBillingContent = () => {
    return (typeof salesText.billingContent === 'function') ?
      salesText.billingContent(plan) :
      salesText.billingContent;
  }

  const renderButtonHint = () => {
    return (typeof salesText.buttonHint === 'function') ?
      salesText.buttonHint(user.subscription) :
      salesText.buttonHint;
  }

  const renderForm = () => (
    <div className='signup-form-area details fade-in' style={{display: !success ? 'block' : 'none'}}>

      {salesText.subtitle && <p className='subtitle'>{salesText.subtitle}</p>}

      <div className='two-blocks'>

        <div className='signup-block stripe-form-area'>

          { renderBillingContent() }

          <div className='form-row'>

            <button
              disabled={processing || success}
              className='subscribe-standard'
              onClick={handleActivate}
            >
              {processing ? 'Processing...' : (salesText.buttonBilling || 'Subscribe now')}
            </button>
            {salesText.buttonHint && <span className='button-hint'>{renderButtonHint()}</span>}
          </div>

        </div>

        <PlanSalesSummary priceId={priceId} user={user} userState={userState} />

      </div>

      <span className='continue-button-note switch-to-monthly nolink'>Need more results or territories? <a href={choosePlansLink} target='_self'>Check out all our plans here</a>.</span>

    </div>
  )

  const renderDone = () => (
    <div className='signup-form-area done fade-in' style={{display: success ? 'block' : 'none'}}>
      <div className='big-tick'></div>
      <h2>Thank you.</h2>
      {salesText.doneHtml}
    </div>
  )

  return (
    <div className='sign-up-modal'>
      <div className='padlock-motif'></div>
      { !success && <>
        <img className='wt-logo-in-form' src={logo} alt='Wordtracker logo' />
        {salesText.title && (<h4>
          <i className="fa-regular fa-square-check"></i>
          {salesText.title}
        </h4>)}
      </> }
      {renderForm()}
      {renderDone()}
    </div>
  )
}
