import "./textField.scss"

export const textareaField = {
  template: `
    <div class='form-row'>
      <label ng-class='{"has_error": !!$ctrl.error}'>
        <span ng-transclude></span>
        <span class='error fade-down' ng-if='$ctrl.error'>({{$ctrl.error}})</span>
        <textarea ng-change='$ctrl.error = null; $ctrl.hasError = null' ng-model='$ctrl.model' noresize placeholder='{{$ctrl.placeholder}}'>
      </label>
    </div>
  `,
  bindings: {
    model: '=',
    error: '<',
    placeholder: '@',
  },
  transclude: true
}