import "./lists.scss";

class _Controller {

  constructor(UserService, $location, $lists) {
    this.isFreeUser = UserService.user.isFreeUser;
    this.$location = $location;
    $lists.initialize();
  }

  $onInit() { // Ideally move this to router or some redirects hook
    if (this.isFreeUser) this.$location.path('/search');
  }

}

_Controller.$inject = ['UserService', '$location', '$lists'];

export const lists = {
  controller: _Controller,
  template: `
    <div id='lists-page'>
      <ui-view></ui-view>
    </div>`
};
