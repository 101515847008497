import React from 'react';
import KeywordsProvider from './KeywordsProvider';
import FiltersProvider from './FiltersProvider';
import KeywordsDisplay from './KeywordsDisplay';

// README
// KeywordsProvider: takes data from AngularJS, stores its own state of it, updating when appropriate, and passes it down to the children.
// FiltersProvider: adds filtering capabilities, and returns filtered keywords
// KeywordDisplay: handles pagination, sorting etc, and then renders the table.

export default angularProps => {


  return (
    <>
      <KeywordsProvider {...angularProps} source={angularProps.collection && angularProps.collection.name}>
        {({uuid, ...props}) => (
          // KeywordsProvider generates UUID for each search, so we can quickly reset by just re-mounting the filter component on a new search
          <>
            <FiltersProvider
              key={uuid}
              updateAngularCountAndMeta={angularProps.updateAngularCountAndMeta}
              onClickGsc={angularProps.onClickGsc}
              isFreeUser={angularProps.isFreeUser}
              showFilters={angularProps.showFilters}
              method={angularProps.method}
              {...props}
            >{({filtered, hasFilters}) => (
                <KeywordsDisplay
                  {...props}
                  hasFilters={hasFilters}
                  keywords={filtered}
                  isFreeUser={angularProps.isFreeUser}
                  onClickResult={angularProps.onClickResult}
                  onClickSparkline={angularProps.onClickSparkline}
                  onClickUnlock={angularProps.onClickUnlock}
                  onChangeSelected={angularProps.onChangeSelected}
                  onUpdateSorting={angularProps.onUpdateSorting}
                  perPage={angularProps.perPage}
                  sortField={angularProps.sortField}
                  serpsResults={angularProps.serpsResults}
                  possible={angularProps.collection.possible}
                ></KeywordsDisplay>
              )}
            </FiltersProvider>
          </>
        )}
      </KeywordsProvider>
    </>
  )
}