import React from 'react'
import PercentBar from './PercentBar';
import PercentLozenge from './PercentLozenge';
import { getField } from '../../../shared/fields';
import Sparkline from './Sparkline'
import NumberChange from './NumberChange';
import RankHistoryMetric from './RankHistoryMetric';

const isFloat = (n) => Number(n) === n && n % 1 !== 0;

const intentGrades = [
  { name: 'Very low',  color: '#df2f50'},
  { name: 'Low',       color: '#fb6d1a'},
  { name: 'Medium',    color: '#FBAC1A'},
  { name: 'High',      color: '#0BC872'},
  { name: 'Very high', color: '#0BC872'}
];

export default function Metric({result, fieldKey, onClickSparkline}) {

  if (!fieldKey) return null;
  
  const field = getField(fieldKey);

  const renderBigNumber = (result, fieldKey) => {
    return <span>{result[fieldKey].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
  }

  const metricRenderers = {
    competition: (result, fieldKey) => {
      let { competition } = result;
      if (competition > 0) competition /= 100;
      return <PercentLozenge
        colorType='low-is-good'
        key={result.keyword}
        data={competition}
      />
    },
    pct_mobile_searches: (result, fieldKey) => (
      <PercentBar
        key={result.keyword}
        data={result.pct_mobile_searches}
      />
    ),
    pct_desktop_searches: (result, fieldKey) => (
      <PercentBar
        key={result.keyword}
        data={result.pct_desktop_searches}
      />
    ),
    pct_organic_clicks: (result, fieldKey) => (
      <PercentBar
        key={result.keyword}
        data={result.pct_organic_clicks}
      />
    ),
    pct_paid_clicks: (result, fieldKey) => (
      <PercentBar
        key={result.keyword}
        data={result.pct_paid_clicks}
      />
    ),
    pct_searches_no_clicks: (result, fieldKey) => (
      <PercentBar
        colorType='low-is-good'
        key={result.keyword}
        data={result.pct_searches_no_clicks}
      />
    ),
    rank_change: (result, fieldKey) => (
      <NumberChange
        key={result.keyword}
        data={result.rank_change}
      />
    ),
    clicks_change_domain: (result, fieldKey) => (
      <NumberChange
        key={result.keyword}
        data={result.clicks_change_domain}
      />
    ),
    cpc: (result, fieldKey) => {
      if (result.cpc == "-1" || result.cpc == null) return <span>&mdash;</span>;
      let num = result[fieldKey];
      num = +(Math.round(num + "e+2")  + "e-2");
      return <>
        <span className='metric-currency-mark'>$</span>{parseFloat(num).toFixed(2)}
      </>
    },
    volume: (result, fieldKey, onClickSparkline) => {
      return <>
        { result.volume_by_month && <Sparkline tooltipFormatter={(_sparklines, _options, point) => point.y} data={result.volume_by_month} onClick={onClickSparkline} width='50px' height='16px' /> }
        { (result.volume == "-1" || result.volume == null) && <span>&mdash;</span> }
        { !(result.volume == "-1" || result.volume == null) && renderBigNumber(result, fieldKey) }
      </>
    },
    position: (result, fieldKey) => {      if (result.position == "-1" || result.position == null) return <span>&mdash;</span>;
      let num = result[fieldKey];
      return num.toFixed(2);
    },
    ctr: (result, fieldKey) => {
      if (result.ctr == "-1" || result.ctr == null) return <span>&mdash;</span>;
      let num = result[fieldKey];
      return num.toFixed(2);
    },
    impressions: (result, fieldKey) => {
      if (result.impressions == "-1" || result.impressions == null) return <span>&mdash;</span>;
      return renderBigNumber(result, fieldKey)
    },
    clicks_domain: (result, fieldKey) => {
      if (result.clicks_domain == "-1" || result.clicks_domain == null) return <span>&mdash;</span>;
      return renderBigNumber(result, fieldKey)
    },
    clicks: (result, fieldKey) => {
      if (result.clicks == "-1" || result.clicks == null) return <span>&mdash;</span>;
      return renderBigNumber(result, fieldKey)
    },
    ranking_url: (result, fieldKey) => {
      if (result.ranking_url == "-1" || result.ranking_url == null) return <span>&mdash;</span>;
      const { pathname } = new URL(result.ranking_url);
      return <span title={pathname}>{pathname}</span>;
    },
    rank: (result, fieldKey) => {
      if (result.rank == "-1" || result.rank == null) return <span style={{opacity: 0.3, textAlign: 'center'}}>&gt; 99</span>;
      return <PercentLozenge
        colorType='low-is-good'
        key={result.keyword}
        data={(result.rank / 100)}
      />;
    },
    rank_history: (result, fieldKey, onClickSparkline) => {
      return result.rank_history ? <RankHistoryMetric data={result.rank_history} /> : <span style={{ display: 'block', marginTop: '11px', width: '100%', textAlign: 'right', paddingRight: '12px'}}>&mdash;</span>;
    },
    generic: (result, fieldKey) => {
      let val = result[fieldKey];
      if (val == "-1" || val == null) return <span>&mdash;</span>
      if (isFloat(val)) val = val.toFixed(2);
      return <span>{val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
    },
    intent_legacy: (result, fieldKey) => {
      const cmp = result.cmp_legacy;
      if (!cmp) return <span>&mdash;</span>;
      if (cmp == "-1" || cmp == null) return <span>&mdash;</span>;

      var grade = null;
    
      if (cmp >= 3) {
        grade = intentGrades[4];
      } else if (cmp >= 2 && cmp < 3) {
        grade = intentGrades[3];
      } else if (cmp >= 1 && cmp < 2) {
        grade = intentGrades[2];
      } else if (cmp >= 0.5 && cmp < 1) {
        grade = intentGrades[1];
      } else if (cmp < 0.5) {
        grade = intentGrades[0];
      }
      return <span style={{color: grade.color}}>{grade.name}</span>
    },
  }

  if (fieldKey === 'rank_history') return metricRenderers[fieldKey](result, fieldKey, onClickSparkline)
  
  if (result[fieldKey] === undefined && (result.appended || result.prefilled)) return <span className="ajax-loader"></span>

  if (result[fieldKey] === undefined && !field.calculated) return <span>&mdash;</span>

  if (metricRenderers.hasOwnProperty(fieldKey)) {
    return metricRenderers[fieldKey](result, fieldKey, onClickSparkline)
  } else {
    return metricRenderers.generic(result, fieldKey)
  }

}