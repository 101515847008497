import "./topKeywords.scss";

class Controller {
  constructor($resourceManager, $keywordsCollection, $activeColumns) {
    this.$resourceManager = $resourceManager;
    this.$activeColumns = $activeColumns;
    this.keywords = $keywordsCollection({name: 'topKeywords'});
    this.forceColspan = 2;
    $activeColumns.onSearch();
    $activeColumns.loadVanilla();
  }

  $onInit() {
    this.$resourceManager.request('top_keywords', 'keywords', {source: (this.engine || 'google'), country: this.country}).then(response => {
      this.keywords.add(response.keywords.results);
    });
  }

}

Controller.$inject= ['$resourceManager', '$keywordsCollection', '$activeColumns'];

export const topKeywords = {
  bindings: {
    engine: '@',
    country: '@',
  },
  controller: Controller,
  template: require('./genericKeywords.html')
};