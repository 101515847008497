class Controller {
  constructor($window) {
    this.$window = $window;
  }

  goToGetStarted(){
    this.$window.location.href = "https://wordtracker.firstpromoter.com";
  }

  goToDocs(){
    this.$window.location.href = "https://www.wordtracker.com/academy/keyword-research/tool/how-to-become-a-wordtracker-affiliate";
  }

}

Controller.$inject= ['$window'];

export const affiliatePage = {
  controller: Controller,
  template: require('./affiliatePage.html')
};