export const $tokenCatcher = function ($rootScope, $notifier, UserService, $timeout) {

  let tokenCatcher = {
    suspended: false
  };

  tokenCatcher.onTooMany = () => {
    $timeout(() => {
      if (UserService.user.isFreeUser || UserService.user.isExpiredUser) {
        $rootScope.$broadcast('showUpsell');
      } else {
        $notifier.red('Too many recent searches. Try again later.');
      }
    })
  }

  $rootScope.$on('token::429', tokenCatcher.onTooMany);

  $rootScope.$on('token::403', function () {
    tokenCatcher.suspended = true;
  });

  return tokenCatcher;

};

$tokenCatcher.$inject = ['$rootScope', '$notifier', 'UserService', '$timeout'];
