import React, { Component } from 'react';

import KeywordRow from './KeywordRow';
import TableHeader from './TableHeader';
import LoadingMessage from './LoadingMessage';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import generateRandomGibberish from '../../../shared/generateGibberish';
import Modal from './Modal/Modal';
import ExamineKeywordModal from './ExamineKeywordModal'

import "./Tooltip.scss"

class KeywordTable extends Component {

  constructor(props) {
    super(props)
    this.state = { isShiftDown: false, isModalShown: false, modalResult: null }
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSelectStart = this.handleSelectStart.bind(this);
    this.handleClickSparkline = this.handleClickSparkline.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.listEl = null;
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyUp, false);
    document.addEventListener("keydown", this.handleKeyDown, false);
    this.listEl.addEventListener("selectstart", this.handleSelectStart, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("keydown", this.handleKeyDown);
    this.listEl.removeEventListener("selectstart", this.handleSelectStart);
  }

  handleSelectStart(e) {
    if (this.state.isShiftDown) {
      e.preventDefault();
    }
  }

  handleKeyUp(e) {
    if (e.key === "Shift" && this.state.isShiftDown) {
      this.setState({ isShiftDown: false });
    }
  }

  handleKeyDown(e) {
    if (e.key === "Shift" && !this.state.isShiftDown) {
      this.setState({ isShiftDown: true });
    }
  }

  handleClickSparkline(result) {
    this.props.onClickSparkline(result);
    this.setState({
      modalResult: result,
      isModalShown: true
    });
  }

  closeModal() {
    this.setState({
      modalResult: null,
      isModalShown: false
    });
  }

  renderTableHeader = field => {
    const { sortField, sortDescending, onSetSorting, results } = this.props;
    return (
      <TableHeader
        key={field.key}
        field={field}
        hasHistorical={results && results.length > 0 && results[0].hasOwnProperty('volume_by_month')}
        sortField={sortField}
        sortDescending={sortDescending}
        onSetSorting={onSetSorting}
      ></TableHeader>
    )
  }

  renderTableHead = () => {
    const { source, fields, onToggleSelectAll, isSelectAll, results } = this.props;
    return (
      <thead>
        <tr>
          <th className='select-keyword'>
            <input
              type='checkbox'
              disabled={!results || results && results.length == 0}
              checked={isSelectAll}
              onChange={onToggleSelectAll} />
          </th>
          <th className='keyword'>
            <span className='label'>Keyword</span>
            {/* <span className='label historical'>Performance</span> */}
          </th>
          {source === 'mixed' && (
            <th className='keyword-source table-stat'>
              <span className='label'>Data source</span>
            </th>
          )}
          { fields.map(this.renderTableHeader) }
          {/* <th className='spacer'></th> */}
        </tr>
      </thead>
    )
  }

  renderNoResultsExplainer = () => {
    const { loading, failed, hasFilters } = this.props;
    return (
      <tr key='dimmed'>
        <td className='dimmed'>
          { loading && <LoadingMessage/> }
          { !loading && failed && "Sorry, there has been an error. Please try again." }
          { !loading && !failed && (
            <>
              { hasFilters && "No results matching those filter parameters" }
              { !hasFilters && "No results for this query." }
            </>
          )}
        </td>
      </tr>
    )
  }

  renderKeywordRow = (result, idx) => {
    const { fields, sortField, onClickResult, selectedItems, handleSelectItem, source, onClickUnlock } = this.props;
    const { isShiftDown } = this.state;

    return (
      <KeywordRow
        key={idx}
        result={result}
        fields={fields}
        sortField={sortField}
        onClickResult={onClickResult}
        source={source}
        onClickSparkline={() => { this.handleClickSparkline(result)}}
        selected={selectedItems.includes(result.keyword)}
        onChange={(event) => handleSelectItem(event, isShiftDown)}
      ></KeywordRow>
    )
  }

  renderPaddedResultRow = (result, idx) => {
    const { fields, source, onClickUnlock, isFreeUser } = this.props;
    return (
      <KeywordRow
        key={idx}
        result={result}
        isPadded={true}
        fields={fields}
        onClickResult={onClickUnlock}
        source={source}
      ></KeywordRow>
    )
  }

  renderPaddedResults = () => {
    const paddedResults = [];
    const MAX_PADDED_RESULTS = 50;
    let resultsLength = 0;
    if (this.props.results) resultsLength = this.props.results.length;
    const numResultsPossibleNotShown = Math.max((this.props.possible - resultsLength) , 0);
    const numPaddedResults = Math.min(MAX_PADDED_RESULTS, numResultsPossibleNotShown);

    for (var i = 0; i < numPaddedResults; i++) {
      paddedResults.push({ keyword: generateRandomGibberish()});
    }

    return  paddedResults.map(this.renderPaddedResultRow)
  }

  render() {
    const { isModalShown, modalResult } = this.state;
    const { results, totalResults, isFreeUser, fields, serpsResults } = this.props;

    const isSuperSlimline = fields.length > 3;

    const tableClass = classNames({
      'results-list': true,
      'super-slimline': isSuperSlimline,
      'slimline': (!isSuperSlimline && fields.length > 2)
    })

    return (
      <>
        <Tooltip className="react-tooltip-class" id="tooltip-percent-bar" />
        <div className='table-scroll-holder'>
          <table className={tableClass}>
            {this.renderTableHead()}
            <tbody ref={node => (this.listEl = node)}>
              {totalResults > 0 && results && results.map(this.renderKeywordRow)}
              {totalResults > 0 && results && isFreeUser && this.renderPaddedResults()}
              {totalResults === 0 && this.renderNoResultsExplainer()}
            </tbody>
          </table>
        </div>
        <Modal show={isModalShown} onClose={this.closeModal}>
          <ExamineKeywordModal result={modalResult} serpsResults={serpsResults} onClose={this.closeModal} />
        </Modal>
      </>
    )
  }
}

export default KeywordTable;
