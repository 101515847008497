import "./dial.scss";

export const dial = gradedColorClassFilter => {
  return {
    restrict: 'E',
    scope: {
      value: '=',
      target: '=',
      metricName: '@'
    },
    template: `
      <svg class="percentage-circle {{metricName}}" viewBox="0 0 150 150" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet">
        <circle r="60" cx="75" cy="75" fill="transparent" stroke-dasharray="376.9911184" stroke-dashoffset="0"></circle>
        <circle class="bar" r="60" cx="75" cy="75" fill="transparent" stroke-dasharray="376.9911184" stroke-dashoffset="0"></circle>
      </svg>
    `,
    
    link: function(scope, element) {

      var renderSVG = function() {

        var val = (scope.value > 0) ? scope.value * 100 : 0;

        var $circle = $(element).find('.percentage-circle').find('.bar');

        if (isNaN(val)) {
         val = 0;
        } else {
          var r = $circle.attr('r');
          var c = Math.PI*(r*2);
          if (val < 0) { val = 0;}
          if (val > 100) { val = 100;}
          var pct = ((100-val)/100)*c;
          $circle.css({ strokeDashoffset: pct});
        }

        var klass = gradedColorClassFilter(scope.value, parseFloat(scope.target));
        $(element).find('.percentage-circle').find('.bar').addClass(klass);

      };

      scope.$watch('value', function (newV, oldV) {
        if (oldV != newV) renderSVG();
      }, true);

      renderSVG();

    }

  };
};

dial.$inject = ['gradedColorClassFilter'];