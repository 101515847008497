import {app} from "./app.component.js";
import {autoplayVideo} from "./autoplayVideo.component.js";

const appState = {
  name: 'app',
  redirectTo: 'home',
  component: 'app',
  resolve: {
    user: ['$resourceManager', 'UserService', '$q', '$limits', function ($resourceManager, UserService, $q, $limits) {
      return $q.all([
        $resourceManager.request('current', 'user').then((response) => response.user),
        $resourceManager.request('current', 'limits').then((response) => response),
      ]).then(([user, limits]) => {
        $limits.initialize(limits, user);
        return UserService.initialize(user);
      });
    }]
  }
};

export const MAIN_MODULE = angular.module('main', []);

MAIN_MODULE.config(['$uiRouterProvider', function($uiRouter) {
  // $uiRouter.trace.enable(1);
  
  const $urlService = $uiRouter.urlService;

  $urlService.rules.otherwise(function ($injector, $location) {
    const path = $location.path;
    if (path.charAt(path.length - 1) == '/') {
      return path.slice(0, -1);
    } else {
      return "/";
    }
  });

  const $stateRegistry = $uiRouter.stateRegistry;
  $stateRegistry.register(appState);

}]);

MAIN_MODULE.component('app', app);
MAIN_MODULE.component('autoplayVideo', autoplayVideo);
