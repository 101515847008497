import React from 'react'

const DICTIONARY = {
  google: "Google",
  google_raw: "Google",
  google_g: "Google",
  google_blend: "Google",
  amazon: "Amazon",
  youtube: "YouTube",
  ebay: "eBay",
  wordtracker: "Wordtracker",
};

const sourceName = (input, plainGoogle) => {
  if (DICTIONARY.hasOwnProperty(input)) {
    if (plainGoogle && input === "google") return "Google"; 
    return DICTIONARY[input];
  } else {
    return input;
  }
}

export default function SourceName({source}) {
  return <>{sourceName(source)}</>
}
