export class $notifier {

  constructor($timeout, $sce) {
    this.$timeout = $timeout;
    this.$sce = $sce;
  }

  create(type, text, persist) {
    this.type = type;
    this.text = this.$sce.trustAsHtml(String(text));
    this.active = true;
    this.$timeout(function() {
      if (!persist) this.dismiss();
    }.bind(this), 4000);
  }

  green(text, persist) {
    this.create('success', text, persist);
  }

  red(text, persist) {
    this.create('error', text, persist);
  }

  dismiss() {
    this.active = false;
    this.type = null;
    this.text = null;
  }

}

$notifier.$inject = ['$timeout', '$sce'];
