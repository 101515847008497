export const $exampleSeeds = function() {

  var exampleSeeds = {
    store: [
      "sandcastle",
      "woodland",
      "cheap hotels",
      "italian food",
      "red dress",
      "trainers",
      "winter wonderland"
    ],
    pick: function() {
      return this.store[_.random(this.store.length - 1)];
    }
  };

  return exampleSeeds;
};
