import { searchLine } from "./search_line/searchLine.component";
import { lateral } from "./lateral/lateral.component";
import { rightSidebar } from "./right_sidebar/rightSidebar.component";
import { centralArea } from "./central_area/centralArea.component";

import { amazonResults } from "./amazon_results/amazonResults.component";
import { listManagement } from "./list_management/listManagement.component.js";
import { exportResultsButton } from "./export_results_button/exportResultsButton.component";
import { filterButtons } from "./filter_buttons/filterButtons.component";
import { serp } from "./serp/serp.component";
import { pendingResults } from "./pending_results/pendingResults.component";
import { keywordCountSummary } from "./keyword_count_summary/keywordCountSummary.component";
import { search } from "./search.component.js";

export const SEARCH_MODULE = angular.module('searchModule', []);

export const searchState = {
  parent: 'app',
  name: 'search',
  title: 'PRE-SET',
  section: 'search',
  url: '/search',
  component: 'search',
  resolve: {
    cable: ['$cableConnection', '$activeColumns', function ($cableConnection, $activeColumns) {
      $activeColumns.onSearch();
      // $cableConnection.connect();
      return true;
    }]
  }
};


SEARCH_MODULE.config(['$stateRegistryProvider', function($stateRegistry) {
  $stateRegistry.register(searchState);
}]);

SEARCH_MODULE.component('search', search);
SEARCH_MODULE.component('lateral', lateral);
SEARCH_MODULE.component('rightSidebar', rightSidebar);
SEARCH_MODULE.component('centralArea', centralArea);

SEARCH_MODULE.component('keywordCountSummary', keywordCountSummary);

SEARCH_MODULE.component('amazonResults', amazonResults);
SEARCH_MODULE.component('listManagement', listManagement);
SEARCH_MODULE.component('serp', serp);
SEARCH_MODULE.component('pendingResults', pendingResults);
SEARCH_MODULE.component('filterButtons', filterButtons);
SEARCH_MODULE.component('exportResultsButton', exportResultsButton);
