export const planLimits = {
  template: `
  <table class="pricing">
  <thead>
    <tr class='row-plan'>
      <th class='title-col'>Plan</th>
      <th>Standard</th>
      <th class="gold">Custom</th>
    </tr>
  </thead>
  <tbody>
    <tr class='row-calls-per-month'>
      <td class='title-col'>Max calls per month</td>
      <td>10,000</td>
      <td class="gold">Unlimited</td>
    </tr>
    <tr class='row-calls-per-day'>
      <td class='title-col'>Calls per day</td>
      <td>1000</td>
      <td class="gold">Unlimited</td>
    </tr>
  </tbody>
</table>
`
};