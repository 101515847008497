import "./autocompleteDropdown.scss";

export const autocompleteDropdown = function ($dropdownObject) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      limits: '<',
      disabledOptionFn: '&',
      onSelectItem: '&',
      notFoundText: '@',
      items: '<',
      defaultValue: '<',
    },
    template: require('./autocompleteDropdown.html'),
    link: function (scope, element) {

      let input = $('input', element);

      scope = $dropdownObject(scope, element, {greedy: true, forceSelect: true});

      scope.selectedItem = scope.defaultValue;

      scope.matches = angular.copy(scope.items);

      scope.setSelectedItem = function(item) {
        scope.isOpen = false;
        if (item.disabled) {
          scope.disabledOptionFn();
        } else {
          scope.selectedItem = item;
          scope.onSelectItem({item});
        }
        scope.blur();
      };

      var blurLinkSelect = function(e) {
        if (_.isLink(e.target)) {
          $('body').off('click.blur keyup.blur', blurLinkSelect);
          return false;
        }
        if (!$.contains($(element)[0], $(e.target)[0])) scope.blur();
      };

      scope.blur = function(noClean) {
        $('body').off('click.blur keyup.blur', blurLinkSelect);
        input.blur();
        scope.close();
        if (!noClean) {
          scope.searchText = null;
          scope.matches = scope.items;
        }
      };

      scope.open = function() {
        $('body').on('click.blur keyup.blur', blurLinkSelect);
        scope.isOpen = true;
      };

    }
  };
};

autocompleteDropdown.$inject = ['$dropdownObject'];