export const SearchCSV = function ($searchAbstract, $timeout, cfpLoadingBar, $keywordsCollection) {
  function _SearchCSV (searchSettings) {
    var action = searchSettings.source;

    var payload = function () {
      var _payload = {};
      _.extend(_payload, searchSettings.searchParams);
      _payload.source = searchSettings.source;
      return _payload;
    };

    var search = $searchAbstract('search', action, 'main', payload);

    search.store = function () {
      let collection = $keywordsCollection({name: 'csv'});

      if (searchSettings) {
        collection.activeSeed = searchSettings.activeSeed;
        collection.source = searchSettings.source;
        collection.country = searchSettings.searchParams.country;
        collection.month = searchSettings.searchParams.month;
      }

      return collection;
    };

    return search;
  }

  return _SearchCSV;
};

SearchCSV.$inject = ['$searchAbstract', '$timeout', 'cfpLoadingBar', '$keywordsCollection'];
