export const FILTERS_MODULE = angular.module('filters', []);

import { monthName } from "./monthName.filter";
import { countryName } from "./countryName.filter";
import { capitalize } from "./capitalize.filter";
import { highlightOption } from "./highlightOption.filter";
import { truncate } from "./truncate.filter";
import { randomPhrase } from "./randomPhrase.filter";
import { spinalCase } from "./spinalCase.filter";
import { randomNumber } from "./randomNumber.filter";
import { sourceName } from "./sourceName.filter";
import { removeTrailingSlash } from "./removeTrailingSlash.filter";
import { removeProtocol } from "./removeProtocol.filter";
import { toGraded } from "./toGraded.filter";
import { bigNumber } from "./bigNumber.filter";
import { colNotAvailableTip } from "./colNotAvailableTip.filter";
import { gradedColorClass } from "./gradedColorClass.filter";
import { metricRating } from "./metricRating.filter";
import { listSources } from "./listSources.filter";


FILTERS_MODULE.filter('monthName', monthName);
FILTERS_MODULE.filter('countryName', countryName);
FILTERS_MODULE.filter('spinalCase', spinalCase);
FILTERS_MODULE.filter('capitalize', capitalize);
FILTERS_MODULE.filter('highlightOption', highlightOption);
FILTERS_MODULE.filter('truncate', truncate);
FILTERS_MODULE.filter('randomPhrase', randomPhrase);
FILTERS_MODULE.filter('randomNumber', randomNumber);
FILTERS_MODULE.filter('sourceName', sourceName);
FILTERS_MODULE.filter('removeTrailingSlash', removeTrailingSlash);
FILTERS_MODULE.filter('removeProtocol', removeProtocol);
FILTERS_MODULE.filter('toGraded', toGraded);
FILTERS_MODULE.filter('bigNumber', bigNumber);
FILTERS_MODULE.filter('colNotAvailableTip', colNotAvailableTip);
FILTERS_MODULE.filter('gradedColorClass', gradedColorClass);
FILTERS_MODULE.filter('metricRating', metricRating);
FILTERS_MODULE.filter('listSources', listSources);

