import "./centralArea.scss";

class _Controller {
  constructor($textFilters, $limits, UserService, $resultsStore, $searchSettings, $upsells, $activeList, $listManagementNotifier) {
    this.textFilters = $textFilters;
    this.searchSettings = $searchSettings;
    this.$listManagementNotifier = $listManagementNotifier;
    this.$upsells = $upsells;
    this.$activeList = $activeList;
    this.limits = $limits;
    this.results = $resultsStore;
    this.isFreeUser = UserService.user.isFreeUser;
  }

  saveKeywordsToActiveList(keywords) {
    this.$listManagementNotifier.pending('Saving...');
    this.$activeList.saveKeywordsToList(keywords).then(() => {
      this.$listManagementNotifier.success('Saved');
    });
  }

}

_Controller.$inject = ['$textFilters', '$limits', 'UserService', '$resultsStore', '$searchSettings', '$upsells', '$activeList', '$listManagementNotifier'];

export const centralArea = {
  bindings: {
    type: '<',
    fromDomain: '<'
  },
  controller: _Controller,
  template: require('./centralArea.html')
};
