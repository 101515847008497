import React, {Component} from 'react';

let opts = {
  type: 'line',
  width: '50',
  height: '16',
  lineColor: '#5195e9',
  spotColor: undefined,
  minSpotColor: undefined,
  maxSpotColor: undefined,
  highlightSpotColor: undefined,
  highlightLineColor: undefined,
  spotRadius: 0,
  normalRangeMin: 0,
};

class Sparkline extends Component {
  
  constructor(props) {
    super(props)
    this.sparklineRef = React.createRef();
  }
  
  componentDidMount () {
    let data;
    
    if (this.props.data) {
      data = [...this.props.data];
      data = data.map(x => (x.count || x.volume));
      if (data.reduce((sum, x) => sum + x, 0) === 0) {
        data = [1,0,2,2,4,1,4,7,8,10,9,6];
        opts.disableHighlight = true;
        opts.disableTooltips = true;
        opts.lineColor = '#c2d7f0';
        opts.fillColor = 'rgba(194, 215, 240, 0.2';
      } else {
        const min = _.min(data);
        opts.normalRangeMin = min / 3;
        opts.lineColor = '#5195e9';
        opts.fillColor = 'rgba(194, 215, 240, 0.5';
        opts.disableHighlight = this.props.hasOwnProperty('disableTooltips') ? this.props.disableTooltips : false;
        opts.disableTooltips = opts.disableHighlight;
        if (this.props.hasOwnProperty('min')) {
          opts.chartRangeMin = this.props.min;
        }
        if (this.props.hasOwnProperty('max')) {
          opts.chartRangeMax = this.props.max;
        }
        if (this.props.hasOwnProperty('type')) {
          opts.type = this.props.type;
        }
        if (this.props.hasOwnProperty('width')) {
          opts.width = this.props.width;
        }
        if (this.props.hasOwnProperty('height')) {
          opts.height = this.props.height;
        }
        if (this.props.hasOwnProperty('tooltipFormatter')) {
          opts.tooltipFormatter = this.props.tooltipFormatter;
        }
      }
    } else {
      data = [1,0,2,2,4,1,4,7,8,10,9,6];
      opts.disableHighlight = true;
      opts.disableTooltips = true;
      opts.lineColor = '#c2d7f0';
      opts.fillColor = 'rgba(194, 215, 240, 0.2';
    }
    if (this.props.mini) {
      opts.width = '40';
      opts.height = '16';
    }

    $(this.sparklineRef.current).sparkline(data, opts);
  }
  
  render() {
    return (
      <div
        className="sparkline"
        ref={this.sparklineRef}
        onClick={this.props.onClick}
      ></div>
    )
  }

}

export default Sparkline