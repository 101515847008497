import "./navWithSubmenu.scss";

class _Controller {
  constructor() {
    this.shown = false;
  }

  show() {
    this.shown = true;
  }
  
  hide() {
    this.shown = false;
  }

}

export const navWithSubmenu = {
  controller: _Controller,
  transclude: true,
  template: `
    <a ng-click='$ctrl.show()' class='nav-with-submenu-title nav-link' ng-class='{open: $ctrl.shown}'>{{$ctrl.name}}</a>
    <div class='fade-in submenu-holder' ng-show='$ctrl.shown' off-click='$ctrl.hide()' off-click-if='$ctrl.shown' off-click-filter="'.nav-with-submenu-title'" ng-transclude></div>
  `,
  bindings: {
    name: '@'
  }
}