import isValidDomain from "../../../../shared/isValidDomain";

class Controller {

  constructor($saveToList, $limits, $lists) {
    this.s = $saveToList;
    this.$lists = $lists;
    this.$limits = $limits;
    this.isValidDomain = true;
  }

  $onInit() {
    this.domainInput = this.domain ? this.domain.replace(/\/([^/?#]+)([?#].*)?$/, '') : null;
    this.setCountry(this.country || 'US');
  }

  resolve(list) {
    this.close({value: list});
  }

  onChangeDomain() {
    this.isValidDomain = isValidDomain(this.domainInput);
  }

  createList() {
    return this.s.createListWithKeywords(
      {
        title: this.inputValue,
        domain: this.isRankingList ? this.domainInput.replace(/\/([^/?#]+)([?#].*)?$/, '') : null,
        countries: [this.country]
      },
      this.getKeywordsFn,
      this.resolve.bind(this)
    );
  }

  setCountry(country) {
    if (!country || !this.$limits.countries.ranking.available.includes(country)) country = 'US';
    this.country = country;
  }

}

export const createListModal = {
  controller: Controller,
  bindings: {
    close: '&',
    isRankingList: '<',
    domain: '<',
    countrySource: '<',
    country: '<',
    getKeywordsFn: '<'
  },
  template: require('./createListModal.html'),
}

Controller.$inject = ['$saveToList', '$limits', '$lists'];