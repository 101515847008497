class _Controller {
  constructor() {}
}

_Controller.$inject = [];

export const pendingResults = {
  bindings: {
    results: '<'
  },
  controller: _Controller,
  template: require('./pendingResults.html')
};
