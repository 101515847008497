export const truncate = function() {
  return function(text, length) {
    length = length || 48;
    if (!text) return "";
    var truncate = text.substr(0, length);
    if (truncate.length < text.length) truncate += "...";
    // if (/^\S/.test(text.substr(length))) return truncate.replace(/\s+\S*$/, "...");
    return truncate;
  };
};
