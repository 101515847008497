import { uniq, compact, filter, find, without, union, first } from "underscore";

export const Seeds = function () {

  function parseNegative(string) {
    var replaced;

    replaced =   string.replace(/ -/g, ',-');
    replaced = replaced.replace(/(^-\S+)/g, '$1,');
    replaced = replaced.replace(/,-(\S+)/g, ',-$1,');

    return replaced;
  }

  function removeMalformed(string, allowMultiple) {
    var cleaned = string.split(/\, ?/).map(function(string) {
      var newString = string.trim().replace(/[ ]+/g,' ').toLowerCase();
      // if (newString.length > 0 && !!newString.match(/[a-zA-Z0-9]+/)) return newString;
      if (newString.length > 0) return newString;
    });
    return allowMultiple ? first(compact(cleaned), 10).join(", ") : compact(cleaned)[0];
  }

  function dedup(string) {
    return uniq(string.split(/\, ?/)).join(", ");
  }

  function noNegatives(string) {
    return string.replace(/^-(\S+)/g, '$1');
  }

  function parseSearchText(string, allowMultiple) {

    if (!string) return null;
    let properCommas     = parseNegative(string);
    let cleaned          = removeMalformed(properCommas, allowMultiple);
    let deduped          = dedup(cleaned);
    let noMinuses        = noNegatives(deduped);
    return noMinuses;
  }

  function extractPositive (string) {
    if (!string) return null;
    return filter(string.split(/, ?/g), function (seed) {
      return !seed.charAt(0).match('-');
    });
  }

  function extractNegative (string) {
    if (!string) return null;
    return filter(string.split(/, ?/g), function (seed) {
      return seed.charAt(0).match('-');
    });
  }

  function getErrors (object) {
    var errors = [];
    var positiveShort, negativeShort;

    negativeShort = find(object.negative, function(seed) { return (seed && seed.length == 2); });

    if (object.source === 'wordtracker') {
      positiveShort = find(object.positive, function(seed) { return (seed && seed.length < 2); });
      if (positiveShort || negativeShort) errors.push("Please ensure your search terms contain at least two characters.");
    }
    
    if (object.api.length === 0) errors.push("Enter a valid search term to begin.");

    return errors;
  }

  // INIT
  function _Seeds(searchText, source) {

    var allowMultiple = (source === 'google' || source === 'ranking');

    var object = {
      searchText: parseSearchText(searchText, allowMultiple),
      source: source,
      positive: null,
      negative: null,
      api: null,
      errors: [],
      isValid: function() {
        return this.errors.length === 0;
      },
      removeSeed: function(seed) {
        this.positive = without(this.positive, seed);
        this.negative = without(this.negative, seed);
        this.api = union(this.positive, this.negative);
        var seeds = this.searchText.split(", ");
        seeds = without(seeds, seed);
        this.searchText = seeds.join(", ");
      },
      addSeed: function(seed) {
        this.positive.push(seed);
        this.api = union(this.positive, this.negative);
        this.searchText = this.searchText + ", " + seed;
      },
    };

    object.positive = extractPositive(object.searchText);
    object.negative = extractNegative(object.searchText);
    object.api      = union(object.positive, object.negative);

    object.errors = getErrors(object);

    return object;

  }

  return _Seeds;
};
