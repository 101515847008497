import "./domainBug.scss";

class _Controller {

  constructor($sharedCookies, $timeout) {
    this.$sharedCookies = $sharedCookies;
    this.$timeout = $timeout;
    this.showBugText = false;
  }

  $onInit() {
    if (!this.$sharedCookies.get('seen_domain_bug')) {
      this.$timeout(() => this.openDomainBug(), 3000);
    }
  }

  openDomainBug() {
    this.showBugText = true;
    this.$sharedCookies.set('seen_domain_bug', true);
  }

  closeDomainBug() {
    this.showBugText = false;
  }

}

_Controller.$inject = ['$sharedCookies', '$timeout'];

export const domainBug = {
  controller: _Controller,
  template: require('./domainBug.html')
}
