import { domain } from "./domain.component.js";
import { competitors } from "./competitors/competitors.component";
import { domainBug } from "./domain_bug/domainBug.component";
import { pageMetrics } from "./page_metrics/pageMetrics.component";
import { domainKeywordsTabs } from "./domain_keywords_tabs/domainKeywordsTabs.component";
import { domainKeywords } from "./domain_keywords/domainKeywords.component";

export const DOMAIN_MODULE = angular.module('domainModule', []);

export const domainState = {
  parent: 'app',
  name: 'domain',
  title: 'PRE-SET',
  section: 'domain',
  url: '/domain',
  component: 'domain',
  resolve: {
    cable: ['$domainSearchSettings', '$activeColumns', function ($domainSearchSettings, $activeColumns) {
      $activeColumns.loadDomain();
      return true;
    }]
  }
};

DOMAIN_MODULE.config(['$stateRegistryProvider', function($stateRegistry) {
  $stateRegistry.register(domainState);
}]);


DOMAIN_MODULE.component('domain', domain);
DOMAIN_MODULE.component('competitors', competitors);
DOMAIN_MODULE.component('domainBug', domainBug);
DOMAIN_MODULE.component('pageMetrics', pageMetrics);
DOMAIN_MODULE.component('domainKeywordsTabs', domainKeywordsTabs);
DOMAIN_MODULE.component('domainKeywords', domainKeywords);