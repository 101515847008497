import "./advancedSearchOptions.scss";

class _Controller {

  constructor($searchSettings, UserService) {
    this.$searchSettings = $searchSettings;
    this.user = UserService.user;
    this.show = false;
  }

  showAdvanced() {
    this.cachedSettings = angular.copy(this.$searchSettings.searchParams);
    this.show = true;
  }

  hideAdvanced() {
    this.evaluateIfSettingsChanged();
    this.show = false;
  }

  toggleAdvanced() {
    (this.show) ? this.hideAdvanced() : this.showAdvanced();
  }

  evaluateIfSettingsChanged() {
    if (!angular.equals(this.cachedSettings, this.$searchSettings.searchParams)) {
      this.$searchSettings.setChanged(true);
    }
  }
}

_Controller.$inject = ['$searchSettings', 'UserService'];

export const advancedSearchOptions = {
  controller: _Controller,
  template: require('./advancedSearchOptions.html')
};
