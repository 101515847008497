$.fn.selectRange = function(start, end) {
  return this.each(function() {
    if (typeof end == "undefined") {
      end = start;
    }
    if (start == -1) {
      start = this.value.length;
    }
    if (end == -1) {
      end = this.value.length;
    }
    if (this.setSelectionRange) {
      this.focus();
      this.setSelectionRange(start, end);
    }
    else if (this.createTextRange) {
      var range = this.createTextRange();
      range.collapse(true);
      range.moveEnd('character', end);
      range.moveStart('character', start);
      range.select();
    }
  });
};

export const $dropdownObject = function($timeout, $handleKeyPress, $filter){
  return function (scope, element, options) {

    if (options) {
      scope.greedy = options.greedy;
      scope.forceSelect = options.forceSelect;
    }

    scope.searchText = '';

    scope.searchTextCache = '';

    scope.cancelled = false;

    scope.candidate = scope.selectedItem || null;
    scope.isOpen = scope.isOpen || false;

    var input = $('input', element);

    scope.open = function() {
      scope.isOpen = true;
    };

    scope.close = function() {
      $timeout(function(){
        scope.isOpen = false;
      });
    };

    scope.focusInput = function() {
      scope.isOpen = true;
      $timeout(function() {
        input.focus();
      });
    };

    scope.keyboarder = {

      up: function($event) {
        $event.preventDefault();
        var newIdx = scope.matches.indexOf(scope.candidate) - 1;
        var setting = (newIdx >= 0) ? scope.matches[newIdx] : null;
        if (setting) {
          scope.setCandidate(setting);
        } else {
          if (!scope.forceSelect) {
            scope.candidate = null;
            var endPos = input.getSelection().start;
            input.val(input.val().substring(0, endPos));
          }
        }
      },

      down: function($event) {
        $event.preventDefault();
        var newIdx = scope.matches.indexOf(scope.candidate) + 1;
        if (!scope.candidate) {
          scope.setCandidate(scope.matches[0]);
        } else if (newIdx < scope.matches.length) {
          scope.setCandidate(scope.matches[newIdx]);
        }

      },

      enter: function($event) {
        $event.preventDefault();
        scope.setSelectedItem(scope.candidate);
      },

      backspace: function($event) {
        if (scope.greedy) {
          scope.candidate = null;
          scope.cancelled = true;
        }
      },

      delete: function($event) {
        this.backspace();
      },

      escape: function($event) {
        $event.preventDefault();
        scope.blur();
      }

    };

    scope.keypress = function($event) {
      $handleKeyPress.delegate($event, scope.keyboarder);
    };

    scope.updateRegex = function(text) {
      scope.regex = new RegExp(text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), 'i');
    };

    scope.isCandidate = function(item) {
      return scope.candidate == item;
    };

    scope.handleScroll = function() {
      var scroll = angular.element('.autocomplete-scroller', element);
      var selected = angular.element('.ac_selected', scroll);
      var viewPortHeight = scroll.outerHeight();
      var bottomOfSelected = selected.position().top + selected.outerHeight();
      var bottomOfViewPort = scroll.scrollTop() + viewPortHeight;
      if (bottomOfSelected > bottomOfViewPort) {
        scroll.scrollTop(bottomOfSelected - viewPortHeight - selected.outerHeight());
      }
    };

    scope.setCandidate = function(value) {
      scope.candidate = value;
      // $timeout(function() {
      //   handleScroll();
      // });
    };

    scope.autoComplete = function() {

      if (!!scope.candidate) {

        var candidate = scope.candidate.hasOwnProperty("name") ? scope.candidate.name : scope.candidate;

        var typedPos = scope.searchText.length;
        if (candidate.substring(0,typedPos).toLowerCase() === scope.searchText.toLowerCase()) { // Haven't we already got regex for this?
          var endPos = candidate.length;
          var newVal = scope.searchText.substring(0, typedPos) + candidate.substring(typedPos, endPos);
          input.val(newVal);
          input.selectRange(typedPos, endPos);
        }
      }
    };

    scope.validateCandidate = function() {
      if (scope.items.indexOf(scope.candidate) < 0) scope.candidate = null;
    };

    scope.hasSearchText = function() {
      return (scope.searchText && scope.searchText.length > 0);
    };

    scope.itemMatchesSearch = function(item) {
      if (!item) return false;
      item = item.hasOwnProperty('name') ? item.name : item;
      return (!scope.searchText || !!item.match(scope.regex));
    };

    scope.updateResults = function () {
      var typed = (scope.searchText.length > scope.searchTextCache.length);
      scope.updateRegex(scope.searchText);
      if (scope.hasSearchText()) {
        scope.matches = $filter('orderBy')(scope.items.filter(scope.itemMatchesSearch));
        if (typed) {
          scope.setCandidate(scope.matches[0]);
          if (scope.greedy) scope.autoComplete();
          scope.validateCandidate(); // Do we even need this any more?
        } else {
          scope.candidate = null;
        }
      } else {
        scope.matches = scope.items;
        scope.candidate = null;
      }
      scope.searchTextCache = scope.searchText;
    };

    scope.setSelectedItem = function(item) {
      scope.selectedItem = item;
      scope.isOpen = false;
      scope.callback();
    };

    return scope;
  };
};

$dropdownObject.$inject = ['$timeout', '$handleKeyPress', '$filter'];
