export const $mocks = function(UserService) {
  return {
    lists: {
      shouldMock: () => UserService.user.isFreeUser,
      methods: {
        query: () => [],
        keywords: (params) => ({id: params.id, keywords: []}),
        save: (params, payload) => ({id: 1, ...payload.list}),
        update: (params, payload) => ({id: params.id, ...payload})
      }
    },
    projects: {
      shouldMock: () => UserService.user.isFreeUser,
      methods: {
        query: () => [],
      }
    },
    keywords: {
      shouldMock: () => UserService.user.isFreeUser,
      methods: {
        import: () => ({import: true}),
        delete: () => (null)
      }
    }
  }
};

$mocks.$inject = ['UserService'];
