class Controller {
  constructor() {}
}

export const planPricing = {
  controller: Controller,
  template: `

<table class="pricing">
 <thead>
   <tr class="row-plan">
     <th class="title-col">Plan</th>
     <th>Standard</th>
     <th class='gold'>Custom</th>
   </tr>
 </thead>
 <tbody>
   <tr>
     <td class="title-col"><strong style='font-size: 18px;'>Monthly price</strong></td>
     <td><strong style='font-size: 18px;'>$20</strong></td>
     <td class='gold'><strong style='font-size: 18px;'>$500+</strong></td>
   </tr>
   <tr>
     <td class="title-col">Calls included with your plan</td>
     <td>100</td>
     <td class='gold'>50,000+</td>
   </tr>
   <tr>
     <td class="title-col">Cost of additional calls (overage)</td>
     <td>$0.05</td>
     <td class='gold'>Contact us</td>
   </tr>
  </tbody>
</table>`
};