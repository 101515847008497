const ratings = ["Very poor", "Poor", "Fair", "Good", "Excellent"];
const ratingsDifficulty = ["Very easy", "Easy", "Fair", "Hard", "Very hard"];

const toRating = (number, scale, ratings) => {
  for (let i = 0; i < scale.length; i++) {
    let lower = scale[i];
    let upper = scale[i + 1] || 0;
    if (number >= lower && number < upper) return ratings[i];
  }
  return ratings[4];
};

export const metricRating = function () {
  return function(number, metricName) {
    if (!number) return "";
    let scale = [0, 20, 40, 60, 80, 100];
    let _ratings = (metricName === "OverallScore") ? ratingsDifficulty : ratings;
    return toRating(number, scale, _ratings);
  };
};