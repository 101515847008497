import "./nicheExplorer.scss";

class Controller {
  constructor($countryNames, $resourceManager, $notifier, $location, $timeout, $limits) {

    this.$resourceManager = $resourceManager;
    this.$notifier = $notifier;
    this.$location = $location;
    this.$timeout = $timeout;
    this.showButton = true;
    this.$limits = $limits;
    this.countries = [];
    this.zuul_url = __ZUUL_URL;
    
    // const countries = ["global", ...$limits.countries.clickstream.available];
    const countries = ["global", "US", "GB", "CA", "AU", "NZ"];
    
    this.countries = countries.reduce((array, countryCode) => {
      array.push({ code: countryCode, name: $countryNames[countryCode] })
      return array;
    }, [])

    this.sources = ["google", "amazon", "youtube", "ebay"]

    this.options = {
      source: null,
      country: null,
      category: null,
    }

  }

  setSource(source) {
    this.options.source = source;
  }

  setCountry(country) {
    this.options.country = country.code;
  }

  setCategory(category) {
    this.options.category = category.value;
  }

  createGeneratedPage(token, params) {
    params.token = token;
    this.$resourceManager.request('generated_pages', 'save', null, params).then(res => {
      this.$notifier.green('Generating report...');
      const { source, country, category } = this.options;
      this.$timeout(() => {
        window.location.pathname = `/niche-explorer/${source}/${country.toLowerCase()}/${category}`;
      }, 2000);
    }).catch(err => {
      this.$notifier.red('Something went wrong.');
      console.error(err);
      this.showButton = true;
    });
  }

  generateReport() {
    this.showButton = false;
    let params = angular.copy(this.options);
    this.recaptcha_widget = grecaptcha.render('recaptcha_element', {
      sitekey: __WORDTRACKER_RECAPTCHA_SITE_KEY,
      callback: token => this.createGeneratedPage(token, params)
    });
  }

}

Controller.$inject = ['$countryNames', '$resourceManager', '$notifier', '$location', '$timeout', '$limits'];

export const nicheExplorer = {
  controller: Controller,
  template: require('./nicheExplorer.html'),
  bindings: {
    'categories': '<'
  }
};