export const $domainSearchSettings = function($cableConnection) {
  var settings = {
    searchParams: {
      token: null,
      site: null,
      country: 'US',
      session_uid: $cableConnection.sessionUid
    },
    source: 'google'
  };

  return settings;
};

$domainSearchSettings.$inject = ['$cableConnection'];
