import { extend } from "underscore";

export const SearchSerp = function($searchAbstract) {
  function _SearchSerp (searchSettings) {
    var payload = function () {
      var _payload = {};
      extend(_payload, searchSettings.searchParams);
      _payload.seeds = searchSettings.activeSeed;
      return _payload;
    };

    var search = $searchAbstract('serp', 'google', 'serps', payload);

    search.store = function () {
      return {
        results: null,
        loading: true,
        failed: false,
        fail: function () {
          this.loading = false;
          this.failed = true;
        },
        addFromCable: function (response) {
          this.loading = false;
          this.results = response;
        }
      };
    };

    return search;
  }

  return _SearchSerp;
};

SearchSerp.$inject = ['$searchAbstract'];
