import "./seo.scss";

class SeoController {

  constructor($resourceManager, $notifier, $timeout, Modal) {
    this.prices = [{
      id: "small",
      title: "Small Sites",
      description: "Typically those promoting a single product or service.",
      price: "$1,500 - $2,000",
      image: require("./small-house.png"),
    },{
      id: "medium",
      title: "Medium Sites",
      description: "Small e-commerce stores and affiliate sites.",
      price: "$2,000 - $3,000",
      image: require("./medium-house.png"),
    },{
      id: "large",
      title: "Large Sites",
      description: "Those with large amounts of content & products.",
      price: "$3,000 - $5,000",
      image: require("./large-house.png"),
    }];

    this.$resourceManager = $resourceManager;
    this.$notifier = $notifier;
    this.$timeout = $timeout;
    this.Modal = Modal;
    this.errors = {};

  }
  
  $onInit() {
    this.resetForm();
    this.loadRecaptcha();
    this.bindScrollTo();
  }

  bindScrollTo() {
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .on('click', function(event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && 
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 1000, function() {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            };
          });
        }
      }
    });
  }

  loadRecaptcha() {
    if (typeof grecaptcha.render !== 'function') {
      return this.$timeout(this.loadRecaptcha.bind(this), 250);
    }
    this.recaptchaNow = grecaptcha.render('recaptcha-element', {
      sitekey: __WORDTRACKER_INVISIBLE_RECAPTCHA_SITE_KEY,
      callback: this.submitForm.bind(this)
    });
  }

  validate() {
    this.errors = {};
    const { email, name, website, message } = this.enquiry;
    Object.entries({email, name, website, message}).forEach(([key,value]) => {
      if (!value || value && value.length === 0) {
        this.errors[key] = "required";
      }
    })
    if (Object.keys(this.errors).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  resetForm() {
    this.enquiry = {
      email: null,
      name: null,
      website: null,
      message: null
    }
    this.resetButton();
    this.errors = {};
  }

  resetButton() {
    this.loading = false;
    this.complete = false;
  }
  
  preSubmit() {
    if (!this.validate()) return false;

    this.loading = true;
    grecaptcha.execute(this.recaptchaNow);
  }

  submitForm(token) {
    const { email, name, website, message } = this.enquiry;
    const payload = { email, name, website, message, token }
    this.$resourceManager.request('communications', 'save', null, {type: 'seo', ...payload}).then(() => {
      this.complete = true;
      this.$notifier.green("Enquiry received. We'll be in touch soon.")
    }).catch(err => {
      console.error(err);
      this.$notifier.red("Sorry, something went wrong.")
      this.resetButton();
      grecaptcha.reset();
    });
  }

}

SeoController.$inject = ['$resourceManager', '$notifier', '$timeout', 'Modal'];

export const seo = {
  controller: SeoController,
  template: require('./seo.html')
};