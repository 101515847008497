class Controller {
  constructor() {
    this.code = `#!/bin/bash\n
curl -X GET -G \\
  'https://api.lc.wordtracker.com/v3/search?app_id=APP_ID&app_key=APP_KEY' \\
  -d seeds=firefox \\
  -d engine=google \\
  -d country=us
  -d type=broad

{
  "results": [
    {
      "keyword": "firefox",
      "total_volume": 25258,
      "avg_volume": 2105,
      "volume_by_month": [
        {
          "volume": 2336,
          "year": "2018",
          "month": "01"
        },
        ...
      ],
      "panelist_ids_by_month": [
        {
          "ids": 1440,
          "year": "2018",
          "month": "03"
        },
        ...
      ],
      "total_months_seen": 12
    },
    {
      "keyword": "mozilla firefox",
      "total_volume": 4013,
      "avg_volume": 334,
      "volume_by_month": [
        {
          "volume": 476,
          "year": "2018",
          "month": "03"
        },
        ...
      ],
      "panelist_ids_by_month": [
        {
            "ids": 296,
            "year": "2018",
            "month": "03"
        },
        ...
      ],
      "total_months_seen": 12
    },
    ...
  ],
  "params": {
      "engine": "google",
      "country": "us",
      "seeds": [
          "firefox"
      ],
      "limit": 10,
      "type": "broad",
      "sort": "total"
  }
}`;
    this.codeOptions = {
      lineWrapping : true,
      lineNumbers: true,
      readOnly: 'nocursor',
      mode: 'shell',
      theme: 'monokai'
    };
  }

}

export const apiPage = {
  controller: Controller,
  template: require('./apiPage.html')
};