export const SearchRanking = function($searchAbstract, $keywordsCollection, $resultsStore) {
  function _RankingSearch (rankingSearchSettings) {
  
    var payload = function () {
      var _payload = {};
      _.extend(_payload, rankingSearchSettings.searchParams);
      _payload.site = rankingSearchSettings.searchParams.site;
      _payload.keywords = rankingSearchSettings.searchParams.keywords;
      _payload.method = 'ranking';
      return _payload;
    };

    let search = $searchAbstract('ranking', 'ranking', 'ranking', payload, null, false);

    return search;
  }

  return _RankingSearch;
};

SearchRanking.$inject = ['$searchAbstract', '$keywordsCollection', '$resultsStore'];
