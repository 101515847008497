import './domainKeywordsTabs.scss';

class _Controller {
  constructor($resultsStore, $domainSearchSettings) {
    this.results = $resultsStore;
    this.$domainSearchSettings = $domainSearchSettings;
    this.tabTitles = {
      'clicks': { long: 'Keywords driving traffic', short: 'Driving traffic'},
      'clicks_change': { long: 'Rising keywords', short: 'Rising keywords'},
      'top_keywords': { long: 'Popular keywords on page', short: 'Popular keywords'}
    };
    this.icons = {
      'clicks': 'fa-bullseye',
      'clicks_change': 'fa-fire',
      'top_keywords': 'fa-magnifying-glass'
    }
    this.activeTab = 'clicks';
  }

  setActiveTab(tab) {
    this.activeTab = tab;
  }

  getCollectionIsLoading(tabId) {
    switch(tabId) {
      case 'clicks':
        return this.results.keywords_for_domain_by_clicks.loading;
      case 'clicks_change':
        return this.results.keywords_for_domain_by_clicks_change.loading;
      case 'top_keywords':
        return this.results.site_report.topKeywords.loading;
      default:
        return false;
    }
  }

}

_Controller.$inject = ['$resultsStore', '$domainSearchSettings'];

export const domainKeywordsTabs = {
  bindings: {},
  controller: _Controller,
  template: require('./domainKeywordsTabs.html')
}
