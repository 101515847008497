class _Controller {

  constructor($lists, $listNavigation, $transclude) {
    this.fileNav = $listNavigation;
    this.$transclude = $transclude;
    this.$lists = $lists;
    this.lists = $lists.lists;
    this.projects = $lists.projects;
    this.tree = $lists.tree;
    $lists.initialize();
  }

  $onInit() {
    this.hasPlaceholder = this.$transclude.isSlotFilled('placeholder');
  }

  setOrderBy(column) {
    this.fileNav.orderBy.setOrderBy(column);
  }

}

_Controller.$inject = ['$lists', '$listNavigation', '$transclude'];

export const listTree = {
  transclude: {
    placeholder: '?placeholder'
  },
  controller: _Controller,
  bindings: {
    type: '<'
  },
  template: require('./listTree.html')
};
