export const $handleKeyPress = function() {
  var KEYS = {
    8:  'backspace',
    9:  'tab',
    13: 'enter',
    27: 'escape',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
    46: 'delete'
  };

  var handleKeyPress = {

    delegate: function($event, handler) {
      var keyCode = $event.keyCode;
      if (KEYS.hasOwnProperty(keyCode) && handler.hasOwnProperty(KEYS[keyCode])) {
        handler[KEYS[keyCode]]($event);
        return true;
      } else {
        return false;
      }
    }

  };

  return handleKeyPress;
};
