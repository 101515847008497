import './callout.scss';

export const callout = function ($timeout, $compile) {
  return {
    restrict: 'A',
    scope: {
      callout: '@',
      calloutDirection: '@',
      calloutBehaviour: '@',
      calloutShow: '<'
    },
    link: function (scope, element) {

      scope.calloutDirection = scope.calloutDirection || 'bottom';
      scope.show = false;
      scope.isDisabled = null;
      var template = "<div class='callout {{calloutDirection}}' ng-click='hideCallout($event)' ng-show='show' ng-bind-html='callout'></div>";

      var input = element.find('> [ng-disabled]');

      $timeout(function() {
        var calloutElement = $compile(template)(scope);
        element.append(calloutElement);
      });

      scope.$watch(function() {
        return input.attr('disabled');
      }, function(disabled){
        scope.isDisabled = !!disabled;
      });

      scope.hideCallout = (e) => {
        e.preventDefault();
        scope.show = false;
      };

      if (scope.calloutBehaviour === 'disabled hover') {
        $(element).parent().on({
          mouseenter: () => {
            $timeout(() => { scope.show = scope.isDisabled; });
          },
          mouseleave: () => {
            $timeout(() => { scope.show = false; });
          },
        }, element);
      }

      if (scope.calloutBehaviour === 'show') {
        scope.$watch('calloutShow', () => {
          scope.show = scope.calloutShow;
        });
      }

      if (scope.calloutBehaviour === 'hover') {
        $(element).hover(function(){
          $timeout(function() { scope.show = true; });
        }, function(){
          $timeout(function() { scope.show = false; });
        });
      }

      if (scope.calloutBehaviour === 'disabled click') {
        var timer;
        $(element).click(function(){
          $timeout.cancel(timer);
          $timeout(function() {
            scope.show = scope.isDisabled;
            timer = $timeout(function() { scope.show = false; }, 1500);
          });
        });
      }

    }
  };
};

callout.$inject = ['$timeout', '$compile'];
