const DICTIONARY = {
  google: "Google",
  google_raw: "Google",
  google_g: "Google (New)",
  google_blend: "Google (Blend)",
  amazon: "Amazon",
  youtube: "YouTube",
  ebay: "eBay",
  wordtracker: "Wordtracker",
};

export const sourceName = function (UserService) {
  return function(input, plainGoogle) {
    if (DICTIONARY.hasOwnProperty(input)) {
      if (plainGoogle && input === "google") return "Google"; 
      return DICTIONARY[input];
    } else {
      return input;
    }
  };
};

sourceName.$inject = ['UserService'];