import { getField } from '../../../shared/fields';

const keywordMatchesText = (keyword, value) => {
  if (value === '' || !value) return true
  return !!keyword.match(value.toLowerCase())
}

const isQuestion = ({keyword}) => {
  const regex = /\bam\b|\bany\b|\bare\b|\bcan\b|\bcould\b|\bcouldn't\b|\bdid\b|\bdidn't\b|\bdo\b|\bdoes\b|\bdoesn't\b|\bdon't\b|\bhad\b|\bhas\b|\bhasn't\b|\bhave\b|\bhelp\b|\bif\b|\bis\b|\bisn't\b|\bmay\b|\bmust\b|\bname\b|\bshould\b|\bshouldn't\b|\bwas\b|\bwasn't\b|\bwere\b|\bwhat's\b|\bwhich\b|\bwhose\b|\bwill\b|\bwould\b|\bwouldn't\b|\bwho\b|\bwhat\b|\bwhy\b|\bwhere\b|\bwhen\b|\bhow\b/
  return !!keyword.match(regex)
}

const isGSCKeyword = (keyword) => {
  return keyword.hasOwnProperty('ctr') && keyword.ctr != null
}

const isIncluded = ({keyword}, {values}) => {
  return values.some(value => keywordMatchesText(keyword, value))
}

const isExcluded = ({keyword}, {values}) => {
  return values.every(value => !keywordMatchesText(keyword, value))
}

const isMetricValid = (keyword, {type, filter: {min, max}}) => {
  let value = keyword[type];
  return (value >= min && value <= max)
}

const keywordIsValid = (keyword, filters) => {
  return Object.entries(filters).every(([type, filter]) => {
    switch (type) {
      case 'gsc':      return isGSCKeyword(keyword)
      case 'question': return isQuestion(keyword)
      case 'includes': return isIncluded(keyword, filter)
      case 'excludes': return isExcluded(keyword, filter)
      default:         return isMetricValid(keyword, {type, filter})
    }
  })
}

export const getFilteredKeywords = (keywords, filters, textFilters) => {
  const _filters = {...filters}

  textFilters.forEach(({type, value}) => {
    const filter = _filters[type] || {values: []}
    filter.values.push(value)
    _filters[type] = filter
  })

  return (Object.keys(_filters).length > 0)
    ? keywords.filter(keyword => keywordIsValid(keyword, _filters))
    : keywords
}