import './domainKeywords.scss';

class _Controller {

  constructor($domainSearchSettings, $listManagementNotifier, $activeList, $upsells, $limits, $saveToList, UserService, $resultsStore, $lists) {
    this.$domainSearchSettings = $domainSearchSettings;
    this.$listManagementNotifier = $listManagementNotifier;
    this.$activeList = $activeList;
    this.$saveToList = $saveToList;
    this.$upsells = $upsells;
    this.$limits = $limits;
    this.results = $resultsStore;
    this.isFreeUser = UserService.user.isFreeUser;
    this.$lists = $lists;
  }

  saveKeywordsToActiveList(keywords) {
    this.$listManagementNotifier.pending('Saving...');
    this.$activeList.saveKeywordsToList(keywords).then(() => {
      this.$listManagementNotifier.success('Saved');
    });
  }

}

_Controller.$inject = ['$domainSearchSettings', '$listManagementNotifier', '$activeList', '$upsells', '$limits', '$saveToList', 'UserService', '$resultsStore', '$lists'];

export const domainKeywords = {
  bindings: {
    collection: '<',
    locationKey: '@',
    sortField: '@',
    explainer: '@'
  },
  controller: _Controller,
  template: require('./domainKeywords.html')
}
