import { sendTriggerUpsellEvent } from "../shared/events";

import "./app.scss";

class AppController {
  constructor($meta, $notifier, $limits, $window, UserService, $resourceManager, Modal, $tokenCatcher, $location, $sharedCookies, $timeout) {
    this.meta = $meta;
    this.$notifier = $notifier;
    this.$limits = $limits;
    this.$window = $window;
    this.$location = $location;
    this.zuul_url = __ZUUL_URL;
    this.isDevelopment = (typeof __IS_PRODUCTION === 'undefined' || (typeof __IS_PRODUCTION !== 'undefined' && !__IS_PRODUCTION) || (typeof __IS_STAGING != 'undefined' && !!_IS_STAGING));
    this.user = UserService.user;
    this.isFreeUser = UserService.user.isFreeUser;
    this.$resourceManager = $resourceManager;
    this.doShowBug = true;
    this.$tokenCatcher = $tokenCatcher;
    this.Modal = Modal;
    this.$sharedCookies = $sharedCookies;
    this.$timeout = $timeout;
    this.showUpgradeBug = !!this.$sharedCookies.get('closed_upgrade_now_banner');
    this.canPurchase = (
      !this.user.subscription ||
      this.user.isExpiredUser ||
      (
        this.user.current1dollarTrial &&
        !this.user.hasUpcomingSub
      )
    );
  }

  $onInit() {
    
    // If user is locked, force user to /search and display locked message.
    if (this.user && !this.isFreeUser && this.user.locked) {
      this.$state.target('home');
    }

    // Set coupon
    const params = this.$location.search();
    if (params.coupon) {
      this.$sharedCookies.set('coupon', params.coupon);
    }
    
    if (params.offer) {
      this.$timeout(this.goToSignUp.bind(this), 1000);
    }

    if (this.$tokenCatcher.suspended) {
      this.Modal.new({
        component: 'suspendedAccountModal',
        dismissable: false
      })
    }

  }

  goToSignUp() {
    this.$location.removeParams("offer");
    sendTriggerUpsellEvent();
  }
  
  goToAccountSubscription() {
    this.$window.location.href = `${__ZUUL_URL}/account/subscription`
  }

  upgrade() {
    sendTriggerUpsellEvent();
  }

}


AppController.$inject = ['$meta', '$notifier', '$limits', '$window', 'UserService', '$resourceManager', 'Modal', '$tokenCatcher', '$location', '$sharedCookies', '$timeout'];

export const app = {
  bindings: { user: '<' },
  controller: AppController,
  template: require('./app.html')
};