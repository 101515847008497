import { searchFields, listFields, domainFields } from '../../shared/fields'

import "./metricColumnSelector.scss"

class Controller {
  constructor(UserService, $activeColumns, Modal, $searchSettings, $timeout, $upsells) {
    this.user = UserService.user;
    this.$activeColumns = $activeColumns;
    this.$upsells = $upsells;
    this.searchSettings = $searchSettings;
    this.Modal = Modal;
    this.activeCols = $activeColumns.active;
    this.$timeout = $timeout;
    this.ui = {
      showAdvanced: false
    };
    this.maxActiveCols = 6;
  }

  $onInit() {
    this.availableCols = {
      'list': listFields,
      'search': searchFields,
      'domain_keywords_by_clicks': domainFields,
      'domain_keywords_by_clicks_change': domainFields,
      'domain_keywords_by_volume': searchFields,
    }[this.location];
  }

  promptGSC() {
    if (this.user.isFreeUser) {
      this.$upsells.new('gsc_data')
    } else {
      this.Modal.new({
        component: 'gscModal',
        dismissable: true,
        bindings: {
          title: "Connect to Google",
          description: "You can get <strong>Google Search Console (GSC) data</strong> right in your Wordtracker search results.<br><br>Simply click below to hook your account to GSC!",
          action_uri: __ZUUL_URL + '/account/integrations',
          action_text: 'Connect'
        },
      })
    }
  }

  isChecked(col) {
    return this.$activeColumns.isChecked(col, this.location);
  }

  toggleCheck(col, e) {
    if ((!this.user.gsc_has_access_token && col.heading.match("GSC"))) {
      e.preventDefault();
      return this.promptGSC();
    }
    this.$activeColumns.toggleCheck(col, this.location);
  }

  showAdvanced() {
    this.$timeout(() => this.ui.showAdvanced = true );
  }

  hideAdvanced() {
    this.$timeout(() => this.ui.showAdvanced = false );
  }

  toggleAdvanced() {
    (this.ui.showAdvanced) ? this.hideAdvanced() : this.showAdvanced();
  }

  orderAvailableColsFn(col) {
    return col.notAvailableFor && col.notAvailableFor.includes(this.searchSettings.source) ? 1 : -1;
  }

}

Controller.$inject = ['UserService', '$activeColumns', 'Modal', '$searchSettings', '$timeout', '$upsells'];

export const metricColumnSelector = {
  controller: Controller,
  bindings: {
    location: '@'
  },
  template: require('./metricColumnSelector.html')
}