import { sendTriggerUpsellEvent } from '../shared/events';

class _Controller {

  constructor($upsells, $limits, $saveToList, $lists) {
    this.$saveToList = $saveToList;
    this.$upsells = $upsells;
    this.$limits = $limits;
    this.$lists = $lists;
  }

  async newTrack(collection) {

    await this.$lists.initialize();

    const incrementOnDomain = (sum, list) => sum + (list.domain ? 1 : 0);
    const rankingListsUsed = this.$lists.lists.reduce(incrementOnDomain, 0);
    const rankingListsAvailable = Math.max(this.$limits.raw.ranking_lists - rankingListsUsed, 0);

    if (rankingListsAvailable < 1) {
      return sendTriggerUpsellEvent({limit: 'ranking_lists', requested: rankingListsUsed + 1});
    };

    this.$saveToList.newTrack(collection, this.domain, this.country);

  }

}

_Controller.$inject = ['$upsells', '$limits', '$saveToList', '$lists'];

export const trackButton = {
  bindings: {
    collection: '<',
    country: '<',
    domain: '<'
  },
  controller: _Controller,
  template: `
    <button class='track' ng-show='$ctrl.$limits.cant("ranking")' ng-click='$ctrl.$upsells.new("ranking")'><i class='fa-solid fa-chart-line'></i> Track...</button>
    <button class='track' ng-show='$ctrl.$limits.can("ranking")' ng-click='$ctrl.newTrack($ctrl.collection)' ng-disabled='!$ctrl.collection.keywords || $ctrl.collection.keywords && ($ctrl.collection.getActionable().length < 1)'><i class='fa-solid fa-chart-line'></i> Track...</button>
  `
}