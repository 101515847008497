import { atobUTF8, btoaUTF8 } from "../../../btoa.module";
import { sendTriggerUpsellEvent } from "../../../../shared/events";

class Controller {

  constructor($upsells, $limits, $notifier) {
    this.$upsells = $upsells;
    this.$limits = $limits;
    this.$notifier = $notifier;
  }

  resolve(value) {
    this.close({value});
  }

  fromCSV(csv) {
    let decoded = atobUTF8(_.last(csv.split(";base64,")));
    return decoded.split(/\r\n|\n|\r/).map(seed => seed.toLowerCase());
  };

  createImport() {
    if (!this.bulkSeeds && !this.bulkCSV) return false;
    let seedFile = this.bulkCSV || ("data:text/csv;base64," + btoaUTF8(this.bulkSeeds));
    if (!seedFile) return this.$notifier.red('Please enter some valid seeds.');
    let keywords = this.fromCSV(seedFile);
    keywords = _.uniq(keywords);
    this.resolve(keywords);
  }

}

Controller.$inject = ['$upsells', '$limits', '$notifier'];

export const searchBulkModal = {
  controller: Controller,
  bindings: {
    close: '&',
    title: '<'
  },
  template: `
    <div class='modal-header'>
      <span><i class='fa-regular fa-circle-plus'></i> {{$ctrl.title || "Add keywords..."}}</span>
      <button type='button' class='dismiss-standard' ng-click='$ctrl.resolve(false)'>&times;</button>
    </div>
    <div class="modal-body">
      <div class='form-row'>
        <label class='label'>
          Enter keywords
          <textarea id='bulk-seeds' ng-disabled='!!$ctrl.bulkCSV' ng-model='$ctrl.bulkSeeds' placeholder='Insert or paste keywords here (one per line)' autofocus></textarea>
          <button ng-click='$ctrl.bulkCSV = null' id='clear-file-upload'>Clear</button>
        </label>
      </div>
      <div class='form-row'>
        <label class='label'>
          Or upload a CSV file (no headers, one column only)
          <input type='file' id='bulkCSV' fileread='$ctrl.bulkCSV'>
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <button class='create save-standard' ng-if='$ctrl.$limits.cant("bulk_search")' ng-click='$ctrl.$upsells.new("bulk_search")'>Submit</button>
      <button class='create save-standard' ng-disabled='!$ctrl.bulkSeeds && !$ctrl.bulkCSV' ng-if='$ctrl.$limits.can("bulk_search")' ng-click='$ctrl.createImport()'>Submit</button>
    </div>
  `,
}

