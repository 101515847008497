import "./nicheExplorerShow.scss";

class Controller {

  constructor($signUp, $stateParams, $meta, $countryNames, $location) {

    this.$signUp = $signUp;
    this.$meta = $meta;
    this.engine = $stateParams.engine;
    this.countryCode = $stateParams.country || "US";
    this.country = $countryNames[this.countryCode.toUpperCase()];
    this.$location = $location;
    this.$countryNames = $countryNames;
    this.keyword = $stateParams.keyword;
    this.zuul_url = __ZUUL_URL;

    const d = new Date();
    let monthInt = d.getMonth() - 1;
    if (monthInt == -1) monthInt = 11;

    const date = new Date(d.getFullYear(), monthInt, 15);
    this.month = date.toLocaleString('en-us', { month: 'long' });
    this.year = d.getFullYear();

  }

  $onInit() {
    this.$meta.setTitle(`Top 100 keywords for ${_.capitalize(this.engine)}, ${this.country} | Wordtracker`);
    this.$meta.setDescription(`Here are Wordtracker's top 100 keywords for ${_.capitalize(this.engine)}, ${this.country}`);
  }

}

Controller.$inject= ['$signUp', '$stateParams', '$meta', '$countryNames', '$location'];

export const nicheExplorerShow = {
  controller: Controller,
  template: require('./nicheExplorerShow.html')
};