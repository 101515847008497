export const $resultsStore = function () {
  var resultsStore = {
    main: null,
    serps: null,
    matrix: null,
    datamuse: null,
    youtube: null,
    amazon: null,
    headline: null,
    chart: null,
    site_report: null,
    currentList: null,
    ranking: null,
    keywords_for_domain_by_clicks: null,
    keywords_for_domain_by_clicks_change: null,
  };

  return resultsStore;
};
