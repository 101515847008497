import "./stripeCardField.scss"

export const stripeCardField = {
  template: `
    <div class="form-row">
      <label ng-class='{"has_error": !!$ctrl.error}'>
        <span ng-transclude></span>
        <span class='error fade-down' ng-if='$ctrl.error'>({{$ctrl.error}})</span>
        <div id="stripe-card-element"></div>
      </label>
    </div>
  `,
  transclude: true,
  bindings: {
    error: '='
  }
}