import "./reportCombinerGraphic.scss"

export const reportCombinerGraphic = {
  template: `
    <div id="combiner-image-holder">
      <div class="tick" ng-class="{done: $ctrl.leftDone}"></div>
      <div class="tick" ng-class="{done: $ctrl.middleDone}"></div>
      <div class="tick" ng-class="{done: $ctrl.rightDone}"></div>
      <svg id='combiner-image' xmlns="http://www.w3.org/2000/svg" width="535" height="174" viewBox="0 0 535 174">
        <g>
          <path ng-class="{done: $ctrl.leftDone}" d="M8.5,9.5V63.6113A22.8887,22.8887,0,0,0,31.3887,86.5H180.6113A22.8887,22.8887,0,0,1,203.5,109.3887V164.5"/>
          <line ng-class="{done: $ctrl.middleDone}" x1="267" y1="9.5" x2="267" y2="164.5"/>
          <path ng-class="{done: $ctrl.rightDone}" d="M525.5,9.5V63.6113A22.8887,22.8887,0,0,1,502.6113,86.5H353.3887A22.8887,22.8887,0,0,0,330.5,109.3887V164.5"/>

          <circle ng-class="{done: $ctrl.leftDone}" cx="9" cy="9" r="6"/>
          <circle ng-class="{done: $ctrl.middleDone}" cx="267" cy="9" r="6"/>
          <circle ng-class="{done: $ctrl.rightDone}" cx="526" cy="9" r="6"/>
          <circle ng-class="{done: $ctrl.leftDone}" cx="204" cy="165" r="6"/>
          <circle ng-class="{done: $ctrl.middleDone}" cx="267" cy="165" r="6"/>
          <circle ng-class="{done: $ctrl.rightDone}" cx="331" cy="165" r="6"/>
        </g>
      </svg>
    </div>
  `,
  bindings: {
    leftDone: '<',
    middleDone: '<',
    rightDone: '<',
  }
}