import moment from "moment";

export const $tokenManager = function($resourceManager, $f, $q, $rootScope) {

  const TOKEN_KEY = 'wt_current_token';

  const manager = {
    token: null,
    remaining: null,
    extra: 0,
    requestedMore: false,
    promise: null
  };

  const initialize = () => {
    // Set promise to return token from localStorage if it exists and not expired
    let token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      token = JSON.parse(token);
      const expired = moment.unix(token.expiry).isBefore();
      if (token && !expired) {
        manager.promise = $q(resolve => resolve(setUpToken(token)));
      }
    }
  };

  const tokenRequest = () => {
    return $q((resolve, reject) => {
      $f.gen().then((fingerprint) => {
        $resourceManager
          .request('tokens', 'generate', {_f: fingerprint})
          .then(res => resolve(res))
          .catch(err => reject(err));
      });
    });
  };

  const setUpToken = response => {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(response));
    manager.token = response.token;
    manager.remaining = response.remaining;
    return manager.token;
  }

  const handleTokenError = error => {
    console.error(error);
    $rootScope.$broadcast('token::' + error.status);
    return error;
  }

  const getToken = () => {
    return $q((resolve, reject) => {
      manager.token = null;
      localStorage.removeItem(TOKEN_KEY);
      tokenRequest()
        .then(res => resolve(setUpToken(res)))
        .catch(err => {
          manager.remaining = -1;
          manager.token = null;
          localStorage.removeItem(TOKEN_KEY);
          reject(() => handleTokenError(err)); // Cache the callback error function itself, not just its return value, so we can call it elsewhere.
        });
    })
  }

  manager.getMore = function () {
    manager.requestedMore = true;
    manager.remaining = manager.extra;
  };

  manager.getToken = function ({forced} = {}) {

    // If forced, or we don't have a token, go get a new one
    if (forced || !manager.promise) {
      manager.promise = getToken();
    }

    // Return the promise for the new token
    return manager.promise;

  }

  initialize();

  return manager;
};

$tokenManager.$inject = ['$resourceManager', '$f', '$q', '$rootScope'];
