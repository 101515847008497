import { v4 as uuidv4 } from 'uuid'

export const SearchAbstract = function($searchRequest, $tokenManager, $requestManager, $keywordsCollection, $resultsStore, $searchDebug) {
  function _SearchAbstract (type, dataSource, resultsStoreKey, payload, searchSettings, clearCollection = true) {
    let abstract = {
      type,
      dataSource,
      clearCollection,
      resultsStoreKey,
      payload,
      source: searchSettings ? searchSettings.source : dataSource,
      error: null,
      supplier: ['google'].includes(dataSource) ? 'wordtracker' : dataSource
    };

    // Provide an existing store key to try to use that rather than clearing the collection.
    abstract.store = function (existingStoreKey = null) {

      let collection = existingStoreKey ? $resultsStore[existingStoreKey] || $keywordsCollection({name: existingStoreKey}) : $keywordsCollection({name: abstract.resultsStoreKey});
      if (searchSettings) {
        collection.source = abstract.source;
        collection.country = searchSettings.searchParams.country;
        collection.month = searchSettings.searchParams.month;
      }
      return collection;
    };

    abstract.params = function () {
      return {
        token: $tokenManager.token,
        type: abstract.type,
        data_source: abstract.dataSource
      };
    };

    abstract.fail = () => {
      $resultsStore[abstract.resultsStoreKey].fail();
    }

    abstract.accepted = function (response) {
      $searchDebug.log('okay', `${abstract.resultsStoreKey} factory${abstract.clearCollection ? '' : ' (append)'} received response`, {source: abstract.source, supplier: abstract.supplier, message: response});
      const accepted = $requestManager.addResponse(response.request_id, abstract.resultsStoreKey);
      if (accepted) {
        $searchDebug.log('okay', `${abstract.resultsStoreKey} factory${abstract.clearCollection ? '' : ' (append)'} accepted response, awaiting results`, {source: abstract.source, supplier: abstract.supplier});
      } else {
        $searchDebug.log('warn', `${abstract.resultsStoreKey} factory.accepted${abstract.clearCollection ? '' : ' (append)'} couldn't find request_id, ignored`, {source: abstract.source, supplier: abstract.supplier});
      }
    };

    abstract.error = function (error) {
      $searchDebug.log('error', `${abstract.resultsStoreKey} factory${abstract.clearCollection ? '' : ' (append)'} received error`, {message: error});
      abstract.fail();
      abstract.error = error;
      $requestManager.deleteRequest(error.request_id);
      $searchDebug.log('error', `${abstract.resultsStoreKey} request not accepted, deleted request_id${abstract.clearCollection ? '' : ' (append)'}`);
      $tokenManager.getToken({forced: true});
    };

    abstract.shouldClear = () => {
      const seed = abstract.payload().seeds;
      const key = abstract.resultsStoreKey;
      return (
        abstract.clearCollection ||
        (abstract.type === "serp" && (!$resultsStore[key] || $resultsStore[key] && !$resultsStore[key][seed]))
      )
    }

    abstract.search = function () {

      const payload = abstract.payload();
      const params = abstract.params();
      const useExistingStoreKey = abstract.shouldClear() ? null : abstract.resultsStoreKey;
      const store = abstract.store(useExistingStoreKey);

      params.search_id = $requestManager.setup(abstract.resultsStoreKey, store, abstract.shouldClear());
      params.request_id = uuidv4();

      $requestManager.addRequest(params.search_id, params.request_id, abstract.resultsStoreKey, abstract.source, abstract.supplier);

      var searchPromise = $searchRequest('request', 'save', params, payload);

      $searchDebug.log('okay', `${abstract.resultsStoreKey} factory.search${abstract.clearCollection ? '' : ' (append)'}`, {source: abstract.source, supplier: abstract.supplier});

      return searchPromise
        .then(abstract.accepted)
        .catch(abstract.error)
    };

    return abstract;
  }

  return _SearchAbstract;
};

SearchAbstract.$inject = ['$searchRequest', '$tokenManager', '$requestManager', '$keywordsCollection', '$resultsStore', '$searchDebug'];
