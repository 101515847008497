export const sendTriggerUpsellEvent = detail => {
  const triggerUpsellEvent = new CustomEvent("triggerUpsell", {
    bubbles: false,
    cancelable: false,
    composed: false,
    detail
  });
  document.dispatchEvent(triggerUpsellEvent);
}

export const sendUserBuiltEvent = user => {
  const userBuiltEvent = new CustomEvent("userBuilt", {
    detail: { user },
    bubbles: false,
    cancelable: false,
    composed: false,
  });

  document.dispatchEvent(userBuiltEvent);
}

export function subscribe(eventName, listener) {
  document.addEventListener(eventName, listener);
}

export function unsubscribe(eventName, listener) {
  document.removeEventListener(eventName, listener);
}