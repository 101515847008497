class _Controller {
  constructor($tokenCatcher, $window, UserService) {
    this.$tokenCatcher = $tokenCatcher;
    this.$window = $window;
    this.user = UserService.user;
  }

  resetSearches(block) {
    this.resourceManager.request('tokens', 'reset', {_f: this.$f.gen(), block: block}).then(() => {
      this.$window.location.reload();
    });
  }
}

_Controller.$inject = ['$tokenCatcher', '$window', 'UserService'];

export const suspendedAccountModal = {
  controller: _Controller,
  template: `
    <div class='padlock-motif'></div>
    <h4>Account suspended</h4>
    <div>We detected suspicious activity from this account. If you think your account has been suspended in error, please contact <strong>support@wordtracker.com</strong>.</div>
    <a id='reset-searches-button' ng-if='$ctrl.user.admin' ng-click='$ctrl.resetSearches(true)'>Reset account</a>
  `,
}