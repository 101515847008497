class _Controller {

  constructor($lists, $listNavigation, $stateParams) {

    this.listNav = $listNavigation;
    this.project = null;
    this.lists = $lists.lists;
    this.projects = $lists.projects;

    $lists.initialize().then(() => {
      let project = _.findWhere($lists.projects, {id: parseInt($stateParams.id)});
      this.project = (project) ? project : false;
    });

  }

}

_Controller.$inject = ['$lists', '$listNavigation', '$stateParams'];

export const projectShow = {
  controller: _Controller,
  template: require('./projectShow.html')
};
