import './rankingKeywords.scss';

class _Controller {

  constructor($rankingSearchSettings, $listManagementNotifier, $activeList, $upsells, $limits, $saveToList, UserService, $resultsStore) {
    this.$rankingSearchSettings = $rankingSearchSettings;
    this.$listManagementNotifier = $listManagementNotifier;
    this.$activeList = $activeList;
    this.$saveToList = $saveToList;
    this.$upsells = $upsells;
    this.$limits = $limits;
    this.results = $resultsStore;
    this.isFreeUser = UserService.user.isFreeUser;
  }
}

_Controller.$inject = ['$rankingSearchSettings', '$listManagementNotifier', '$activeList', '$upsells', '$limits', '$saveToList', 'UserService', '$resultsStore'];

export const rankingKeywords = {
  bindings: {
    collection: '<',
    locationKey: '@',
    onClickAdd: '&',
    onClickRemove: '&'
  },
  controller: _Controller,
  template: require('./rankingKeywords.html')
}
