import { extend } from "underscore";

export const SearchStandard = function($searchAbstract) {
  function _SearchStandard (dataSource, resultsStoreKey, searchSettings) {
    var payload = function () {
      var _payload = {};
      extend(_payload, searchSettings.searchParams);
      _payload.seeds = searchSettings.activeSeed;
      return _payload;
    };

    var search = $searchAbstract('search', dataSource, resultsStoreKey, payload);
    return search;
  }

  return _SearchStandard;
};

SearchStandard.$inject = ['$searchAbstract'];
