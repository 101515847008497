const FOLDER_ID_COL = "project_id";

export const $lists = function($q, $searchSettings, $storage) {

  const getLists = (onComplete) => {
    let getAllLists = $storage.request('lists', 'query');
    let getAllProjects = $storage.request('projects', 'query');

    $q.all([getAllLists, getAllProjects]).then((responses) => {
      let lists = responses[0];
      let projects = responses[1]
      onComplete(lists, projects);
    });
  };

  const createTree = (items, parent, nestLevel) => {

    parent = parent || {};

    let tree = [];

    var nest = nestLevel || 0;

    for (let item of items) {

      if (item[FOLDER_ID_COL] != parent.id) continue;

      item.nestLevel = nest;

      item.parentIds = angular.copy(item.parentIds || parent.parentIds || []);

      if (parent.id && !item.parentIds.includes(parent.id)) item.parentIds.push(parent.id);

      const isFolder = !item.hasOwnProperty("keywords_count");

      if (isFolder) {
        item.children = createTree(items, item, (item.nestLevel + 1));
      }

      tree.push(item);

    }

    return tree;

  };

  let service = {
    activeList: { keywords: []},
    lists: [],
    projects: [],
    tree: [],
    loaded: null
  };

  service.initialize = force => {
    return $q(resolve => {
      if (service.loaded && !force) resolve();
      else {
        getLists((lists, projects) => {
          service.loaded = null;
          angular.copy(lists, service.lists);
          angular.copy(projects, service.projects);
          angular.copy(createTree([...lists, ...projects]), service.tree);
          service.loaded = true;
          resolve();
        });
      }
    });
  };

  service.createList = (params) => {
    return $q(resolve => {
      $storage.request('lists', 'save', null, {list: params} ).then(list => {
        service.addList(list);
        resolve(list);
      });
    });
  };

  service.getListById = id => {
    return _.findWhere(service.lists, {id});
  };

  service.updateList = (list, payload) => {
    return $q(resolve => {

      // Update the list in the UI instantly, adding a loading flag
      const { children, parentIds, ..._list } = list;
      service.replaceList(list, {..._list, ...payload, loading: true });
      // resolve();
      // Make the actual request
      $storage.request('lists', 'update', {id: list.id}, payload).then(response => {
        let newList = {..._list, ...response, loading: false};
        service.replaceList(list, newList);
        resolve();
      });
    });
  };

  service.createProject = (params) => {
    return $q(resolve => {
      $storage.request('projects', 'save', null, {project: params} ).then(project => {
        service.addProject(project);
        resolve(project);
      });
    });
  };

  service.updateProject = (project, payload) => {
    return $q(resolve => {
      const { children, parentIds, ..._project } = project;
      service.replaceProject(project, {..._project, ...payload, loading: true });
      // resolve();
      $storage.request('projects', 'update', {id: project.id}, payload).then(response => {
        let newProject = {..._project, ...response, loading: false};
        service.replaceProject(project, newProject);
        resolve();
      });
    });
  };

  service.deleteList = (list) => {
    return $q(resolve => {
      $storage.request('lists', 'delete', {id: list.id}).then(response => {
        service.replaceList(list);
        resolve();
      });
    });
  };

  service.deleteProject = (project) => {
    return $q(resolve => {
      $storage.request('projects', 'delete', {id: project.id}).then(response => {
        service.replaceProject(project);
        resolve();
      });
    });
  };

  service.getKeywords = (params) => {
    return $storage.request('lists', 'keywords', params);
  };

  service.deleteKeywords = (list, keywords) => {
    let ids = keywords.map(keyword => keyword.id);
    return $storage.request('keywords', 'delete', {'id[]': ids, 'list_id': list.id});
  };

  service.importKeywords = (list, keywords) => {
    var params = {
      keywords: keywords,
      list_id: list.id
    };
    return $storage.request('keywords', 'import', null, params);
  };

  service.addList = (list) => {
    let lists = angular.copy(service.lists);
    lists.push(list);
    angular.copy(lists, service.lists);
    const merged = angular.copy([...service.lists, ...service.projects]); // Need to copy or things go very wrong
    const newTree = createTree(merged);
    angular.copy(newTree, service.tree);
  };

  service.addProject = (project) => {
    let projects = angular.copy(service.projects);
    projects.push(project);
    angular.copy(projects, service.projects);
    const merged = angular.copy([...service.lists, ...service.projects]); // Need to copy or things go very wrong
    const newTree = createTree(merged);
    angular.copy(newTree, service.tree);
  };

  service.saveKeywordsToList = (keywords, list) => {
    keywords = _.flatten([keywords], true);
    keywords = keywords.filter(keyword => !_.findWhere(list.keywords, {keyword: keyword.keyword}));
    list.keywords.unshift(...keywords);
    return service.importKeywords(list, keywords);
  };

  service.removeKeywordsFromList = (keywords, list) => {
    const keywordKeywords = _.flatten([keywords], true).map(k => k.keyword);
    const _keywords = list.keywords.filter(k => (!keywordKeywords.includes(k.keyword)));
    angular.copy(_keywords, list.keywords);
    return service.deleteKeywords(list, keywords);
  };

  service.replaceResource = (type, oldResource, newResource) => {
    let temp = angular.copy(service[type + 's']);
    let oldIndex = _.findIndex(temp, {id: oldResource.id});
    if (newResource) {
      temp.splice(oldIndex, 1, newResource);
    } else {
      temp.splice(oldIndex, 1);
    }
    angular.copy(temp, service[type + 's']);
    const merged = angular.copy([...service.lists, ...service.projects]); // Need to copy or things go very wrong
    const newTree = createTree(merged);
    angular.copy(newTree, service.tree);
  };

  service.replaceList = (oldList, newList) => {
    service.replaceResource('list', oldList, newList);
  };

  service.replaceProject = (oldProj, newProj) => {
    service.replaceResource('project', oldProj, newProj);
  };

  service.loadListKeywords = list => {
    return $q(resolve => {
      service.getKeywords(list).then(response => {
        list.keywords = response.keywords;
        resolve();
      });
    });
  };

  return service;
};

$lists.$inject = ['$q', '$searchSettings', '$storage'];
