export const OpportunityCalc = function ($resultsStore) {
  function similarity (s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  }

  function editDistance (s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = [];
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i === 0) {
          costs[j] = j;
        } else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
              costs[j - 1] = lastValue;
              lastValue = newValue;
            }
          }
        }
      }
      if (i > 0) {
        costs[s2.length] = lastValue;
      }
    }
    return costs[s2.length];
  }

  function _OpportunityCalc (volume, competition, rank, keyword, title) {
    var values = {
      givenLog: null,
      givenExp: null,
      volumeMaxLog: null,
      volumeMaxExp: null
    };

    competition = competition || 1;
    volume = volume || 1;
    rank = rank || 90;

    if (title) {
      title = title.toLowerCase();
    } else {
      title = '';
    }

    var titleSimilarity = similarity(title, keyword) * 7;

    values.volumeMaxLog = (Math.log(165000) / Math.log(100));
    values.volumeMaxExp = Math.exp(values.volumeMaxLog);

    values.givenLog = (Math.log(volume) / Math.log(100));
    values.givenExp = Math.exp(values.givenLog);

    var volumeRatio = ((values.givenExp * 100) / values.volumeMaxExp);
    var result = ((Math.sqrt(volumeRatio) * 100) / (Math.sqrt(competition + volumeRatio))) / 100;
    if (result > 100) result = 100;

    return result * (100 - rank) - titleSimilarity;
  }

  return _OpportunityCalc;
};

OpportunityCalc.$inject = ['$resultsStore'];
