export const $searchRequest = function($resourceManager, $q, $tokenManager) {
  var search = function (endpoint, action, params, payload) {
    return $q(function (resolve, reject) {
      var request = $resourceManager.request(endpoint, action, params, payload);
      request.then(function (response) {
        resolve(response);
      }).catch(function (error) {
        if (error.status === 401) {
          $tokenManager.getToken({forced: true}).then(function (token) {
            params.token = token;
            search(endpoint, action, params, payload);
          });
        }
        reject(error);
      });
    });
  };

  return function (endpoint, action, params, payload) {
    return search(endpoint, action, params, payload);
  };
};

$searchRequest.$inject = ['$resourceManager', '$q', '$tokenManager'];
