const generateRandomWord = (length) => {
  const consonants = 'bcdfghjklmnpqrstvwxyz';
  const vowels = 'aeiou';
  let word = '';

  for (let i = 0; i < length; i++) {
    if (i % 2 === 0) {
      // Select a random consonant for even positions in the word
      word += consonants[Math.floor(Math.random() * consonants.length)];
    } else {
      // Select a random vowel for odd positions in the word
      word += vowels[Math.floor(Math.random() * vowels.length)];
    }
  }

  return word;
};

const generateRandomGibberish = (numWords, minLength = 4, maxLength = 12) => {
  const numberOfWords = numWords || Math.floor(Math.random() * 2) + 2;
  let gibberish = '';

  for (let i = 0; i < numberOfWords; i++) {
    const wordLength = Math.floor(Math.random() * minLength) + maxLength - minLength - 1;
    const randomWord = generateRandomWord(wordLength);

    gibberish += `${randomWord} `;
  }

  return gibberish.trim(); // Remove trailing space
};

export default generateRandomGibberish;