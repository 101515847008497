import "./home.scss";
//const $ = require('jquery');

class HomeController {

  constructor($window, $meta, $location) {
    this.$meta = $meta;
    this.$location = $location;
    this.$window = $window;
    this.searchText = null;
    this.placeholder = this.sample(["sandcastle", "woodland", "cheap hotels", "italian food", "red dress", "trainers", "winter wonderland"]);
    this.zuul_host = __ZUUL_URL;
  }

  sample (array) {
    const max = array.length - 1;
    const index = Math.floor(Math.random() * max);
    return array[index];
  }

  searchFromBox() {
    this.redirectToSearch(this.searchText);
  }

  goToSignUp(){
    let signUpUrl = `${this.zuul_host}/sign-up?plan=lannister`;
    this.$window.location.href = signUpUrl;
  }

  redirectToSearch(query) {
    this.$location.path('/search').search({query});
  }

}

HomeController.$inject = ['$window', '$meta', '$location'];

export const home = {
  controller: HomeController,
  template: require('./home.html')
};
