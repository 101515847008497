export const stringInputModal = {
  controller: class {

    $onInit() {
      this.title = this.title || 'Input'
      if (this.initialValue) this.inputValue = this.initialValue
    }

    resolve(value) {
      this.close({value});
    }

  },
  bindings: {
    close: '&',
    title: '<heading',
    description: '<',
    label: '<',
    placeholder: '<',
    initialValue: '<',
  },
  template: `
    <div class="modal-header modal-input">
      <span ng-bind-html='$ctrl.title'></span>
      <button type='button' class='dismiss-standard' ng-click='$ctrl.resolve(false)'>&times;</button>
    </div>
    <div class="modal-body" id="modal-body">
      <span class='description' ng-bind-html='$ctrl.description'></span>
      <div class='form-row'>
        <label class='label'>
          <span ng-bind-html='$ctrl.label'></span>
          <input type='text' placeholder='{{$ctrl.placeholder}}' ng-model='$ctrl.inputValue'></input>
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class='save-standard' ng-click="$ctrl.resolve($ctrl.inputValue)" ng-disabled='!$ctrl.inputValue'>Submit</button>
      <button type="button" class='cancel-bordered' ng-click="$ctrl.resolve(false)">Cancel</button>
    </div>
  `,
}
