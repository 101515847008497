import React from 'react'
import classNames from 'classnames'
import Metric from './Metric'
import SourceName from './SourceName'
import ExamineIcon from './ExamineIcon'
import ExamineKeywordModal from './ExamineKeywordModal';

export default ({ result, fields, onClickResult, onClickSparkline, sortField, selected, onChange, source, isPadded }) => (
  <tr className={classNames({
    "selected" :selected,
    "appended": result.appended || result.prefilled,
    "padded-result": isPadded
  })}>
    {
      isPadded
        ? <td className="keyword-locked"></td>
        : <td>
            <input
              name="selected"
              type="checkbox"
              checked={selected}
              value={result.keyword}
              keyword={result.keyword}
              onChange={onChange} />
          </td>
    }
    <td className='keyword'>
      <span
        className={`keyword-text${isPadded ? ' blurred' : ''}`}
        onClick={() => onClickResult({result})}
      >{result.keyword}</span>
      { result.gsc && <span className='gsc-marker'>GSC</span> }
      <ExamineIcon onClick={onClickSparkline} />
    </td>
    { source === 'mixed' && <td className='keyword-source table-stat'><SourceName source={result.source}/></td> }
    { fields.map(field => {
      const fieldClass = classNames({
        'table-stat': true,
        [`${field.key}`]: true,
        sorted: sortField === field.key,
        hasSparkline: (result.volume_by_month && field.key === 'volume')
      })
      return (
        <td
          key={field.key}
          className={fieldClass}
        >
          <Metric result={result} fieldKey={field.key} onClickSparkline={onClickSparkline}></Metric>
        </td>
      )
    })}
    {/* <td className='spacer'></td> */}
  </tr>
)