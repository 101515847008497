import "./dropdown.scss";

export const dropdown = function ($document, $timeout, $rootScope) {
  return {
    restrict: 'E',
    transclude: true,
    replace: true,
    scope: {
      extraClass: '@',
      disabled: '<',
      label: '@',
      name: '@'
    },
    template: `
      <div class='options' ng-class='{open: isOpen, "has-label": !!label}'>
        <button class='toggle-button' ng-click='toggle()' ng-disabled='disabled'>{{ label || "More options" }}</button>
        <ul ng-show='isOpen' class='fade-in dropdown-options'>
          <div class='scroller' ng-transclude></div>
        </ul>
      </div>`,
    link: function (scope) {
      scope.isOpen = false;

      scope.open = function () {
        scope.isOpen = true;
        $rootScope.$emit(scope.name + ":open");
        $timeout(function(){
          $document.one("click", scope.close);
        }, 10);
      };

      scope.toggle = function () {
        (scope.isOpen) ? scope.close() : scope.open();
      };

      scope.close = function () {
        $timeout(function(){
          scope.isOpen = false;
        });
        $rootScope.$emit("reset:" + scope.name + ":open");
      };



    }
  };
};

dropdown.$inject = ['$document', '$timeout', '$rootScope'];
