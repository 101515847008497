import { extend } from "underscore";

export const SiteReport = function($searchAbstract, $notifier, cfpLoadingBar, $timeout, $keywordsCollection, $domainSearchSettings) {
  function _SiteReport (searchSettings) {
    var payload = function () {
      var _payload = {};
      extend(_payload, searchSettings.searchParams);
      _payload.site = searchSettings.searchParams.site;
      return _payload;
    };

    var search = $searchAbstract('site_report', 'wordtracker', 'site_report', payload);

    search.store = function () {
      return {
        loading: true,
        report: {},
        topKeywords: $keywordsCollection({name: 'site_report'}),
        failed: false,
        fail: function () {
          this.failed = true;
          this.loading = false;
          this.topKeywords.fail();
        },
        addFromCable: function (response) {

          cfpLoadingBar.set(1);
          $timeout(() => cfpLoadingBar.complete(), 50);

          this.loading = false;

          if (response.report && response.report.url) {
            $domainSearchSettings.searchParams.site = response.report.url;
          }

          if (response.report) {
            this.report = response.report;
          }

          if (response.top_keywords) {
            this.topKeywords.addFromCable({results: response.top_keywords});
            this.topKeywords.sortOption = '-score';
          }

          if (response.status === 'fail') {
            $notifier.red('Unable to analyze this site. Please check the URL.', false);
            this.fail();
          }

          if (response.status === 'notfound') {
            $notifier.red('Unable to analyze this site. Please check the URL.', false);
            this.fail();
          }
        }
      };
    };

    return search;
  }

  return _SiteReport;
};

SiteReport.$inject = ['$searchAbstract', '$notifier', 'cfpLoadingBar', '$timeout', '$keywordsCollection', '$domainSearchSettings'];
