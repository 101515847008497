export const $searchSettings = function($seeds, $cableConnection) {
  var settings = {
    searchParams: {
      seeds: [],
      seedFile: null,
      country: 'US',
      adult: false,
      plurals: true,
      method: 'match',
      token: null,
      session_uid: $cableConnection.sessionUid
    },
    changed: false,
    mainSourceOptions: ["google", "amazon", "youtube", "ebay"],
    source: 'google',
    includeGsc: true,
    searchText: null,
    activeSeed: null,
    seeds: {api: null},

    validateSearchText: function () {
      this.seeds = $seeds(this.searchText, this.source);
      this.searchText = this.seeds.searchText;
      var valid = this.seeds.isValid();
      return valid;
    },
    setChanged: function(val) {
      this.changed = val;
    }
  };

  return settings;
};

$searchSettings.$inject = ['$seeds', '$cableConnection'];
