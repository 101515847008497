export const required = () => ({
  name: 'required',
  message: 'cannot be blank'
});

export const minLength = (minChars = 1) => ({
  name: 'minLength',
  minLength: minChars, message: `must be at least ${minChars} characters`
});

export const validEmail = () => ({
  name: 'validEmail',
  message: 'must be a valid email address'
});