export const SearchGSC = function ($searchAbstract) {
  function _SearchGSC (searchSettings) {

    var payload = function () {
      var _payload = {};
      _.extend(_payload, searchSettings.searchParams);
      _payload.seeds = searchSettings.seeds.api;
      _payload.method = 'match';
      return _payload;
    };

    let search = $searchAbstract('search', 'gsc', 'main', payload, searchSettings, false);

    return search;
    
  }

  return _SearchGSC;
};

SearchGSC.$inject = ['$searchAbstract'];
