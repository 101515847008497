import { keys, where, each } from "underscore";

export const MetricFilters = function() {
  function _MetricFilters (dataset) {
    var api = {
      active: [],
      types: {
        volume:      {title: 'Volume',          availableMin: 0, availableMax: 0 },
        competition: {title: 'Competition',     availableMin: 0, availableMax: 0 },
        cmp:         {title: 'PPC Competition', availableMin: 0, availableMax: 0 },
        iaat:        {title: 'IAAT',            availableMin: 0, availableMax: 0 },
        cpc:         {title: 'CPC',             availableMin: 0, availableMax: 0 },
        kei:         {title: 'KEI',             availableMin: 0, availableMax: 0 },
      },
      updateAvailableRange: function (dataset) {
        dataset.forEach(function(result) {
          for (var type in api.types) {
            if (result[type] < api.types[type].availableMin && result[type] > -1) api.types[type].availableMin = Math.floor(result[type]);
            if (result[type] > api.types[type].availableMax) api.types[type].availableMax = Math.ceil(result[type]);
          }
        });
      },
      resetRange: function (type) {
        api.types[type].availableMax = 0;
        api.types[type].availableMin = 0;
      },
      resetAll: function () {
        each(keys(api.types), function (key) {
          api.resetRange(key);
        });
      },
      add: function (type) {
        if (!api.types.hasOwnProperty(type)) throw 'Unknown filter type requested.';
        var filter = {
          type: type,
          enabled: true,
          min: api.types[type].availableMin,
          max: api.types[type].availableMax
        };
        api.active.push(filter);
      },
      remove: function (index) {
        api.active.splice(index, 1);
      },
      hasFilter: function (type) {
        return where(api.active, {type: type}).length > 0;
      },
      resultValid: function (result) {
        return api.active.every(function (filter) {
          var value = result[filter.type];
          if (!filter.enabled) return true;
          return (value >= filter.min && value <= filter.max);
        });
      },
      setSource: function (source) {
        api.active.forEach(function (filter) {
          filter.enabled = !(api.types[filter.type].hasOwnProperty('disableWhen') && api.types[filter.type].disableWhen === source);
        });
      }
    };

    if (dataset) {
      api.updateAvailableRange(dataset);
    }

    return api;
  }
  return _MetricFilters;
}

MetricFilters.$inject = [];
