export const listBlock = function ($location) {

  return {
    restrict: 'E',
    scope: {
      list: '=',
      selected: '=',
      onDragStart: '&'
    },
    template: require('./listBlock.html'),
    link: function (scope) {

      scope.goToList = function(list) {
        $location.path('/lists/' + list.id );
      };

    }
  };
};

listBlock.$inject = ['$location'];
