export const $searchManager = function($tokenManager, $curiosity, $timeout, cfpLoadingBar, $activeColumns, $searchSettings, UserService, $cableConnection) {

  const preSearch = (callback) => {

    if (!$cableConnection.isConnected) {
      console.log('Searching before cable is connected. Registering search as an onConnectCallback.');
      return $cableConnection.registerOnConnectCallback(() => preSearch(callback));
    }

    $tokenManager.getToken().then(callback).catch(onTokenErrorFn => {
      if (typeof onTokenErrorFn === 'function') {
        onTokenErrorFn()
      } else {
        console.error(onTokenErrorFn);
      }
    });

  };

  const refreshToken = () => {
    $tokenManager.getToken({forced: true})
  };

  var buildSearches = function (types) {
    const { user } = UserService;
    const promises = _.compact(types.map(type => {
      const curiosity = $curiosity[type]();
      switch (type) {
        case 'main':
          $activeColumns.onSearch();
          return curiosity.search();
        case 'gsc':
          const validSearch = ($searchSettings.source === 'google' && $searchSettings.includeGsc);
          const validUser = (user.gsc_has_access_token && !user.isFreeUser && !user.isExpiredUser)
          if (validSearch && validUser) {
            return curiosity.search();
          }
          break;
        default:
          return curiosity.search();
      }
    }));

    if (types.includes("site_report")) {
      manager.searchedDomain = true;
    } else if(types.includes('ranking')) {
      manager.searchedRanking = true;
    }else {
      manager.searched = true;
    }

    $timeout(() => {
      cfpLoadingBar.set(0.01);
      cfpLoadingBar.start();
    });

    // Once we've made all our search requests, get a new token for the next search;
    Promise.allSettled(promises).then(refreshToken);

  };

  var manager = {
    searched: false,
    searchedDomain: false,
    search: function () {
      preSearch(function () {
        buildSearches(['main', 'gsc', 'datamuse']);
      });
    },
    searchRanking: function () {
      preSearch(function () {
        buildSearches(['ranking']);
      });
    },
    searchDomain: function () {
      preSearch(function () {
        buildSearches(['site_report', 'keywords_for_domain_by_clicks', 'keywords_for_domain_by_clicks_change', 'page_metrics', 'competitors']);
      });
    },
    searchBulkSeeds: function () {
      preSearch(function () {
        buildSearches(['csv', 'datamuse']);
      });
    },
    searchAppend: function(seed, resultsStoreKey) {
      preSearch(() => {
        var curiosity = $curiosity.append(seed, resultsStoreKey);
        curiosity.search().finally(refreshToken);
      });
    },
    serpAppend: function(seed, country) {
      preSearch(() => {
        var curiosity = $curiosity.serpAppend(seed, country);
        curiosity.search().finally(refreshToken);
      });
    }
  };

  return manager;
};

$searchManager.$inject = ['$tokenManager', '$curiosity', '$timeout', 'cfpLoadingBar', '$activeColumns', '$searchSettings', 'UserService', '$cableConnection'];