export const $curiosity = function($searchMain, $searchCSV, $searchGSC, $searchStandard, $searchAppend, $searchSettings, $siteReport, $domainSearchSettings, $rankingSearchSettings, $serpAppend, $pageMetrics, $competitors, $keywordsForDomain, $searchRanking) {
  var curiosity = {
    main: function () {
      return $searchMain($searchSettings);
    },
    gsc: function () {
      return $searchGSC($searchSettings);
    },
    append: function (seed, resultsStoreKey) {
      return $searchAppend($searchSettings, seed, resultsStoreKey);
    },
    ranking: function() {
      return $searchRanking($rankingSearchSettings);
    },
    serpAppend: function (seed, country) {
      return $serpAppend(seed, country);
    },
    csv: function () {
      return $searchCSV($searchSettings);
    },
    amazon: function () {
      return $searchStandard('amazon', 'amazon', $searchSettings);
    },
    youtube: function () {
      return $searchStandard('youtube', 'youtube', $searchSettings);
    },
    datamuse: function () {
      return $searchStandard('datamuse', 'datamuse', $searchSettings);
    },
    site_report: function () {
      return $siteReport($domainSearchSettings);
    },
    keywords_for_domain_by_clicks: function () {
      return $keywordsForDomain($domainSearchSettings, 'clicks');
    },
    keywords_for_domain_by_clicks_change: function () {
      return $keywordsForDomain($domainSearchSettings, 'clicks_change');
    },
    competitors: function () {
      return $competitors($domainSearchSettings);
    },
    page_metrics: function () {
      return $pageMetrics($domainSearchSettings);
    }
  };

  return curiosity;
};

$curiosity.$inject = ['$searchMain', '$searchCSV', '$searchGSC', '$searchStandard', '$searchAppend', '$searchSettings', '$siteReport', '$domainSearchSettings', '$rankingSearchSettings', '$serpAppend', '$pageMetrics', '$competitors', '$keywordsForDomain', '$searchRanking'];
