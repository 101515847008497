export const randomPhrase = function () {
  return function(string) {
    // var totalWords = _.random(1);
    // var randomWords = ['news', 'update', 'better', 'cheap', 'how to', 'for beginners', 'improvement', 'diy', 'red', 'yellow', 'blue', 'copper', 'every'];
    // var extraWords = _.sample(randomWords, totalWords + 1);

    // var allWords = _.union(extraWords, string.split(" "));
    // return _.shuffle(allWords).join(" ");
    return "Unlock more suggestions";

  };
};
