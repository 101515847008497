import "./topKeywords.scss";

class Controller {

  constructor($resourceManager, $keywordsCollection, $activeColumns) {
    this.$resourceManager = $resourceManager;
    this.$activeColumns = $activeColumns;
    this.keywords = $keywordsCollection({name: 'keywordIdeas'});
    this.forceColspan = 4;
    $activeColumns.onSearch();
    $activeColumns.loadSeo();
  }

  $onInit() {
    this.$resourceManager.request('top_keywords', 'ideas', {source: (this.engine || 'google'), country: this.country, keyword: this.keyword }).then(response => {
      this.keywords.add(response.keywords.results);
    });
  }

}

Controller.$inject= ['$resourceManager', '$keywordsCollection', '$activeColumns'];

export const keywordIdeas = {
  bindings: {
    engine: '@',
    country: '@',
    keyword: '@'
  },
  controller: Controller,
  template: require('./genericKeywords.html')
};