import { sendUserBuiltEvent } from "../shared/events";

const hasEmail         = user => !!user?.email
const hasPlanCode      = user => !!user?.subscription?.plan_code;
const hasTrialFlag     = user => !!user?.subscription?.trial;
const isStateExpired   = user => user?.subscription?.state === 'canceled';
const isPlanHold       = user => user?.subscription?.plan_code === 'lannister_hold_monthly';

export const UserService = function($tokenManager) {

  const service = {
    user: {}
  }

  service.can = (action) => {
    const conditions = {
      getMoreSearches: () => !hasPlanCode(service.user) && !$tokenManager.requestedMore,
      purchase:        () => !hasPlanCode(service.user),
      upgrade:         () => hasPlanCode(service.user) && service.user.subscription.tier != 'gold',
      endTrial:        () => hasTrialFlag(service.user) && service.user.subscription.tier != 'bronze',
      renew:           () => !!service.user.isExpiredUser
    };
    return conditions[action]();
  };

  service.initialize = (user) => {
    let builtUser = {
      ...user,
      isZuulUser:          hasEmail(user),
      isFreeUser:          !hasPlanCode(user),
      isExpiredUser:       hasEmail(user) && (!hasPlanCode(user) || isStateExpired(user)) || isPlanHold(user),
      isTrialUser:         hasTrialFlag(user),
      canUpgrade:          hasPlanCode(user) && !user.subscription.plan_code.match(/gold/),
      isNimbleUser:        hasPlanCode(user) && !!user.subscription.plan_code.match(/nimble/),
      current1dollarTrial: hasPlanCode(user) && !!user.subscription.plan_code.match(/1dollar/) && !isStateExpired(user),
      hasUpcomingSub:      hasPlanCode(user) && !!user.upcoming_price_id,
      hasFullSubscription: hasPlanCode(user) && !user.subscription.plan_code.match(/1dollar/) && !isStateExpired(user),
      hasValidCard:        user.customer && user.customer.payment_method_valid,
      can: service.can.bind(service),
    };

    angular.copy(builtUser, service.user);

    sendUserBuiltEvent(builtUser);

    return service.user;

  }

  return service;

}

UserService.$inject = ['$tokenManager'];
