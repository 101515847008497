import "./lateral.scss";

class _Controller {
  constructor($limits, $searchSettings, $notifier, $searchManager, $resultsStore) {
    this.limits = $limits;
    this.$searchSettings = $searchSettings;
    this.$notifier = $notifier;
    this.$searchManager = $searchManager;
    this.$resultsStore = $resultsStore;
  }

  sendResultToMain(result) {
    if (_.contains(this.$searchSettings.seeds.positive, result)) {
      return this.$notifier.green("This term is already in your search");
    }

    this.$searchSettings.seeds.addSeed(result);
    this.$resultsStore.main.addAppended(result);
    this.$searchManager.searchAppend(result, 'main');

    let resultIdx = _.findIndex(this.results.keywords, r => r.keyword == result);
    this.results.keywords.splice(resultIdx, 1);
  }

  notInSeeds(result) {
    return (this.$searchSettings.seeds.positive.indexOf(result.keyword) == -1);
  }

}

_Controller.$inject = ['$limits', '$searchSettings', '$notifier', '$searchManager', '$resultsStore'];

export const lateral = {
  bindings: {
    results: '<',
    newUpsell: '&newUpsellFn',
    onClickResultFn: '&',
  },
  controller: _Controller,
  template: require('./lateral.html')
};
