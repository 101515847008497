import React from 'react';

const NO_FILTER_FIELDS = ['intent_legacy', 'ranking_url', 'rank_history'];

export default props => (
  <div className='add-filter-buttons'>
    { props.fields.filter(field => !NO_FILTER_FIELDS.includes(field.key)).map(field => (
      <button
        className='add-filter'
        disabled={props.filters.hasOwnProperty(field.key)}
        key={field.key}
        onClick={() => props.onAddFilter(field.key)}
      >+ {field.name}...</button>
    ))}
    <button
      key='question'
      className='add-filter'
      disabled={props.filters.hasOwnProperty('question')}
      onClick={() => props.onAddFilter('question')}
    >+ Is Question...</button>
    { props.gscConnected && props.source != 'ranking' &&
      <button
        key='gsc'
        className='add-filter gsc'
        disabled={props.filters.hasOwnProperty('gsc') || ['amazon', 'youtube', 'ebay'].includes(props.source) || props.method === 'exact'}
        onClick={() => props.onAddFilter('gsc')}
      >+ GSC keywords only...</button>
    }
  </div>
)