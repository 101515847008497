import "./appWindow.scss"

export const appWindow = {
  template: `
    <div class='app-window'>
      <div class='top-bar'>
        <div class='red'></div>
        <div class='amber'></div>
        <div class='green'></div>
      </div>
      <div ng-transclude></div>
    </div>
  `,
  transclude: true
}