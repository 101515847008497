import "./filterButtons.scss";


class _Controller {
  constructor($activeColumns) {
    this.activeColumns = $activeColumns.active;
  }

  shouldHideFilter(type) {
    return !_.findWhere(this.activeColumns, {key: type});
  }

}

_Controller.$inject = ['$activeColumns'];

export const filterButtons = {
  bindings: {
    source: '<',
    collection: '<',
    contextType: '<'
  },
  controller: _Controller,
  template: require('./filterButtons.html')
};
