import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid';

class KeywordsProvider extends Component {

  constructor(props) {
    super(props)
    const uuid = uuidv4();
    this.initialState = {
      uuid, // Generate a unique key for each search, to easily invalidate children if an entirely new search is made.
      keywords: [],
      fields: [],
      loading: null,
      failed: null,
      source: null,
    }
    this.state = this.initialState;
  }

  componentDidMount() {
    this.updateKeywords();
  }
  
  componentDidUpdate(prevProps) {
    this.resetTableIfNewSearch(prevProps);
    this.updateSourceIfBlank();
    this.updateFieldsIfChanged();
    this.updateStatusesIfChanged();
    this.updateKeywordsIfChanged();
  }

  updateKeywords() {
    const { keywords } = this.props;
    if (keywords) {
      const keywords = JSON.parse(JSON.stringify((this.props.keywords || []))); // Angular hangs on to the object, so make our own clone of it.
      this.setState({ keywords });
    }
  }

  updateKeywordsIfChanged() {
    if (
      this.props.keywords && JSON.stringify(this.props.keywords) != JSON.stringify(this.state.keywords)
    ) {
      this.updateKeywords();
    }
  }

  resetTableIfNewSearch(prevProps) {
    if (!this.props.keywords && prevProps.keywords) {
      this.setState({...this.initialState, uuid: uuidv4()});
    }
  }

  updateStatusesIfChanged() {
    const { loading, failed } = this.props.collection;
    if (loading != this.state.loading || failed != this.state.failed) {
      this.setState({failed, loading});
    }
  }

  updateSourceIfBlank() {
    if (this.props.source && !this.state.source) this.setState({source: this.props.source});
  }

  updateFieldsIfChanged() {
    const { fields } = this.props;
    if (fields.map(f => f.key).sort().toString() != this.state.fields.map(f => f.key).sort().toString()) {
      this.setState({fields})
    }
  }

  render() {
    return (
      this.props.children({...this.state, textFilters: this.props.textFilters})
    )
  }

}

export default KeywordsProvider