export const fileread = function () {
  return {
    scope: { fileread: "=" },
    link: function (scope, element) {
      scope.$watch('fileread', function(newVal, oldVal) {
        if (!newVal) element.val(null);
      });

      var changeFile = function(changeEvent) {
        var reader = new FileReader();
        reader.onload = function (loadEvent) {
          scope.$apply(function () {
            scope.fileread = loadEvent.target.result;
          });
        };
        reader.readAsDataURL(changeEvent.target.files[0]);
      };

      element.bind("change", changeFile);

    }
  };
};
